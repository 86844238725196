import { FlagImage } from "./FlagImage";
import { FinishedStatus } from "./FinishedStatus";
import { IFlagCountryProps } from "../../../interfaces";

export const FlagCountry = ({
  state,
  flagImg,
}: IFlagCountryProps): JSX.Element => {
  const BACKGROUND_IMAGE = state === "FINISHED" ? "grayscale" : "grayscale-0";

  return (
    <div
      data-testid="flag-card"
      className="flex flex-row items-center w-full gap-2.5"
    >
      {flagImg && (
        <FlagImage flagImg={flagImg} backgroundImage={BACKGROUND_IMAGE} />
      )}

      {state === "FINISHED" && (
        <FinishedStatus state={state} flagImg={flagImg} />
      )}
    </div>
  );
};
