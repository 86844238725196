import { ContainerCardInvoice } from "../details/ContainerCardInvoice";
import { Divider } from "../../../../components/common/divider/Divider";
import { LoadingItemSkeleton } from "./LoadingItemSkeleton";
import { LoadingInfoItemSkeleton } from "./LoadingInfoItemSkeleton";
import { useTranslation } from "react-i18next";

export const DetailsInvoiceSkeleton = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="details-invoice-skeleton"
      className="flex flex-col gap-10 md:gap-6">
      <div className="h-[152px] rounded-xl bg-neutral-300 animate animate-pulse" />

      <div className="flex flex-col md:flex-row gap-6 w-full justify-center items-center">
        <ContainerCardInvoice>
          <div className="flex flex-col gap-6">
            <div className="flex flex-row justify-between items-center">
              <h2 className="text-neutral-charcoal text-base font-bold">
              {t("global.customerV2.invoice.details.products")}
              </h2>
              <p className="bg-neutral-300 animate animate-pulse w-[104px] h-6 rounded-lg" />
            </div>

            <Divider className="border-0 border-b border-neutral-300" />

            <div className="flex flex-col gap-6">
              {Array.from({ length: 3 }).map((_, index) => (
                <LoadingItemSkeleton key={index} />
              ))}
            </div>

            <Divider className="border-0 border-b border-neutral-300" />

            <div className="flex flex-col gap-6">
              <LoadingInfoItemSkeleton label="Subtotal" />
              <LoadingInfoItemSkeleton label="Discount" />
              <LoadingInfoItemSkeleton label="Taxes" />
              <LoadingInfoItemSkeleton label="Total" />
            </div>
          </div>
        </ContainerCardInvoice>

        <ContainerCardInvoice>
          <div className="flex flex-col gap-6">
            <h2 className="text-neutral-charcoal text-base font-bold">
              {t("global.customerV2.invoice.details.personalInfoCard.title")}
            </h2>

            <Divider className="border-0 border-b border-neutral-300" />

            <div className="flex flex-col gap-6">
              <LoadingInfoItemSkeleton label="Name" />
              <LoadingInfoItemSkeleton label="Email" />
              <LoadingInfoItemSkeleton label="Phone" />
            </div>
          </div>
        </ContainerCardInvoice>
      </div>
    </div>
  );
};
