import { makeApiCall } from "../apiCall";
import { AxiosIsError } from "../../utils";
import { IGetUserReferralLink } from "../../interfaces";
import { API_URLS } from "../../constants";

export const getUserReferralLink = async (set: IGetUserReferralLink["set"]) => {
  const { URL_GET_REFERRAL_LINK_TRAVELCLUB } = API_URLS;
  try {
    const { data } = await makeApiCall<{
      referral_link: string;
    }>("POST", URL_GET_REFERRAL_LINK_TRAVELCLUB);

    set({ referralLinkUser: data.referral_link });
    return;
  } catch (error) {
    set({ referralLinkUser: null });
    if (AxiosIsError(error)) {
      throw new Error(
        error.response.data?.errors?.customer || error.response.data?.error
      );
    } else {
      throw new Error(error);
    }
  }
};
