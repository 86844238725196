import Icon from "@mdi/react";
import { mdiClose, mdiInformationOutline } from "@mdi/js";

import HButton from "../../../../components/common/hbutton/HButton";

import { ITopupTooltipModalProps } from "../../../../interfaces";

export const TopupTooltipModal = ({
  closeModal,
  text,
}: ITopupTooltipModalProps): JSX.Element => {
  return (
    <div className="relative bg-white p-6 rounded-xl w-full md:w-[345px] mx-4">
      <button
        onClick={closeModal}
        className="ml-auto border border-neutral-300 hover:bg-neutral-200 rounded-full w-8 h-8 flex 
    justify-center items-center transition-all duration-300"
      >
        <Icon path={mdiClose} size={0.8} />
      </button>
      <div className="flex flex-col gap-6 justify-center items-center">
        <Icon path={mdiInformationOutline} size={2.3} />
        <h3 className="text-base font-normal text-neutral-charcoal">{text}</h3>
        <HButton
          type="button"
          name="button got it"
          onClick={closeModal}
          containerStyles="w-full"
          className="px-5 py-3 h-12 text-base font-medium text-white border-none 
          hover:bg-gradient-to-r hover:from-magenta-bold hover:to-magenta-bold bg-gradient-to-r from-magenta to-magenta-900 
          transition-all duration-300"
          text="Got it"
        />
      </div>
    </div>
  );
};
