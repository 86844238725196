import { useEffect, useState } from "react";

import { InputLabel } from "../../../components/input/InputLabel";
import { useForm } from "../../../hooks/useForm";
import { useCustomerStore } from "../../../store/customerStore";

import { IEditableUserProfileProps, ICustomerStore } from "../../../interfaces";
import { ContentModal } from "../../../components/modal/ContentModal";
import { UnsavedChangesModal } from "./UnsavedChangesModal";
import { useTranslation } from "react-i18next";

export const EditableUserProfile = ({
  initialState,
  saveData,
}: IEditableUserProfileProps): JSX.Element => {
  const { t } = useTranslation();
  const [modalSaveChanges, setModalSaveChanges] = useState<boolean>(false);
  const { setIsModifiedDataAccount, isModifiedDataAccount } = useCustomerStore(
    (store: ICustomerStore) => store
  );

  const { onChange, lastName, firstName, email, phone, form, setForm } =
    useForm(initialState);

  const handleSaveChanges = () => {
    saveData({
      firstName,
      lastName,
    });
    setModalSaveChanges(false);
    setIsModifiedDataAccount(false);
  };

  const handleCloseUnsavedChangesModal = () => setModalSaveChanges(false);

  useEffect(() => {
    const isModified = Object.keys(form).some(
      (key) => form[key] !== initialState[key]
    );
    setIsModifiedDataAccount(isModified);
  }, [form, setIsModifiedDataAccount]);

  return (
    <>
      <div
        data-testid="editable-profile"
        className="bg-white p-4 md:p-12 flex flex-col gap-2 md:gap-6 rounded-xl shadow-[0_6px_24px_rgba(0,0,0,0.1)] w-full lg:w-[885px] h-auto"
      >
        <div className="grid grid-col-1 md:grid-cols-2 gap-2 md:gap-4">
          <InputLabel
            type={"text"}
            name={"firstName"}
            label={t("global.account.formProfile.name")}
            value={firstName || ""}
            onChange={(text: string) => onChange(text, "firstName")}
            textPlaceholder=""
          />
          <InputLabel
            type={"text"}
            name={"lastName"}
            label={t("global.account.formProfile.lastName")}
            value={lastName || ""}
            onChange={(text: string) => onChange(text, "lastName")}
            textPlaceholder=""
          />
        </div>
        <div className="w-full flex flex-col md:flex-row gap-2 md:gap-4">
          <InputLabel
            type={"email"}
            name={"email"}
            label={t("global.account.formProfile.email")}
            value={email || ""}
            onChange={(text: string) => onChange(text, "email")}
            disabled
            isEdit
            textPlaceholder=""
          />

          {initialState.phone ? (
            <InputLabel
              type={"phone"}
              name={"phone"}
              label={t("global.account.formProfile.phone")}
              value={phone}
              onChange={(text: string) => onChange(text, "phone")}
              disabled
              isEdit
              textPlaceholder=""
            />
          ) : (
            <></>
          )}
        </div>

        {isModifiedDataAccount && (
          <div
            data-testid="group-buttons-account"
            className="flex flex-col-reverse md:flex-row justify-center gap-6 md:gap-4 items-center"
          >
            <button
              type="button"
              aria-label="button not save"
              name="button not save"
              className="font-medium"
              onClick={() => setForm({ ...initialState })}
            >
              {t("global.account.btns.btnNotSave")}
            </button>

            <button
              type="button"
              aria-label="button open modal save profile"
              name="button open modal save profile"
              onClick={() => setModalSaveChanges(true)}
              className="underline font-medium"
            >
              {t("global.account.btns.btnSaveChanges")}
            </button>
          </div>
        )}
      </div>

      <ContentModal
        isModal={modalSaveChanges}
        toggleModal={handleCloseUnsavedChangesModal}
      >
        <UnsavedChangesModal
          handleSuccess={handleSaveChanges}
          closeModal={handleCloseUnsavedChangesModal}
        />
      </ContentModal>
    </>
  );
};
