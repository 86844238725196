import { IEsimAdapterGeneric, INewOrderEsim, IOrderEsim } from "../interfaces";

export const esimAdapter = (card: INewOrderEsim): IEsimAdapterGeneric => {
  if (card.consumedData) {
    if (Array.isArray(card.consumedData)) {
      const [consumedData, ...consumedCalls] = card.consumedData;
      return {
        ...card,
        consumedData,
        consumedCalls,
      };
    } else {
      const consumedData = card.consumedData;
      return {
        ...card,
        consumedData,
        consumedCalls: [],
      };
    }
  }

  return { ...card, consumedData: card.consumedData, consumedCalls: [] };
};
