import { useTranslation } from "react-i18next";

import { redirectToWeb } from "../../../utils/redirectLinkEsim";

import Girl from "../../../assets/main/GirlShopping.svg";

export const SectionAnotherEsim = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <section className="flex flex-col gap-3 items-center w-full py-[40px] mt-[20px]">
      <div className="flex flex-col justify-center items-center gap-6">
        <img src={Girl} alt="Shopping" className="w-[200px] h-[200px]" />
        <p className="text-[20px] lg:text-[28px] font-bold lg:font-medium text-center">
          {t("global.customerV2.home.sectionBuyNewEsim.title")}
        </p>
        <div
          onClick={redirectToWeb}
          className="min-w-[170px] cursor-pointer py-[12px] px-[20px] gap-1 flex justify-center items-center h-12 hover:bg-gradient-to-r hover:from-magenta-bold hover:to-magenta-bold 
    bg-gradient-to-r from-magenta to-magenta-900 text-white rounded-xl transition-all duration-300"
        >
          <p className="w-full text-[14px] whitespace-nowrap text-center">
            {t("global.customerV2.home.buttons.buttonBuyAnotherEsim")}
          </p>
        </div>
      </div>
    </section>
  );
};
