import { useState } from "react";
import { LayoutPageWithMapBackground } from "../../../../components/layouts/LayoutPageWithMapBackground";
import info_0 from "../../../../assets/details/steps/info_0.svg";
import info_1 from "../../../../assets/details/steps/info_1.svg";
import info_2 from "../../../../assets/details/steps/info_2.svg";
import info_3 from "../../../../assets/details/steps/info_3.svg";
import warning from "../../../../assets/details/warning_modal.svg";
import HButton from "../../../../components/common/hbutton/HButton";
import { ContentModal } from "../../../../components/modal/ContentModal";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { useInstallCodes } from "../../../../hooks";
import { ICustomerStore } from "../../../../interfaces";
import { useCustomerStore } from "../../../../store/customerStore";
import { useParams } from "react-router-dom";
import { SkeletonStep } from "./SkeletonStep";
import { Trans } from "react-i18next";
import { t } from "i18next";

interface IStep {
  title: string;
  image: JSX.Element;
  content: JSX.Element;
}

export const StepInstructions = () => {
  const initialState = {
    SMDP: "",
    activationCode: "",
    universalLink: "",
    loader: true,
  };
  let { iccid, isCall } = useParams();
  const { customerId } = useCustomerStore((state: ICustomerStore) => state);
  const { universalLink, loader } = useInstallCodes(
    initialState,
    customerId,
    iccid || ""
  );

  const [step, setStep] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);

  const stepsContent: IStep[] = [
    {
      title: t("global.OneButInst_SetUp_Subtittle"),
      image: <img className="w-full" src={info_0} alt="info-step" />,
      content: (
        <p className="mt-[8px] text-center">
          <Trans
            i18nKey={"global.OneButInst_SetUp_Description"}
            components={{
              strong: <strong />,
            }}
          />
        </p>
      ),
    },
    {
      title: t("global.OneButInst_InstAutom_SetUp2_SubTitle"),
      image: <img className="w-full" src={info_1} alt="info-step" />,
      content: (
        <p className="mt-[8px] text-center">
          <Trans
            i18nKey={"global.OneButInst_InstAutom_SetUp2_Descript"}
            components={{
              strong: <strong />,
            }}
          />
        </p>
      ),
    },
    {
      title: t("global.OneButInst_SetUp3_Subtittle"),
      image: <img className="w-full" src={info_2} alt="info-step" />,
      content: (
        <p className="mt-[8px] text-center">
          {isCall === "call" ? (
            t("global.OneButInst_InstAutom_Set_Step3_Calls")
          ) : (
            <Trans
              i18nKey={"global.OneButInst_InstAutom_Set_Step3_NoCalls"}
              components={{
                strong: <strong />,
              }}
            />
          )}
        </p>
      ),
    },
    {
      title: t("global.OneButInst_SetUp4_Subtittle"),
      image: <img className="w-full" src={info_3} alt="info-step" />,
      content: (
        <p className="mt-[8px] text-center">
          <Trans
            i18nKey="global.OneButInst_SetUp4_Descript_Complete"
            components={{
              b: <br />,
            }}
          />
        </p>
      ),
    },
  ];

  const handleChangeStep = () => {
    if (step < stepsContent.length - 1) {
      setStep(step + 1);
    }
  };

  const handleInstall = () => {
    window.open(universalLink, "_blank");
  };

  return (
    <LayoutPageWithMapBackground
      goBack={true}
      title={t("global.OneButInst_SetUp_Title")}
    >
      <button
        disabled={loader}
        onClick={() => setStep(stepsContent.length - 1)}
        className="underline absolute top-[45px] right-[16px]"
      >
        {t("global.OneButInst_SetUp_skip")}
      </button>

      {loader ? (
        <SkeletonStep />
      ) : (
        <>
          {stepsContent[step]?.image}
          <h1 className="text-[16px] mt-[20px] font-bold w-full text-center">
            {stepsContent[step]?.title}
          </h1>
          {stepsContent[step]?.content}
          <HButton
            type="button"
            name="button continue step instructions"
            containerStyles="mt-[20px] w-full mx-auto hover:bg-red-300 rounded-xl  bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold transition-all duration-300"
            text={
              step === stepsContent.length - 1
                ? t("global.Iundestand_Button")
                : t("global.customerV2.details.active.badgets.next")
            }
            className=" h-12 flex items-center justify-center px-5 py-3 text-white gap-2 border-none font-normal text-base"
            iconColor="white"
            onClick={
              step === stepsContent.length - 1
                ? () => setShowModal(true)
                : handleChangeStep
            }
          />
        </>
      )}
      <ContentModal isModal={showModal} toggleModal={() => setShowModal(false)}>
        <div className="relative p-[24px] flex flex-col items-center w-[90%] h-[472px] bg-white rounded-[12px]">
          <button
            data-testid="close-friend-discount-modal"
            onClick={() => setShowModal(false)}
            className="absolute top-4 right-4 border border-neutral-300 hover:bg-neutral-200 rounded-full w-8 h-8 flex justify-center items-center transition-all duration-300"
          >
            <Icon path={mdiClose} size={0.8} />
          </button>
          <img className="w-[100px]" src={warning} alt="warning" />
          <p className="text-center mt-[12px]">
            <Trans
              i18nKey={"global.OneButInst_Alert_Text1"}
              components={{
                strong: <strong />,
              }}
            />
          </p>
          <p className="text-center mt-[20px]">
            {t("global.OneButInst_Alert_Text2")}
          </p>
          <HButton
            type="button"
            name="button login"
            containerStyles="mt-[20px] w-full mx-auto hover:bg-red-300 rounded-xl  bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold transition-all duration-300"
            text={t("global.login.btnLogin")}
            className=" h-12 flex items-center justify-center px-5 py-3 text-white gap-2 border-none font-normal text-base"
            iconColor="white"
            onClick={handleInstall}
          />
          <HButton
            type="button"
            name="button cancel confirmation logout"
            containerStyles="mt-[16px] border border-[#E4E6EA] w-full  rounded-xl   transition-all duration-300"
            text={t("global.account.LogoutConfirmationModal.btnCancel")}
            className=" h-12 flex items-center justify-center px-5 py-3 gap-2 border-none font-normal text-base"
            iconColor="white"
            onClick={() => setShowModal(false)}
          />
        </div>
      </ContentModal>
    </LayoutPageWithMapBackground>
  );
};
