import { useCallback, useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import Icon from "@mdi/react";
import { mdiClose, mdiMenu } from "@mdi/js";
import { MobileMenu } from "../headerMobile/MobileMenu";
import { CoinsInfo } from "./CoinsInfo";
import { useCustomerStore } from "../../../store/customerStore";
import { ICustomerStore } from "../../../interfaces";
import SupportIcon from "../../../assets/help/supportIcon.svg";
import HolaflyLogo from "../../../holafly_logo.svg";

export const NavbarMobile = (): JSX.Element => {
  const intercom = useIntercom();
  const { isOpenMenuLanguage, isOpenMenuMobile, toggleMenuMobile } =
    useCustomerStore((store: ICustomerStore) => store);
  const { referralUser } = useCustomerStore((store: ICustomerStore) => store);

  const blockScroll = useCallback(() => {
    const body = document.getElementsByTagName("body");
    if (isOpenMenuMobile || isOpenMenuLanguage) {
      body[0].style.overflow = "hidden";
    } else {
      body[0].style.overflow = "initial";
    }
  }, [isOpenMenuLanguage, isOpenMenuMobile]);

  useEffect(() => {
    blockScroll();

    return () => {
      const body = document.getElementsByTagName("body")[0];
      body.style.overflow = "initial";
    };
  }, [isOpenMenuMobile, isOpenMenuLanguage, blockScroll]);

  return (
    <>
      <div className="gap-3 lg:gap-0 lg:hidden w-full h-full flex flex-row items-center">
        <div
          data-testid="btn_show_mobile_menu"
          onClick={() => toggleMenuMobile(!isOpenMenuMobile)}
          className="cursor-pointer rounded-xl active:bg-slate-400 relative"
        >
          <Icon
            className="transition-all duration-1000"
            path={isOpenMenuMobile ? mdiClose : mdiMenu}
            title="Web"
            size={1}
          />
        </div>

        <div className="md:w-[90%] flex justify-center items-center">
          <img
            draggable={false}
            className="lg:hidden w-[100px] h-[32px]"
            src={HolaflyLogo}
            alt="Holafly_logo_mobile"
          />
        </div>

        <div className="ml-auto flex items-center lg:hidden">
          <img
            onClick={() => intercom.show()}
            className="w-[24px] h-[24px]"
            src={SupportIcon}
            alt="support chat"
            draggable={false}
          />
          <hr className="h-[12px] mx-[12px] rounded-[12px] w-[1px] bg-[#CCD0D7]" />
          <CoinsInfo coins={referralUser?.points_balance || 0} />
        </div>
      </div>

      {isOpenMenuMobile && <MobileMenu />}
    </>
  );
};
