import { useEffect } from "react";
import { Routes, useSearchParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useSetLanguageFromUrl } from "../hooks";
import { useSetCurrencyFromUrl } from "../hooks/useSetCurrencyFromUrl";
import { useCustomerStore } from "../store/customerStore";
import { ICustomerStore } from "../interfaces/store";
import { GenerateRoutes } from "./GenerateRoutes";
import { RoutesPublic } from "../routes/Routes";

export const AppRouter = (): JSX.Element => {
  const intercom = useIntercom();
  let [searchParams] = useSearchParams();
  const { language } = useCustomerStore((store: ICustomerStore) => store);
  const handleSetLanguageFromUrl = useSetLanguageFromUrl();
  const handleSetCurrencyFromUrl = useSetCurrencyFromUrl();

  useEffect(() => {
    handleSetLanguageFromUrl();
    handleSetCurrencyFromUrl();
  }, [searchParams]);

  useEffect(() => {
    // Configura el idioma de Intercom aquí
    return intercom.update({ languageOverride: language });
  }, [intercom, language]);

  return <Routes>{GenerateRoutes(RoutesPublic)}</Routes>;
};
