import { Dispatch, SetStateAction } from "react";
import { mdiInformationOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { t } from "i18next";
import HButton from "../../../components/common/hbutton/HButton";
import { Trans } from "react-i18next";
import { LinkText } from "../../../components/linkText/LinkText";

export const InformationCard = ({
  handleModal,
}: {
  handleModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  return (
    <div
      data-testid="information-card-delete-account"
      className=" ml-auto mr-auto flex flex-col gap-[24px] p-[30px] w-full lg:min-w-[496px] lg:max-w-[600px] h-full lg:h-[330px] border border-[#E4E6EA] rounded-[12px]">
      <article className="flex gap-[3px] items-center">
        <Icon path={mdiInformationOutline} size={1} />
        <h4 className="text-[14px] font-bold">
          {t("global.customerV2.deleteAccount.informationCard.title")}
        </h4>
      </article>

      <p className="text-[14px] font-normal w-full">
        {t("global.customerV2.deleteAccount.informationCard.message")}
      </p>

      <p className="text-[14px] font-normal w-full">
        <Trans
          i18nKey="global.customerV2.deleteAccount.cardAdvice.learnAbout.privacyPolicy"
          components={{
            a: (
              <LinkText
                to={t("global.footer.privacyPolicyLink")}
                className="font-bold underline cursor-pointer"
              />
            ),
          }}
        />
      </p>

      <strong className="text-[14px]">
        {t("global.customerV2.deleteAccount.informationCard.explanationText")}
      </strong>

      <HButton
        type="button"
        name="button open modal delete account"
        onClick={() => handleModal(true)}
        className="mt-auto py-3 px-5 flex justify-center items-center h-[48px] hover:from-magenta-bold hover:to-magenta-bold bg-gradient-to-r from-magenta to-magenta-900 text-white rounded-xl"
        text={t("global.customerV2.deleteAccount.informationCard.btnLabel")}
      />
    </div>
  );
};
