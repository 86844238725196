import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import HButton from "../common/hbutton/HButton";
import { useCustomerStore } from "../../store/customerStore";
import { ILayoutPageWithMapBackgroundProps, ICustomerStore } from "../../interfaces";
import Map from "../../map.svg";
import { ArrowWest } from "../svg";

export const LayoutPageWithMapBackground = ({
  children,
  title,
  btnLabel,
  goBack,
  handleBack
}: ILayoutPageWithMapBackgroundProps) => {
  const { customerId: customerIdZustand } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  const customerId = localStorage.getItem("customerId") || customerIdZustand;

  const navigate = useNavigate();

  const handleNavigate = () => {
    if (goBack) {
      navigate(-1);
      return;
    }
    navigate(`/${customerId}`);
  };

  return (
    <div
      data-testid="layoutPage-withMap"
      className="relative mt-[60px] px-4 pt-10 lg:max-w-[1440px]  flex flex-col w-full items-center"
    >
      <img
        className="absolute -top-[80px] lg:-top-[170px] opacity-50 -z-1"
        src={Map}
        alt="MAP"
      />
      <div className="relative flex w-full lg:max-w-[1016px] justify-center items-center">
        <div className="absolute left-0 flex items-center gap-4">
          <HButton
            type="button"
            name="button go back"
            containerStyles="border rounded-xl border-[#E4E6EA] hover:bg-neutral-200 h-[48px] w-[48px] 
            flex justify-center items-center transition-all duration-300"
            className="p-3 text-center"
            icon={<ArrowWest />}
            onClick={handleBack ? handleBack : handleNavigate}
          />
          <p
            data-testid="btn-label"
            className="hidden lg:block text-sm text-black font-bold"
          >
            {btnLabel
              ? btnLabel
              : t("global.customerV2.buttons.buttonBackToDetail")}
          </p>
        </div>

        <h1
          id="error_alert"
          className="text-[22px] lg:text-[34px] font-medium "
        >
          {title}
        </h1>
      </div>
      <div className="w-full flex flex-col lg:max-w-[1016px] my-[50px] md:mt-[100px]">
        {children}
      </div>
    </div>
  );
};
