import { SVGProps } from "react";

const InstagramLight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M8.5 6.1a2.4 2.4 0 1 0 0 4.8 2.4 2.4 0 0 0 0-4.8Zm0-1.6a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm5.2-.2a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM8.5 2.1c-1.979 0-2.302.006-3.223.047-.627.03-1.048.113-1.438.265a2.31 2.31 0 0 0-.864.563c-.25.242-.443.537-.564.864-.152.392-.236.812-.264 1.438C2.105 6.16 2.1 6.469 2.1 8.5c0 1.98.006 2.303.047 3.223.03.627.113 1.048.264 1.438.136.348.296.598.562.864.27.269.52.43.864.562.395.153.816.238 1.44.267.883.041 1.192.046 3.223.046 1.98 0 2.303-.005 3.223-.046.626-.03 1.048-.114 1.438-.265.346-.135.598-.296.864-.562.27-.27.43-.52.563-.864.152-.394.237-.816.266-1.44.041-.883.046-1.192.046-3.223 0-1.979-.005-2.302-.046-3.223-.03-.626-.114-1.048-.266-1.438a2.328 2.328 0 0 0-.562-.864 2.306 2.306 0 0 0-.864-.564c-.392-.152-.813-.236-1.439-.264C10.84 2.105 10.531 2.1 8.5 2.1Zm0-1.6c2.174 0 2.445.008 3.298.048.852.04 1.432.174 1.942.372a3.9 3.9 0 0 1 1.418.923c.407.4.721.883.922 1.417.198.51.332 1.09.372 1.943.038.852.048 1.124.048 3.297 0 2.174-.008 2.445-.048 3.298-.04.852-.174 1.432-.372 1.942a3.905 3.905 0 0 1-.922 1.418 3.93 3.93 0 0 1-1.418.922c-.51.198-1.09.332-1.942.372-.853.038-1.124.048-3.298.048-2.173 0-2.445-.008-3.297-.048-.852-.04-1.432-.174-1.943-.372a3.911 3.911 0 0 1-1.417-.922A3.923 3.923 0 0 1 .92 13.74c-.198-.51-.332-1.09-.372-1.942C.511 10.945.5 10.674.5 8.5c0-2.173.008-2.445.048-3.297C.588 4.35.722 3.77.92 3.26c.2-.534.515-1.018.923-1.417.4-.407.883-.722 1.417-.923C3.77.722 4.35.588 5.203.548 6.055.511 6.327.5 8.5.5Z"
    />
  </svg>
);
export default InstagramLight;
