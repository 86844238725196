import React from "react";
import { ContentCopy } from "../content/ContentCopy";
import { SkeletonCardActivationCode } from "../skeleton/SkeletonCardActivationCode";
import { IInstallCodeCardProps } from "../../interfaces";

export const InstallCodeCard = ({
  bgContainer,
  orientation,
  text,
  isLoading,
  title,
}: IInstallCodeCardProps): JSX.Element => {
  return (
    <ContentCopy
      bgContainer={bgContainer}
      orientation={orientation}
      textCopy={text}
    >
      {isLoading ? (
        <SkeletonCardActivationCode />
      ) : (
        <div className="flex flex-col gap-2">
          <h3 className="font-bold text-sm text-neutral-charcoal">{title}</h3>
          <p className="text-sm font-normal text-neutral-charcoal break-all min-w-[80%]">
            {text}
          </p>
        </div>
      )}
    </ContentCopy>
  );
};
