import { Fragment } from "react";
import { useCustomerStore } from "../../../store/customerStore";
import { ICustomerStore, IDataAndStatusDestination } from "../../../interfaces";

export const BundleText = ({
  showStatusDestination,
  card,
}: IDataAndStatusDestination): JSX.Element => {
  const { language } = useCustomerStore((state: ICustomerStore) => state);

  return (
    <Fragment>
      {!showStatusDestination ? (
        <p
          data-testid="boundle"
          className="font-medium text-lg text-[#656669] truncate"
        >
          {card.boundle ? card.boundle[language] : ""}
        </p>
      ) : (
        <p
          data-testid="destination_remaining_days"
          className="font-bold text-xl"
        >
          {card.destination ? card.destination[language] : ""}
        </p>
      )}
    </Fragment>
  );
};
