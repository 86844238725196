export const THEME = {
	colors: {
		mainColor: '#fff',
		secondaryColor: '#c74b5e',
		neutroGris10: '#eaeaea',
		black: '#292b2e',
		green: 'yellowgreen',
		yellow: 'gold',
		primary: '#E6485C',
		"primary-pink": '#C8495C',
		'primary-yellow': '#FFF0AB',
		'primary-blue': '#BFEBFF',
		'primary-orange': '#FFCCB0',
		'neutral-charcoal': '#292B2E',
		'neutral-charcoal-60': '#292B2E99',
		'neutral-charcoal-30': '#292B2E4D',
		'neutral-charcoal-10': '#292B2E1A',
		'digital-purple': '#643560',
		'digital-green': '#48EC86',
		'digital-green-hover': '#22E46B',
		'digital-blue': '#6ED2FF',
		'digital-blue-hover': '#3DBFFA',
		'accent-primary': '#FF2642',
		'accent-yellow': '#FFE778',
		'accent-blue': '#73D3FF',
		'accent-orange': '#FF8A4A',
		'ntn-success': '#17E317',
		'ntn-error': '#FA2D2D',
		'ntn-warning': '#FFC400',
		'ntn-info': '#0084FF'
	}
}