import { useState, useEffect } from "react";
import { useAxios } from ".";
import { IOrderTopUp } from "../interfaces";

export const useGetQueuedBundles = (id: string) => {
  const { apiCall } = useAxios();

  const [queuedBundles, setQueuedBundles] = useState<IOrderTopUp[]>([]);
  const getQueuedBundles = async () => {
    try {
      const response = await apiCall(
        "GET",
        `${process.env.REACT_APP_API_URL}/topups/getQueuedbundles/${id}`
      );
      setQueuedBundles(response.data.data);
    } catch (error) {
      setQueuedBundles([]);
    }
  };

  useEffect(() => {
    if (id) {
      getQueuedBundles();
    }
  }, []);

  return { queuedBundles };
};
