import { format } from "date-fns";
import { FlagCountry } from "../common/flag/FlagCountry";
import { useCustomerStore } from "../../store/customerStore";
import { IOrderEsim, ICustomerStore } from "../../interfaces";

import "../../styles/scrollbar.css";

interface ICardExpiredProps {
  data: IOrderEsim;
}
export const CardExpired = ({ data }: ICardExpiredProps): JSX.Element => {
  const { language } = useCustomerStore((store: ICustomerStore) => store);

  return (
    <div
      data-testid="card-expired"
      className="hover:shadow-card transition-all duration-300 border border-[#E4E6EA] rounded-xl w-full md:w-[322px] max-h-[302px] p-[30px]"
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-3">
          <div className="flex flex-row gap-[1px]">
            <FlagCountry
              state="FINISHED"
              flagImg={data.flagUrl || ""}
              initDate={data.init_date}
            />
          </div>
          <h2 className="font-bold text-base text-neutral-charcoal">
            {data.destination[language]}
          </h2>
        </div>

        <div className="scrollbar flex flex-col gap-[15px] overflow-y-auto max-h-[160px]">
          <div className="flex flex-row gap-[5px] md:gap-0 justify-between">
            <div className="flex flex-col gap-[3px]">
              <p
                data-testid="activation-period"
                className="font-normal text-base text-neutral-charcoal"
              >
                {data.activation_date
                  ? format(new Date(data.activation_date || ""), "dd/MM/yyyy")
                  : "null"}{" "}
                -{" "}
                {data.deactivation_date
                  ? format(new Date(data.deactivation_date), "dd/MM/yyyy")
                  : "null"}
              </p>
              <span
                data-testid="boundle"
                className="text-neutral-500 font-normal text-xs"
              >
                {data.boundle[language]}
              </span>
            </div>
            {/* <TopupIndicator /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
