import { t } from "i18next";

export const downloadPlatformApp = () => {
  let platform: string = "";

  const userAgent = navigator.userAgent;
  const isWindows = /Windows/.test(userAgent);
  const isMac = /Mac/.test(userAgent);


  if (isWindows) {
    platform = "downloadAppUrlAndroid";
  } else if (isMac) {
    platform = "downloadAppUrlIos";
  } else {
    platform = "downloadAppUrlAndroid";
  }
  window.open(t(`global.header.${platform}`), "_blank", "noopener,noreferrer");
};
