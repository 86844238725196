import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { Trans, useTranslation } from "react-i18next";
import HButton from "../../../../components/common/hbutton/HButton";
import { ConnectionBadPhone } from "../../../../components/svg";

export interface IPendingTopupModalProps {
  closeModal: () => void;
}

export const PendingTopupModal = ({
  closeModal,
}: IPendingTopupModalProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="pending-topup-modal"
      className="relative bg-white p-[50px] rounded-xl w-full md:w-[392px] mx-4 gap-3">
      <button
        data-testid="close-pending-topup-modal"
        onClick={closeModal}
        className="absolute top-4 right-4 border border-neutral-300 hover:bg-neutral-200 rounded-full w-8 h-8 flex 
    justify-center items-center transition-all duration-300">
        <Icon path={mdiClose} size={0.8} />
      </button>
      <div className="flex flex-col gap-6 justify-center items-center">
        <ConnectionBadPhone />
        <h2 className="text-[22px] font-medium text-neutral-charcoal text-center">
          {t("global.customerV2.modalWarningTopUpHome.title")}
        </h2>
        <p className="text-base font-normal text-neutral-charcoal">
          <Trans
            i18nKey="global.customerV2.modalWarningTopUpHome.description"
            components={{
              b: <b />,
            }}
          />
        </p>
        <HButton
          type="button"
          name="button view my plan"
          onClick={closeModal}
          containerStyles="w-full"
          className="px-5 py-3 h-12 text-base font-medium text-white border-none rounded-xl
          hover:bg-gradient-to-r hover:from-magenta-bold hover:to-magenta-bold bg-gradient-to-r from-magenta to-magenta-900"
          text={t("global.customerV2.modalWarningTopUpHome.buttonMyPlan")}
        />
      </div>
    </div>
  );
};
