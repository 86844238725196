import { useTranslation } from "react-i18next";
import { IChartProps } from "../../interfaces";

import "./style.css";

export const ChartCircle = ({
  size,
  percent,
  trackWidth,
  trackColor = `#292B2E`,
  indicatorWidth,
  indicatorColor = `#292B2E`,
  indicatorCap = `round`,
  labelColor = `#333`,
  spinnerMode = false,
  spinnerSpeed = 1,
  dataConsumed,
  dataRestant,
  isConsumed,
  customContent,
}: IChartProps): JSX.Element => {
  const { t } = useTranslation();

  const center = size / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - percent) / 100);

  return (
    <div
      data-testid="chart-circle"
      className="svg-pi-wrapper -top-[5px]"
      style={{ width: size, height: size }}>
      <svg className="svg-pi" style={{ width: size, height: size }}>
        <circle
          className="svg-pi-track"
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={trackColor}
          strokeWidth={trackWidth}
        />
        <circle
          className={`svg-pi-indicator transition-all duration-300 ${
            spinnerMode ? "svg-pi-indicator--spinner" : ""
          }`}
          style={{ animationDuration: String(spinnerSpeed * 1000) }}
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={indicatorColor}
          strokeWidth={indicatorWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          strokeLinecap={indicatorCap}
        />
      </svg>

      <div
        className="svg-pi-label w-full h-full flex flex-col justify-center items-center"
        style={{ color: labelColor }}>
        {!isConsumed && !customContent ? (
          !spinnerMode && (
            <>
              <p className="text-base font-bold text-neutral-charcoal">
                {dataConsumed}
              </p>
              <p className="text-xs font-bold text-neutral-500">
                /{dataRestant}
              </p>
            </>
          )
        ) : !customContent ? (
          <p className="font-bold text-xs w-[73px]">
            {t("global.customerV2.details.pending.textInstallBeforeYourTrip")}
          </p>
        ) : (
          customContent
        )}
      </div>
    </div>
  );
};
