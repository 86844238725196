import { useState, ChangeEvent, useEffect } from "react";
import { mdiEmailOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { IFloatingLabelInput } from "../../interfaces";

export const FloatingLabelInput = ({
  type,
  name,
  label,
  value,
  error,
  errorMessage,
  iconPath,
  onChange,
}: IFloatingLabelInput): JSX.Element => {
  const [active, setActive] = useState<Boolean>(false);

  const icon = iconPath ? iconPath : mdiEmailOutline;
  const isError = error ? error : false;

  const handleActivation = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value);
    setActive(true);
  };

  const isLongLabel = label.length > 47;
  const paddingTopActive = active
    ? isLongLabel
      ? "pt-10 xs:pt-7"
      : "pt-7"
    : "pt-3";

  useEffect(() => {
    setActive(!!value);
  }, [value]);

  return (
    <div className="flex flex-col">
      <div
        data-testid="floating_labelInput"
        className={`flex flex-row gap-5 relative border rounded-xl text-white mb-2 ${
          isError ? "border-semantic-error-500" : "border-neutral-300"
        }`}
      >
        {icon && (
          <div className="absolute mx-3 h-full flex items-center">
            <Icon path={icon} size={0.7} className="text-neutral-charcoal" />
          </div>
        )}

        <div className="flex flex-col w-full">
          <input
            className={`pr-8 pl-9 outline-none w-full text-black rounded-xl bg-transparent text-base font-normal 
            transition-all duration-200 ease-in-out pb-3 ${paddingTopActive}`}
            id={name}
            name={name}
            value={value}
            type={type}
            onChange={handleActivation}
          />
          <label
            className={`
              ml-5  absolute top-0 left-0 flex items-center text-neutral-500 font-normal  
              py-3 px-4 transition-all duration-200 ease-in-out ${
                active ? "text-xs" : "text-xs h-full"
              }`}
            data-testid="label_email"
            htmlFor={name}
          >
            <p>{label}</p>
          </label>
        </div>
      </div>

      {isError && (
        <p className="text-semantic-error-500 text-xs font-bold">
          {errorMessage}
        </p>
      )}
    </div>
  );
};
