import { ChangeEvent } from "react";
import { IInputLabel } from "../../interfaces";

export const InputLabel = ({
  type,
  name,
  label,
  value,
  error = false,
  errorMessage,
  onChange,
  disabled,
  isEdit,
  textPlaceholder,
  labelStyle = "text-neutral-500 text-xs",
  inputStyle = "text-base",
  componentStyle = "border-neutral-300"
}: IInputLabel): JSX.Element => {
  const styleError = error ? "border-semantic-error-500" : componentStyle;

  const bgDisabledInput = isEdit
    ? "bg-neutral-200 text-neutral-400"
    : "bg-transparent text-black";

  const styleDisabledLabel = isEdit ? "text-neutral-300 text-xs" : labelStyle;

  const labelSM = label.length > 40 ? `${label.slice(0, 40)}...` : label;

  const handleActivation = (e: ChangeEvent<HTMLInputElement>): void =>
    onChange(e.target.value);

  return (
    <div className="flex flex-col w-full">
      <div
        data-testid="floating_labelInput"
        className={`flex flex-row gap-5 relative border rounded-xl text-white mb-2 ${styleError}`}
      >
        <div className="flex flex-col w-full">
          <input
            aria-label={name}
            className={`${bgDisabledInput} ${inputStyle} pt-[30px] pb-3 px-[18px] outline-none w-full rounded-xl
            font-normal transition-all duration-200 ease-in-out`}
            id={name}
            name={name}
            value={value}
            type={type}
            onChange={handleActivation}
            placeholder={textPlaceholder}
            disabled={disabled}
          />
          <label
            className={`${styleDisabledLabel} absolute top-0 left-0 flex items-center font-normal  
            py-3 px-[18px] transition-all duration-200 ease-in-out`}
            htmlFor={name}
          >
            <p className="hidden md:block w-full">{label}</p>
            <p className="md:hidden w-full">{labelSM}</p>
          </label>
        </div>
      </div>

      {error && (
        <p className="text-semantic-error-500 text-xs font-bold">
          {errorMessage}
        </p>
      )}
    </div>
  );
};
