import { useTranslation } from "react-i18next";
import { ContainerCardInvoice } from "./ContainerCardInvoice";
import { Divider } from "../../../../components/common/divider/Divider";
import { IPersonalInfoCard } from "../../../../interfaces";

export const PersonalInfoCard = ({
  name,
  lastName,
  email,
  phone,
}: IPersonalInfoCard): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ContainerCardInvoice>
      <div data-testid="personal-info-card" className="flex flex-col gap-6">
        <div className="flex flex-col  gap-2 md:gap-1">
          <h2 className="text-neutral-charcoal text-base font-bold">
            {t("global.customerV2.invoice.details.personalInfoCard.title")}
          </h2>

          <h2 className="text-neutral-charcoal text-sm font-normal">
            {t("global.customerV2.invoice.details.personalInfoCard.subtitle")}
          </h2>
        </div>

        <Divider className="bg-neutral-300 h-[1px] w-full" />

        <div className="flex flex-col gap-4 md:gap-6">
          <div className="flex flex-row justify-between items-center">
            <p className="text-neutral-charcoal text-sm font-normal">
              {t(
                "global.customerV2.invoice.details.personalInfoCard.textFieldName"
              )}
            </p>
            <p className="text-neutral-charcoal text-base font-bold text-right w-3/4 md:w-auto truncate">
              {name} {lastName}
            </p>
          </div>

          <div className="flex flex-row justify-between items-center">
            <p className="text-neutral-charcoal text-sm font-normal">
              {t(
                "global.customerV2.invoice.details.personalInfoCard.textFieldEmail"
              )}
            </p>
            <p className="text-neutral-charcoal text-base font-bold text-right w-3/4 md:w-auto truncate">
              {email}
            </p>
          </div>

          <div className="flex flex-row justify-between items-center">
            <p className="text-neutral-charcoal text-sm font-normal">
              {t(
                "global.customerV2.invoice.details.personalInfoCard.textFieldPhone"
              )}
            </p>
            <p className="text-neutral-charcoal text-base font-bold">{phone}</p>
          </div>
        </div>
      </div>
    </ContainerCardInvoice>
  );
};
