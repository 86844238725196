import * as React from "react";
import { SVGProps } from "react";

const CalendaryOutlineXL = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="121"
    height="121"
    viewBox="0 0 121 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.16699 40.666H113.834V91.466C113.834 98.1866 113.834 101.547 112.526 104.114C111.375 106.372 109.539 108.208 107.282 109.358C104.715 110.666 101.354 110.666 94.6337 110.666H26.367C19.6464 110.666 16.286 110.666 13.7191 109.358C11.4612 108.208 9.62539 106.372 8.47491 104.114C7.16699 101.547 7.16699 98.1866 7.16699 91.466V40.666Z"
      fill="white"
      stroke="#292B2E"
    />
    <path
      d="M7.16699 33.2C7.16699 26.4794 7.16699 23.1191 8.47491 20.5521C9.62539 18.2942 11.4612 16.4584 13.7191 15.3079C16.286 14 19.6464 14 26.367 14H94.6337C101.354 14 104.715 14 107.282 15.3079C109.539 16.4584 111.375 18.2942 112.526 20.5521C113.834 23.1191 113.834 26.4794 113.834 33.2V40.6667H7.16699V33.2Z"
      fill="white"
      stroke="#292B2E"
    />
    <path d="M27.167 8.16602V21.4993" stroke="#292B2E" strokeLinecap="round" />
    <path d="M92.167 8.16602V21.4993" stroke="#292B2E" strokeLinecap="round" />
  </svg>
);
export default CalendaryOutlineXL;
