import "./style.css";

type BtnType = "outline" | "primary" | "custom";

interface ILoaderProps {
  color?: string;
  className?: string;
}

const defaultBtnStyles =
  "w-full transition-all duration-300 text-[14px] font-bold rounded-lg p-2 border-2 flex items-center justify-center";

const Loader = ({ color, className }: ILoaderProps) => {
  const classNameStyle = className ? className : "loader";
  return <div data-testid="loader" className={classNameStyle}></div>;
};

export default Loader;
