import { Trans } from "react-i18next";
import { LinkText } from "../linkText/LinkText";

import SLIDE1 from "../../assets/stepsLogin/slide1.png";
import SLIDE2 from "../../assets/stepsLogin/slide2.png";
import SLIDE3 from "../../assets/stepsLogin/slide3.png";

const styles =
  "absolute text-[28px] font-normal text-neutral-charcoal bottom-[10%] text-center";

const translationComponent = {
  p: <LinkText className="font-bold" />,
}

export const slidesDefault = [
  {
    id: 1,
    content: (
      <p className={`${styles} w-64`}>
        <Trans
          i18nKey="global.splash.firstLoaderText"
          components={translationComponent}
        />
      </p>
    ),
    image: SLIDE1,
  },
  {
    id: 2,
    content: (
      <p className={`${styles} w-[410px]`}>
        <Trans
          i18nKey="global.splash.secondLoaderText"
          components={translationComponent}
        />
      </p>
    ),
    image: SLIDE2,
  },
  {
    id: 3,
    content: (
      <p className={`${styles} w-96`}>
        <Trans
          i18nKey="global.splash.threeLoaderText"
          components={translationComponent}
        />
      </p>
    ),
    image: SLIDE3,
  },
];
