import iosBanner from "../../../../../assets/details/pending_banner_ios.svg";
import Icon from "@mdi/react";
import { mdiCreation, mdiWifi, mdiWifiOff } from "@mdi/js";
import { CallDown } from "../../../../../components/svg";
import simShape from "../../../../../assets/details/sim_shape.svg";
import { t } from "i18next";
import { Trans } from "react-i18next";

export const AutomaticOption = () => {

  const informationItems = [
    {
      icon: <Icon path={mdiWifi} size={1} />,
      text: t("global.OneButInst_AutomInfo_One"),
    },
    {
      icon: <Icon path={mdiWifiOff} size={1} />,
      text: t("global.OneButInst_AutomInfo_Two"),
    },
    {
      icon: <img src={simShape} alt="sim-shape" />,
      text: t("global.OneButInst_AutomInfo_Three"),
    },
  ];
  return (
    <div className="w-full flex flex-col gap-[20px] items-center">
      <article className="relative w-full flex items-center">
        <img className="w-full" src={iosBanner} alt="ios-banner" />
        <p className="text-center absolute w-full h-full p-[15px]">
        <Trans
            i18nKey={"global.OneButInst_Automatic_warning_device"}
            components={{
              strong: <strong />,
            }}
          />
         
        </p>
      </article>
      <article className="gap-5 w-full flex flex-col items-center rounded-xl border border-[#E4E6EA] p-5">
        <div className="h-8 w-[163px] bg-[#DCF4FF] flex justify-center items-center gap-1 rounded-[80px]">
          <Icon path={mdiCreation} size={0.8} />
          <p className="text-[16px] font-medium">
            {t("global.OneButInst_ChooseMethod_Tag")}
          </p>
        </div>

        <article className="w-full flex gap-2 items-center justify-center">
          <CallDown />
          <p className="text-[16px] font-bold">
            {t("global.login.frequentDoubts.titleActiveEsim")}
          </p>
        </article>
        <p className="text-[16px] font-bold w-full">
          {t("global.OneButInst_InfoInstal_Title")}
        </p>

        {informationItems.map((item, idx) => (
          <article key={idx} className="flex items-start w-full gap-3">
            {item.icon}
            <p className="max-w-[271px] text-[16px] font-normal">{item.text}</p>
          </article>
        ))}
      </article>
    </div>
  );
};
