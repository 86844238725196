import React, { SVGProps } from "react";

export const ArrowWest = (props: SVGProps<SVGSVGElement>,fill?:string): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path
        fill={fill ? fill : "#292B2E"}
        d="M4.026 12c0-.1.015-.197.043-.29a.52.52 0 0 1 .172-.247l4.746-4.746a.563.563 0 0 1 .441-.194.63.63 0 0 1 .461.194.672.672 0 0 1 .172.462.67.67 0 0 1-.172.461l-3.736 3.716h13.23a.626.626 0 0 1 .643.644c0 .186-.06.34-.182.462a.628.628 0 0 1-.462.182H6.152l3.737 3.737a.636.636 0 0 1 .183.44.595.595 0 0 1-.183.462.63.63 0 0 1-.46.194.563.563 0 0 1-.442-.194l-4.746-4.746a.52.52 0 0 1-.172-.247.984.984 0 0 1-.043-.29Z"
      />
    </svg>
  );
};
