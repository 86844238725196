import React, { SVGProps } from "react";

const UserSupport = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#292B2E"
        d="M12.605 19.695a.624.624 0 0 1-.442-.179.597.597 0 0 1-.19-.453c0-.182.064-.333.19-.453a.624.624 0 0 1 .442-.178h5.537c.07 0 .13-.025.18-.074a.246.246 0 0 0 .073-.18v-6.083a5.73 5.73 0 0 0-.463-2.295 5.96 5.96 0 0 0-1.264-1.874 5.96 5.96 0 0 0-1.873-1.263A5.731 5.731 0 0 0 12.5 6.2c-.814 0-1.579.154-2.295.463a5.96 5.96 0 0 0-1.873 1.263A5.96 5.96 0 0 0 7.068 9.8a5.731 5.731 0 0 0-.463 2.295v4.484c0 .168-.06.316-.18.442a.595.595 0 0 1-.451.19 1.42 1.42 0 0 1-1.042-.432 1.42 1.42 0 0 1-.432-1.042v-1.684c0-.281.077-.537.232-.77.154-.23.357-.423.61-.578l.042-1.095c.07-.968.313-1.86.727-2.673A7.163 7.163 0 0 1 7.7 6.842a7.18 7.18 0 0 1 2.2-1.358A7.045 7.045 0 0 1 12.5 5c.912 0 1.783.161 2.61.484a7.01 7.01 0 0 1 2.2 1.358 7.312 7.312 0 0 1 1.58 2.084c.414.807.656 1.695.726 2.663l.042 1.095c.252.127.456.298.61.516.155.217.232.46.232.726v1.937c0 .253-.077.488-.232.706-.154.217-.358.389-.61.515v1.095c0 .421-.148.779-.442 1.074a1.461 1.461 0 0 1-1.074.442h-5.537Zm-2.463-5.832a.732.732 0 0 1-.537-.221.706.706 0 0 1-.22-.516c0-.21.073-.39.22-.537a.733.733 0 0 1 .537-.22c.197 0 .368.073.516.22a.732.732 0 0 1 .22.537.705.705 0 0 1-.22.516.705.705 0 0 1-.516.221Zm4.716 0a.705.705 0 0 1-.516-.221.705.705 0 0 1-.22-.516c0-.21.073-.39.22-.537a.706.706 0 0 1 .516-.22c.21 0 .39.073.537.22.147.148.22.327.22.537a.707.707 0 0 1-.22.516.732.732 0 0 1-.537.221Zm-7.095-1.368c-.056-.772.053-1.477.327-2.116a4.932 4.932 0 0 1 1.094-1.622c.456-.441.98-.782 1.569-1.02a4.734 4.734 0 0 1 1.79-.358c1.206 0 2.248.379 3.125 1.137a4.615 4.615 0 0 1 1.59 2.842 6.211 6.211 0 0 1-3.368-1c-1.011-.653-1.769-1.498-2.274-2.537a6.528 6.528 0 0 1-1.358 2.842 6.134 6.134 0 0 1-2.495 1.832Z"
      />
    </svg>
  );
};

export default UserSupport;
