import { ISvg } from "../../../interfaces";

const LogoutOffset = ({ color }: ISvg): JSX.Element => (
  <svg
    data-testid="logout-offset"
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    color={color}>
    <path
      fill={color}
      d="M15.21 16.032a.736.736 0 0 1-.206-.503c0-.183.069-.343.206-.48l1.737-1.737H9.952a.664.664 0 0 1-.685-.686.667.667 0 0 1 .685-.685h6.995l-1.737-1.76a.658.658 0 0 1-.206-.48c0-.183.069-.343.206-.48a.658.658 0 0 1 .48-.206.62.62 0 0 1 .457.183l2.857 2.857a.755.755 0 0 1 .172.262.862.862 0 0 1 0 .617.753.753 0 0 1-.172.264l-2.857 2.857c-.137.152-.293.22-.468.206a.741.741 0 0 1-.47-.229ZM5.86 20.626c-.457 0-.846-.16-1.166-.48-.32-.32-.48-.708-.48-1.165V6.272c0-.457.16-.846.48-1.166.32-.32.709-.48 1.166-.48h5.463c.183 0 .343.065.48.194.137.13.206.294.206.492a.646.646 0 0 1-.206.491.676.676 0 0 1-.48.195H5.86c-.06 0-.122.03-.183.091-.06.061-.091.122-.091.183v12.709c0 .06.03.121.091.182s.122.092.183.092h5.463c.183 0 .343.065.48.194.137.13.206.293.206.492a.649.649 0 0 1-.206.492.678.678 0 0 1-.48.193H5.86Z"
    />
  </svg>
);
export default LogoutOffset;
