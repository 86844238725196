import { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiExportVariant } from "@mdi/js";

import CopyToClipboard from "../../../../components/common/copyToClipboard/CopyToClipboard";
import { TooltipListShareReferrer } from "../../../../components/tooltip/TooltipListShareReferrer";

import { useCustomerStore } from "../../../../store/customerStore";
import { ICustomerStore } from "../../../../interfaces/store";

import { useDataLayer } from "../../../../hooks/useDataLayer";
import { IReferralFriendProps } from "../../../../interfaces";

export const ReferralFriendLite = ({}: IReferralFriendProps): JSX.Element => {
  const { t } = useTranslation();
  const { referralLinkUser } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  const [isShowTooltip, setIsShowTooltip] = useState(false);

  const { setDataLayer } = useDataLayer();

  const handleCopyEvent = () => {
    setDataLayer({
      event: "custom_event",
      event_name: "aff_referral_copied",
    });
  };

  return (
    <div
      data-testid="referral-friend"
      className="w-full  flex flex-col items-center"
    >
      <div className="relative  w-full  shadow-card py-[8px] px-[16px] rounded-[16px] border flex justify-between items-center">
        <p className="w-full text-ellipsis max-w-[400px] lg:max-w-[600px]  overflow-hidden whitespace-nowrap">
          {referralLinkUser || ""}
        </p>
        <div className="flex items-center justify-center ">
          <div onClick={handleCopyEvent} className="p-[12px]">
            <CopyToClipboard copyText={referralLinkUser || ""} />
          </div>
          <div className="p-[12px] cursor-pointer flex flex-col relative">
            <button
              data-testid="show-tooltip"
              onClick={() => setIsShowTooltip(!isShowTooltip)}
            >
              <Icon path={mdiExportVariant} size={0.9} />
            </button>

            <TooltipListShareReferrer
              className={`z-20 ${
                isShowTooltip ? "block" : "hidden"
              } transition-all duration-300 mt-10 absolute left-[85px] md:left-[calc(100%-84px)] lg:right-[calc(100%-10px)]`}
              arrowPosition="top"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
