import { makeApiCall } from "../apiCall";
import { IBodyRequestOrder, IPostRequestOrder } from "../../interfaces";
import { API_URLS } from "../../constants";
import { t } from "i18next";

export const postRequestOrder = async (
  body: IBodyRequestOrder
): Promise<IPostRequestOrder> => {
  const {URL_REQUEST_INVOICE} = API_URLS;

  try {
    await makeApiCall<{ message: string }>("POST", URL_REQUEST_INVOICE, body);
    return { message: 'successful creation', isError: false };
  } catch (error) {
    return {
      message: error.response?.data?.message || t("global.customerV2.errorScreen.internal"),
      isError: true,
    };
  }
};
