import { CalendaryOutlineSM } from "../../../../components/svg";

interface UnlimitedComsuptionSMProps {
  daysRestant: number | undefined;
}

export const UnlimitedComsuptionSM = ({
  daysRestant,
}: UnlimitedComsuptionSMProps): JSX.Element => {
  return (
    <div data-testid="unlimited-comsuption" className="relative">
      <CalendaryOutlineSM />
      <div className="flex justify-center items-center absolute  right-0 left-0 top-[7px] ">
        <p
          data-testid="days-restant"
          className="text-[7px] font-medium text-[#292B2E]">
          {daysRestant && daysRestant}
        </p>
      </div>
    </div>
  );
};
