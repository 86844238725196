import { useState, useEffect } from "react";
import { IAlertProps } from "../../interfaces/components/alert/alertInterface";
import styleUnitMeasure from "../../styles/stylesUnitMeasure";

export const Alert = ({
  children,
  type,
  minHeight,
  minWidth,
  customStyle,
}: IAlertProps): JSX.Element => {
  const [style, setStyle] = useState("");

  const width = `${
    minWidth ? `md:min-w-[${styleUnitMeasure.width[minWidth]}]` : "w-fit"
  }`;
  const height = `${
    minHeight ? `md:min-h-[${styleUnitMeasure.height[minHeight]}]` : "w-fit"
  }`;

  const basicStyle = `flex justify-start items-center flex-col px-5 py-4 mt-5 w-full h-full border rounded-lg ${width} ${height}`;

  useEffect(() => {
    const styleObject = {
      default: `${basicStyle} bg-blue-50 border-blue-200`,
      danger: `${basicStyle} bg-red-100 border-red-300`,
      warning: `${basicStyle} bg-semantic-100 border-semantic-300`,
      custom: `${customStyle}`,
    };

    setStyle(styleObject[type]);
  }, [type, basicStyle, customStyle]);

  return (
    <div role="alert" data-testid="alert_eSim" className={style}>
      {children}
    </div>
  );
};
