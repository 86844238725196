import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IResponseUseGuideHelpCenter } from "../interfaces";

export const useGuideHelpCenter = (): IResponseUseGuideHelpCenter => {
  const { t } = useTranslation();
  const [tabsIndex, setTabsIndex] = useState({
    activeTabGlobal: 0,
    activeTabApple: 0,
    activeTabAndroid: 0,
    activeTabGlobalPhone: 0,
    activeTabAndroidSamsung: 0,
    activeTabAndroidPixel: 0,
  });

  const {
    activeTabGlobal,
    activeTabApple,
    activeTabAndroid,
    activeTabGlobalPhone,
    activeTabAndroidSamsung,
    activeTabAndroidPixel,
  } = tabsIndex;

  const handleChangeActiveTab = (key: string, index: number) =>
    setTabsIndex({ ...tabsIndex, [key]: index });

  return {
    GUIDE_IOS: [
      {
        index: [
          {
            step: 1,
            title: t(
              "global.login.helpCenter.sectionInstructions.titleFirstCard"
            ),
            alertMessage: t(
              "global.login.helpCenter.sectionInstructions.alertCard.fisrtAlert"
            ),
            instructions: [
              {
                subtitle: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoIos.tabManually.list.titleList"
                ),
                list: [
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoIos.tabManually.list.firstPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoIos.tabManually.list.secondPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoIos.tabManually.list.thirdPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoIos.tabManually.list.fourthPoint"
                  ),
                ],
                idVideo: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoIos.tabManually.IDVideo"
                ),
              },
              {
                subtitle: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoIos.tabCodeQr.list.titleList"
                ),
                list: [
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoIos.tabCodeQr.list.firstPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoIos.tabCodeQr.list.secondPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoIos.tabCodeQr.list.thirdPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoIos.tabCodeQr.list.fourthPoint"
                  ),
                ],
                idVideo: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoIos.tabCodeQr.IDVideo"
                ),
              },
            ],
            tabControl: {
              activeTab: activeTabApple,
              handleTabClick: (index: number) =>
                handleChangeActiveTab("activeTabApple", index),
              tabsData: [
                {
                  id: "manual",
                  title: t(
                    "global.login.helpCenter.sectionInstructions.tabsCard.first"
                  ),
                },
                {
                  id: "qr",
                  title: t(
                    "global.login.helpCenter.sectionInstructions.tabsCard.second"
                  ),
                },
              ],
            },
          },
          {
            step: 2,
            title: t(
              "global.login.helpCenter.sectionInstructions.titleSecondCard"
            ),
            alertMessage: t(
              "global.login.helpCenter.sectionInstructions.alertCard.secondAlert"
            ),
            instructions: [
              {
                subtitle: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoIos.tabManually.activateManually.list.titleList"
                ),
                list: [
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoIos.tabManually.activateManually.list.firstPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoIos.tabManually.activateManually.list.secondPoint"
                  ),
                ],
                idVideo: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoIos.tabManually.activateManually.IDVideo"
                ),
              },
            ],
          },
        ],
      },
      {
        index: [
          {
            step: 1,
            title: "Install your eSIM",
            alertMessage:
              "Install before your trip, you must have internet connection",
            instructions: [
              {
                subtitle:
                  "You will only need to copy and paste the codes you received in your purchase email",
                list: [
                  "Install before your trip",
                  "You must have internet connection",
                  "Use the SM-DP+ Address and Activation Code that you received on your email",
                  "Follow the next steps:",
                ],
                idVideo: "",
              },
              {
                subtitle:
                  "Remember to have QR code in a different device so you can scan it",
                list: [
                  "You must have internet connection",
                  "Install before your trip",
                  "You must have the QR code in a different device so you can scan it",
                  "Follow the next steps:",
                ],
                idVideo: "",
              },
            ],
            tabControl: {
              activeTab: activeTabAndroid,
              handleTabClick: (index: number) =>
                handleChangeActiveTab("activeTabAndroid", index),
              tabsData: [
                { id: "manual", title: "Manually" },
                { id: "qr", title: "QR Code" },
              ],
            },
          },
          {
            step: 2,
            title: "Activate your eSIM",
            alertMessage:
              "Activate only at your destination, data will begin to be consumed",
            instructions: [
              {
                subtitle: "Check your connection",
                list: [
                  "Install before your trip",
                  "You must have internet connection",
                  "Use the SM-DP+ Address and Activation Code that you received on your email",
                  "Follow the next steps:",
                ],
                idVideo: "",
              },
            ],
          },
        ],
      },
    ],
    GUIDE_ANDROID: [
      {
        index: [
          {
            step: 1,
            title: t(
              "global.login.helpCenter.sectionInstructions.titleFirstCard"
            ),
            alertMessage: t(
              "global.login.helpCenter.sectionInstructions.alertCard.fisrtAlert"
            ),
            instructions: [
              {
                subtitle: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabManually.list.titleList"
                ),
                list: [
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabManually.list.firstPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabManually.list.secondPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabManually.list.thirdPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabManually.list.fourthPoint"
                  ),
                ],
                idVideo: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabManually.IDVideo"
                ),
              },
              {
                subtitle: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabCodeQr.list.titleList"
                ),
                list: [
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabCodeQr.list.firstPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabCodeQr.list.secondPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabCodeQr.list.thirdPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabCodeQr.list.fourthPoint"
                  ),
                ],
                idVideo: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabCodeQr.IDVideo"
                ),
              },
            ],
            tabControl: {
              activeTab: activeTabAndroidSamsung,
              handleTabClick: (index: number) =>
                handleChangeActiveTab("activeTabAndroidSamsung", index),
              tabsData: [
                {
                  id: "manual",
                  title: t(
                    "global.login.helpCenter.sectionInstructions.tabsCard.first"
                  ),
                },
                {
                  id: "qr",
                  title: t(
                    "global.login.helpCenter.sectionInstructions.tabsCard.second"
                  ),
                },
              ],
            },
          },
          {
            step: 2,
            title: t(
              "global.login.helpCenter.sectionInstructions.titleSecondCard"
            ),
            alertMessage: t(
              "global.login.helpCenter.sectionInstructions.alertCard.secondAlert"
            ),
            instructions: [
              {
                subtitle: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabCodeQr.activateCodeQr.list.titleList"
                ),
                list: [
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabCodeQr.activateCodeQr.list.firstPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabCodeQr.activateCodeQr.list.secondPoint"
                  ),
                ],
                idVideo: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoSamsung.tabCodeQr.activateCodeQr.IDVideo"
                ),
              },
            ],
          },
        ],
      },
      {
        index: [
          {
            step: 1,
            title: t(
              "global.login.helpCenter.sectionInstructions.titleFirstCard"
            ),
            alertMessage: t(
              "global.login.helpCenter.sectionInstructions.alertCard.fisrtAlert"
            ),
            instructions: [
              {
                subtitle: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabManually.list.titleList"
                ),
                list: [
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabManually.list.firstPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabManually.list.secondPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabManually.list.thirdPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabManually.list.fourthPoint"
                  ),
                ],
                idVideo: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabManually.IDVideo"
                ),
              },
              {
                subtitle: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabCodeQr.list.titleList"
                ),
                list: [
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabCodeQr.list.firstPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabCodeQr.list.secondPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabCodeQr.list.thirdPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabCodeQr.list.fourthPoint"
                  ),
                ],
                idVideo: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabCodeQr.IDVideo"
                ),
              },
            ],
            tabControl: {
              activeTab: activeTabAndroidPixel,
              handleTabClick: (index: number) =>
                handleChangeActiveTab("activeTabAndroidPixel", index),
              tabsData: [
                {
                  id: "manual",
                  title: t(
                    "global.login.helpCenter.sectionInstructions.tabsCard.first"
                  ),
                },
                {
                  id: "qr",
                  title: t(
                    "global.login.helpCenter.sectionInstructions.tabsCard.second"
                  ),
                },
              ],
            },
          },
          {
            step: 2,
            title: t(
              "global.login.helpCenter.sectionInstructions.titleSecondCard"
            ),
            alertMessage: t(
              "global.login.helpCenter.sectionInstructions.alertCard.secondAlert"
            ),
            instructions: [
              {
                subtitle: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabCodeQr.activateCodeQr.list.titleList"
                ),
                list: [
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabCodeQr.activateCodeQr.list.firstPoint"
                  ),
                  t(
                    "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabCodeQr.activateCodeQr.list.secondPoint"
                  ),
                ],
                idVideo: t(
                  "global.login.helpCenter.sectionInstructions.tabInfoAndroid.tabInfoGooglePixel.tabCodeQr.activateCodeQr.IDVideo"
                ),
              },
            ],
          },
        ],
      },
    ],
    activeTabGlobal,
    activeTabGlobalPhone,
    handleChangeActiveTab,
  };
};
