import React, { useState } from "react";
import TabsBar from "../tabsBar/TabsBar";

const TABS_OPTIONS = [
    { active: true, id: "Change_Email", label: "Change Email" },
    { active: false, id: "Status_Esim", label: "Status Esim" },
]

export const LayoutAdminEmail = ({children,setTabId}): JSX.Element => {
  const [TabOptions, setSelectTabOption] =
    useState(TABS_OPTIONS);

  const handleTabChange = (selectedTab) => {
    setTabId(selectedTab.id)
    setSelectTabOption((prevTabs) =>
      prevTabs.map((tab) =>
        tab.id === selectedTab.id
          ? { ...tab, active: true }
          : { ...tab, active: false }
      )
    );
  };

  return (
    <div className="w-full h-screen flex flex-col gap-9 justify-center items-center p-7">
      <TabsBar options={TabOptions} onChange={handleTabChange} />
      {children}
    </div>
  );
};
