import { useState } from "react";
import { postRequestOrder } from "../services";
import { IBodyRequestOrder } from "../interfaces";

export const useRequestOrderInvoce = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  const sendRequestOrder = async (body: IBodyRequestOrder) => {
    resetValue();

    const { message, isError } = await postRequestOrder(body);
    if (isError) {
      setMessage(message);
      setIsLoading(false);
      setHasError(isError);
      return;
    }

    setMessage(message);
    setIsLoading(false);
    setHasSuccess(true)
  };

  const resetValue = () => {
    setMessage("");
    setIsLoading(true);
    setHasError(false);
    setHasSuccess(false)
  };

  return {
    sendRequestOrder,
    isLoading,
    message,
    hasError,
    hasSuccess
  };
};
