import { SVGProps } from "react";
const XTwitter = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}>
    <path
      fill={props.color || "#fff"}
      d="M10.022 7.146 15.98.371h-1.412L9.395 6.254 5.265.37H.5l6.247 8.896L.5 16.37h1.412l5.461-6.212 4.363 6.212H16.5l-6.478-9.225ZM8.09 9.345l-.633-.886L2.42 1.411h2.168l4.064 5.688.633.886 5.283 7.394H12.4L8.089 9.345Z"
    />
  </svg>
);
export default XTwitter;
