import { Dispatch, SetStateAction } from "react";
import { DiscountHistory } from "../discountHistory/DiscountHistory";
import { RedeemHolaPoints } from "../redeemHolaPoints/RedeemHolaPoints";
import { ReferralUser } from "../../../../interfaces";
import BackgroundClouds from "../../../../assets/backgrounds/BackgroundClouds.svg";

type Alert = {
  message: string;
  isError: boolean;
  isLoader: boolean;
};
interface DiscountManageProps {
  referralUser: ReferralUser;
  resetALert: () => void;
  alert: Alert;
  setAlert: Dispatch<SetStateAction<Alert>>;
  handleOpenModal: () => void;
}
export const DiscountManage = ({
  alert,
  resetALert,
  setAlert,
  handleOpenModal,
  referralUser,
}: DiscountManageProps) => {
  return (
    <div
      style={{
         background: `url(${BackgroundClouds})`,
         backgroundRepeat: 'no-repeat',
      }}
      className="mb-14 w-full gap-6 lg:max-w-[1016px] rounded-xl min-h-[544px] 
  mt-[24px] px-4 pt-4 pb-6 lg:p-[30px] flex flex-col lg:flex-row"
    >
      <RedeemHolaPoints
        resetALert={resetALert}
        alert={alert}
        setAlert={setAlert}
        handleOpenModal={handleOpenModal}
      />
      <DiscountHistory referralUser={referralUser} />
    </div>
  );
};
