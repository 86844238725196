import { useState, useEffect } from "react";
import { getOrderByName } from "../services";
import { IOrderByNameInvoice, IUseGetOrderByNameResult } from "../interfaces";
import { INITIAL_RESPONSE_GET_BY_ORDER_NAME } from "../constants";

export const useGetOrderByName = (
  numberOrder: string
): IUseGetOrderByNameResult => {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<IOrderByNameInvoice>(
    INITIAL_RESPONSE_GET_BY_ORDER_NAME
  );
  const [error, setError] = useState<string | null>(null);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const fetchUserOrder = async () => {
    setLoading(true);

    const { data, error } = await getOrderByName(
      {
        numberOrder,
      },
      signal
    );

    setLoading(false);
    setOrder(data);
    setError(error);
  };

  useEffect(() => {
    fetchUserOrder();

    return () => {
      abortController.abort();
    };
  }, []);

  const refreshOrder = () => {
    fetchUserOrder();
  };

  return {
    loading,
    order,
    error,
    refreshOrder,
  };
};
