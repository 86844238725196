import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../../components/dropdowns/Dropdown";
import { HelpCenterHeader } from "./components/HelpCenterHeader";
import { GuideInstallation } from "./components/GuideInstallation";
import { BannerDownloadInstructionsGuide } from "./components/BannerDownloadInstructionsGuide";
import { BannerContact } from "./components/BannerContact";
import { DescItemElement } from "./components/DescItemElement";
import { useGuideHelpCenter } from "../../hooks";
import { frequentDoubts } from "../../constants";

const HelpCenterPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = (): void => navigate(-1);

  const {
    GUIDE_ANDROID,
    GUIDE_IOS,
    activeTabGlobal,
    activeTabGlobalPhone,
    handleChangeActiveTab,
  } = useGuideHelpCenter();

  const getUrlDownloadPdf = (): string => {
    if (activeTabGlobal === 0)
      return t("global.login.helpCenter.sectionDownloadGuide.urlApplePdf");

    if (activeTabGlobalPhone === 0) {
      return t("global.login.helpCenter.sectionDownloadGuide.urlSamsungPdf");
    } else {
      return t(
        "global.login.helpCenter.sectionDownloadGuide.urlGooglePixelPdf"
      );
    }
  };

  const dropdownItems = frequentDoubts.map((doubt) => ({
    question: t(doubt.question),
    response: Array.isArray(doubt.response)
      ? doubt.response.map((item) => t(item))
      : t(doubt.response),
    link: doubt.link,
  }));

  return (
    <Fragment>
      <HelpCenterHeader handleGoBack={handleGoBack} />
      <div data-testid="help-center-page" className="bg-[#F8F8FB]">
        <div className="2xl:w-[60%] 2xl:px-0 relative m-[0_auto] w-full h-full xl:px-56 lg:px-32 md:px-20 px-8 py-20">
          <section>
            <div className="flex items-center justify-center gap-6 flex-col mb-10">
              <h2 className="font-medium text-[22px] md:text-[28px] text-neutral-charcoal text-center">
                {t("global.login.frequentDoubts.titleFrequentDoubts")}
              </h2>
              <h6 className="font-bold text-base text-center">
                {t("global.login.frequentDoubts.descriptionFrequentDoubts")}
              </h6>
            </div>
            <div className="flex flex-col">
              {dropdownItems.map((item, index) => (
                <div key={index}>
                  <Dropdown
                    title={item.question}
                    desc={<DescItemElement item={item} />}
                  />
                </div>
              ))}
            </div>
          </section>
        </div>

        <GuideInstallation
          guideApple={GUIDE_IOS}
          guideAndroid={GUIDE_ANDROID}
          activeTabGlobal={activeTabGlobal}
          activeTabGlobalPhone={activeTabGlobalPhone}
          setActiveTabGlobal={(index) =>
            handleChangeActiveTab("activeTabGlobal", index)
          }
          setActiveTabGlobalPhone={(index) =>
            handleChangeActiveTab("activeTabGlobalPhone", index)
          }
        />

        <BannerDownloadInstructionsGuide urlDownloadPdf={getUrlDownloadPdf()} />

        <BannerContact />
      </div>
    </Fragment>
  );
};

export default HelpCenterPage;
