
import { Route } from "react-router-dom";
import Layout from "../components/layouts/Layout";
import { RouteConfig } from "../interfaces";

export const GenerateRoutes = (
  routeConfigs: RouteConfig[],
  basePath = ""
): JSX.Element[] => {
  return routeConfigs.map(({ path, element, layout, subRoutes }) => (
    <Route
      key={path}
      path={`${basePath}${path}`}
      element={layout ? <Layout>{element}</Layout> : element}
    >
      {subRoutes && GenerateRoutes(subRoutes, `${basePath}${path}/`)}
    </Route>
  ));
};
