import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ICustomerStore, TopUp } from '../interfaces';
import { useGetCustomerCard } from './useGetCustomerCard';
import { useCustomerStore } from '../store/customerStore';
import { getCurrencyData } from '../utils/getCurrencyData';
import { getDetails } from '../services';
import { isProviderActive } from '../utils/validateProviders';

export function useDetails() {
  const [topups, setTopups] = useState<TopUp[]>([]);
  const [loadingTopups, setLoadingTopups] = useState(false);
  const { iccid } = useParams();

  const { data, loading, error } = useGetCustomerCard({
    iccid: iccid || '',
  });

  const { currency } = useCustomerStore((state: ICustomerStore) => state);

  const getTopUps = useCallback(
    async (destination: string) => {
      const currentCurrency = getCurrencyData(currency).currency;
      try {
        setLoadingTopups(true);
        const data = await getDetails({ destination, currentCurrency });

        if (data) return setTopups(data);

        setTopups([]);
      } catch (error) {
        setTopups([]);
      } finally {
        setLoadingTopups(false);
      }
    },
    [currency]
  );

  const handleTopUp = useCallback(() => {
    if (isProviderActive(data.provider_name)) {
      getTopUps(data?.destination?.es);
      return;
    }
  }, [data, getTopUps]);

  useEffect(() => {
    if (!loading) {
      handleTopUp();
    }
  }, [loading, handleTopUp]);

  return { topups, loadingTopups, data, loading, error };
}


