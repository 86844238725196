import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../hooks";
import HButton from "../../../components/common/hbutton/HButton";
import { removeToken } from "../../../utils";
import confirmDelete from "../../../assets/delete/delete-confirmation.svg";

export const DeletedAccountNotification = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const handleClick = () => {
    const prevEmail = localStorage.getItem("email");
    localStorage.clear();
    removeToken();
    localStorage.setItem("email", prevEmail || "");
    navigate("/login");
  };
  return (
    <div
      data-testid="notification-success-delete-account"
      className="flex flex-col justify-center items-center gap-4"
    >
      <img
        src={confirmDelete}
        alt="confirmation-delete"
        draggable={false}
        loading="lazy"
      />

      <h2 className="text-neutral-charcoal font-bold text-xl text-center">
        {t("global.customerV2.deleteAccount.deleteAccountNotification.title")}
      </h2>

      <div className="flex flex-col items-center text-[#292B2E]">
        <p className="text-[16px] font-bold">
          {t(
            "global.customerV2.deleteAccount.deleteAccountNotification.thanksMessage"
          )}
        </p>
        <p className="text-[16px] font-normal">
          {t(
            "global.customerV2.deleteAccount.deleteAccountNotification.partingMessage"
          )}
        </p>
      </div>

      <HButton
        type="button"
        name="button notification delete account"
        onClick={handleClick}
        className="py-3 px-5 w-[200px] flex justify-center items-center h-[48px] hover:from-magenta-bold hover:to-magenta-bold bg-gradient-to-r from-magenta to-magenta-900 text-white rounded-xl"
        text={t(
          "global.customerV2.deleteAccount.deleteAccountNotification.btnLabel"
        )}
      />
    </div>
  );
};
