import { t } from "i18next";
import HButton from "../../../components/common/hbutton/HButton";

import { redirectToWeb } from "../../../utils/redirectLinkEsim";

import { EmptyBox } from "../../../components/svg";

import { IExpiredSectionInfoProps } from "../../../interfaces";

export const ExpiredSectionInfo = ({
  shadowOff,
  title,
  content,
  btnText,
}: IExpiredSectionInfoProps): JSX.Element => {
  return (
    <div
      data-testid="section-expired"
      className={`px-8 md:px-16 pt-10 pb-14 mx-auto w-full h-fit rounded-lg ${
        !shadowOff && "shadow-card"
      } mb-4 flex flex-col items-center col-span-2`}>
      <EmptyBox />

      <h1 className="text-neutral-charcoal font-medium text-[28px] text-center">
        {title ? t(`${title}`) : t("global.details.expired.title")}
      </h1>

      <p className="text-neutral-charcoal font-medium text-lg text-center md:w-[432px] w-full mt-4 mb-7">
        {content ? t(`${content}`) : t("global.details.expired.body")}.
      </p>

      <HButton
        type="button"
        name="button buy a eSIM"
        onClick={redirectToWeb}
        className={
          "py-3 px-5 rounded-xl border-0 bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold text-white min-w-44 w-full h-12 p-2 items-center justify-center"
        }
        text={btnText ? t(`${btnText}`) : t("global.details.expired.cta")}
      />
    </div>
  );
};
