import { useState } from "react";
import { useParams } from "react-router-dom";
import { userProfileAdapter } from "../adapters";
import { useCustomerStore } from "../store/customerStore";
import { getUserProfile, postUpdatedUserProfile } from "../services";
import { getAndDecryptLocalStorageData } from "../utils";
import {
  DataUser,
  ICustomerStore,
  IUseGetCustomerProfile,
} from "../interfaces";
import { initialCustomerProfile } from "../constants";

const STATUS_LOAD_DATA_INITIAL = {
  loading: true,
  isError: false,
  isSuccess: false,
};
export const useGetCustomerProfile = (): IUseGetCustomerProfile => {
  const [statusLoadData, setStatusLoadData] = useState(
    STATUS_LOAD_DATA_INITIAL
  );
  const { loading, isError, isSuccess } = statusLoadData;

  const { setProfile, profile, setEmailLocalStorage, customerId } =
    useCustomerStore((store: ICustomerStore) => store);
  const params = useParams();

  const id = customerId || params.customerId;

  const saveChangesProfile = async (data: DataUser) => {
    setStatusLoadData({ ...statusLoadData, ...STATUS_LOAD_DATA_INITIAL });

    const {
      data: response,
      loading,
      isCompleted,
      isError,
    } = await postUpdatedUserProfile(id || "", data);

    const result = userProfileAdapter(response);
    profile === null ? setProfile(initialCustomerProfile) : setProfile(result);

    setStatusLoadData({
      ...statusLoadData,
      loading,
      isSuccess: isCompleted!,
      isError: isError!,
    });
  };

  const loadProfileData = async () => {
    setStatusLoadData({ ...statusLoadData, ...STATUS_LOAD_DATA_INITIAL });

    const { data, loading } = await getUserProfile(id || "");

    const result = userProfileAdapter(data);

    setProfile(result);

    if (!getAndDecryptLocalStorageData("email")) {
      setEmailLocalStorage(data.emails![0].address || "");
    }
    setStatusLoadData({ ...statusLoadData, loading });
  };

  return { loadProfileData, saveChangesProfile, loading, isError, isSuccess };
};
