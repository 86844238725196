import { IconTextStatus } from "../../iconsText/IconTextStatus";
import { BundleText } from "./BundleText";
import { STATUS } from "../../../constants";
import { IDataAndStatusDestination } from "../../../interfaces";

export const StatusAndBundle = ({
  showStatusDestination,
  card,
}: IDataAndStatusDestination): JSX.Element => (
  <div className="flex flex-row justify-between">
    <BundleText showStatusDestination={showStatusDestination} card={card} />
    <IconTextStatus
      color={STATUS[card.status].circle_color}
      status={card.status}
      initDate={card.init_date || ""}
    />
  </div>
);
