import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/input/Input";
import { LoginCard } from "../../components/loginCard/LoginCard";
import HButton from "../../components/common/hbutton/HButton";
import { logInAdmin } from "../../services/admin/login";
import { removeToken } from "../../utils";

export const LoginAdmin = () => {
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();

  const onLogIn = async () => {
    const { success } = await logInAdmin(email);

    if (success) navigate("/admin/dashboard");
    else alert("Invalid email");
  };

  useEffect(() => {
    removeToken();
    localStorage.clear();
  }, []);

  return (
    <div className="w-full h-screen flex mt-auto flex-col justify-center items-center gap-12">
      <div className="w-11 h-9">
        <img src="./android-chrome-192x192.png" alt="logo" />
      </div>

      <LoginCard>
        <p aria-label="paragraph" className="text-xl font-bold">
          Login Admin
        </p>
        <Input
          name="email"
          handleChange={(e) => {
            setEmail(e.target.value);
          }}
          label="Email"
          type={"email"}
          value={email}
        />

        <HButton
          type="button"
          name="button login admin"
          onClick={onLogIn}
          text="Continue"
          containerStyles="w-full flex justify-center"
          className="py-6 px-8 flex items-center  justify-center  max-w-sm h-12 
          rounded-xl bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold transition-all duration-300  text-white"
        />
      </LoginCard>
    </div>
  );
};
