import { CountryAndStateContent } from "../containers/ContainerCountryAndState";
import { CLASSNAME_CONTAIN_DEFAULT_CSNC } from "../../../constants";
import { ICountryAndStateCard } from "../../../interfaces";

export const CountryAndStateNameCard = ({
  card,
  classNameContain,
  showStatusDestination,
  children,
}: ICountryAndStateCard): JSX.Element => {
  const CLASSNAME_INITIAL = classNameContain || CLASSNAME_CONTAIN_DEFAULT_CSNC;

  const IS_SHOW_DESTINATION = !!showStatusDestination && showStatusDestination;

  return (
    <div
      data-testid="container_country_statusBundle"
      className={CLASSNAME_INITIAL}
    >
      {children || (
        <CountryAndStateContent
          card={card}
          showStatusDestination={IS_SHOW_DESTINATION}
        />
      )}
    </div>
  );
};
