import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { useTimeout, useRequestOrderInvoce } from "../../../../hooks";
import { AlertNotification } from "../../../../components/alert/AlertNotification";
import HButton from "../../../../components/common/hbutton/HButton";
import { FormRequestInvoice } from "./FormRequestInvoice";
import { InvoiceConfirmation } from "./InvoiceConfirmation";
import { useCustomerStore } from "../../../../store/customerStore";
import { dateFormatToDayMonthYear } from "../../../../utils";
import {
  ICustomerStore,
  IInvoiceRequestModalProps,
} from "../../../../interfaces";

export const InvoiceRequestModal = ({
  closeModal,
  refreshOrder,
}: IInvoiceRequestModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [showAlertNotification, resetAlert] = useTimeout(4000);
  const { setHasSeenInvoiceRequest, orderUser } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  const { sendRequestOrder, isLoading, message, hasError, hasSuccess } =
    useRequestOrderInvoce();

  const handleClose = () => {
    refreshOrder();
    closeModal();
    setHasSeenInvoiceRequest(false);
  };

  return (
    <div className="relative bg-white h-full w-full lg:w-[600px]">
      <div className="px-6 py-4 flex flex-row justify-between items-center lg:border-b mt-20 lg:mt-0">
        <h1 className="text-center lg:text-start text-[22px] font-medium text-neutral-charcoal w-full">
          {t("global.customerV2.invoice.requestForm.title")}
        </h1>

        <HButton
          type="button"
          name="close modal invoice request"
          containerStyles="p-2 flex justify-end absolute right-2"
          onClick={handleClose}
          className="lg:border lg:border-neutral-300 hover:bg-neutral-200 rounded-full w-8 h-8 flex 
        justify-center items-center transition-all duration-300"
          icon={<Icon path={mdiClose} size={0.8} />}
        />
      </div>
      {!hasSuccess ? (
        <div className="flex flex-col gap-5 lg:h-[90%] lg:overflow-y-auto p-6">
          {hasError && (
            <AlertNotification
              resetAlert={resetAlert}
              isVisible={showAlertNotification}
              alertType={"error"}
              message={message}
              customClassName={`p-5 rounded-xl z-10  transition-all duration-300 animate-slideDown 
                flex self-center bg-semantic-error-100 w-full`}
            />
          )}

          <h2 className="text-neutral-charcoal font-normal text-base">
            {t("global.customerV2.invoice.requestForm.description")}
          </h2>

          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2 rounded-xl border p-3">
              <h3 className="text-neutral-charcoal font-bold text-base">
                {t("global.customerV2.invoice.requestForm.paymentDetails")}
              </h3>

              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-col gap-[2px]">
                  <h4 className="text-sm font-normal text-neutral-charcoal">
                  {t("global.customerV2.invoice.requestForm.total")}
                  </h4>

                  <p className="text-base font-bold text-neutral-charcoal">
                    {orderUser!.totalPaid}
                  </p>
                </div>

                <div className="flex flex-col gap-[2px]">
                  <h4 className="text-sm font-normal text-neutral-charcoal">
                  {t("global.customerV2.invoice.requestForm.currency")}
                  </h4>

                  <p className="text-base font-bold text-neutral-charcoal">
                    {orderUser!.currencyCode} ({orderUser!.currencySymbol})
                  </p>
                </div>

                <div className="flex flex-col gap-[2px]">
                  <h4 className="text-sm font-normal text-neutral-charcoal">
                  {t("global.customerV2.invoice.requestForm.paymentDate")}
                  </h4>

                  <p className="text-base font-bold text-neutral-charcoal">
                    {dateFormatToDayMonthYear(orderUser!.date)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h2 className="text-base font-bold text-neutral-charcoal">
              {t("global.customerV2.invoice.requestForm.personalInfo.title")}
            </h2>

            <FormRequestInvoice
              btnClose={handleClose}
              sendForm={sendRequestOrder}
              isLoading={isLoading}
            />
          </div>
        </div>
      ) : (
        <InvoiceConfirmation closeModal={handleClose} />
      )}
    </div>
  );
};
