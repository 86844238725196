import Icon from "@mdi/react";
import { mdiWeb } from "@mdi/js";
import { useCustomerStore } from "../../../../store/customerStore";
import { ICustomerStore } from "../../../../interfaces";
import { useTranslation } from "react-i18next";
import { items } from "./items";
import { MenuMobileElement } from "./MenuMobileElement";

export const MobileMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const { language, isOpenMenuMobile, toggleMenuMobile, toggleMenuLanguage } =
    useCustomerStore((state: ICustomerStore) => state);

  return (
    <div
      data-testid="mobile-menu"
      className="absolute ease-in duration-[3000] w-full md:max-w-[395px] p-5 items-center top-[64px] left-0 lg:hidden 
bg-white h-screen z-50 shadow-[0_20px_25px_-5px_rgb(0,0,0,0.3),_0_8px_10px_-6px_rgb(0,0,0,0.6)] overflow-scroll border-t"
    >
      <div className="flex flex-col gap-10">
        <div className="w-full flex justify-end items-center">
          <div
            data-testid="btn_open_select_language_mobile"
            className="transition-all duration-1000 cursor-pointer flex gap-2 items-center justify-between border rounded-lg py-3 px-4 "
            onClick={() => {
              toggleMenuLanguage(true);
              toggleMenuMobile(!isOpenMenuMobile);
            }}
          >
            <Icon color="#96969D" path={mdiWeb} title="Web" size={0.8} />
            <p className="text-sm text-neutral-charcoal font-medium">{`${language.toUpperCase()}`}</p>
          </div>
        </div>

        <ul className="flex flex-col">
          {items.map((item) => (
            <MenuMobileElement
              key={item.id}
              title={t(item.title)}
              path={item.path}
              closeModal={() => toggleMenuMobile(false)}
            />
          ))}
        </ul>

        <a
          href="/login"
          className="border rounded-xl hover:bg-neutral-200 w-full px-5 py-3 flex justify-center items-center"
        >
          <span className="font-medium text-[14px] leading-[14px]">Log in</span>
        </a>
      </div>
    </div>
  );
};
