import { API_URLS } from "../../constants";
import { makeApiCall } from "../apiCall";

export const updateStatusEsim = async (body) => {
  const { URL_UPDATE_STATUS_CARD_ADMIN } = API_URLS;

  const sendData = {
    ...body,
    iccid: [body.iccid],
    status: Number(body.status),
  };

  try {
    const { data, status } = await makeApiCall<any>(
      "PATCH",
      URL_UPDATE_STATUS_CARD_ADMIN,
      sendData
    );

    if (status === 200) {
      return {
        status,
        data,
        error: "",
      };
    }

    return {
      status,
      data: null,
      error: "Bad Request",
    };
  } catch (error) {
    console.error("Error al obtener la información:", error);
    return {
      status: error.response?.status || 500,
      data: null,
      error: error.response?.data?.message || "Server Error",
    };
  }
};
