import { SVGProps } from "react";

const Stars = (props:SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="m13.363 5.833-.824-1.843-1.843-.823 1.843-.843L13.363.5l.823 1.824 1.843.843-1.843.823-.823 1.843Zm0 10.667-.824-1.823-1.843-.844 1.843-.823.824-1.843.823 1.843 1.843.823-1.843.844-.823 1.823Zm-7.55-2.667-1.646-3.666L.5 8.5l3.667-1.667 1.647-3.666L7.48 6.833 11.147 8.5 7.48 10.167l-1.666 3.666Zm0-2.862.785-1.687L8.284 8.5l-1.686-.784-.784-1.687-.785 1.687-1.686.784 1.686.784.785 1.687Z"
    />
  </svg>
);
export default Stars;
