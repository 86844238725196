import { IHistoryMap } from "../interfaces";
import { useAxios } from "./useAxios";
import { useCustomerStore } from "../store/customerStore";
import { ICustomerStore } from "../interfaces";
import { useState, useEffect } from "react";

export interface QueuedBundle {
  lastActiveIdx: number;
  bundles: Bundle[];
}

export interface Bundle {
  id: string;
  variantId: string;
  bundleId: string;
  bundleCountry: BundleCountryClass;
  bundleVariant: BundleCountryClass;
  CustomerCardId: string;
  iccid: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
}

export interface BundleCountryClass {
  id: string;
  pt: string;
  es: string;
  en: string;
  it: string;
  fr: string;
  de: string;
}

export const useGetHistoryItems = (customerCardId: string) => {
  const [data, setData] = useState<QueuedBundle>({
    bundles: [],
    lastActiveIdx: 0,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    getHistoryFromAPI();
  }, [customerCardId]);

  const { apiCall } = useAxios();

  const language = useCustomerStore((state: ICustomerStore) => state.language);

  const basicQuery = {
    includeSoftDelete: "false",
    sort: {
      deletedAt: "ASC",
      createdAt: "ASC",
    },
  };

  const getHistoryFromAPI = async () => {
    try {
      setLoading(true);
      const { data } = await apiCall(
        "POST",
        `${process.env.REACT_APP_API_URL}/api/queuedBundle`,
        {
          queryBy: { customerCardId },
          ...basicQuery,
        }
      );

      setData(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const mapItemsFromJson = (
    json: QueuedBundle,
    activeFinalDate?: string,
    usedDataPercent?: number
  ): IHistoryMap[] => {
    if (json.bundles.length === 0) return [];

    const activeIndex = json.lastActiveIdx;
    let orderBundles: Bundle[] = [...json.bundles];
    let finalDate = "";
    let percentConsumed: number = 100;

    if (json.lastActiveIdx >= 0) {
      const activeBundle = orderBundles.splice(activeIndex, 1);
      orderBundles.unshift(activeBundle[0]);
      finalDate = activeFinalDate?.split("T")[0] || "";
      percentConsumed = Number(usedDataPercent);
    }
    const mapItem: IHistoryMap[] = orderBundles.map((bundle, idx) => {
      let itemStatus: "Current" | "Expired" | "Next" = "Expired";
      const cleanInitialDate = bundle?.deletedAt
        ? bundle?.deletedAt.split("T")[0]
        : "";

      if (!bundle?.deletedAt) {
        itemStatus = "Next";
        percentConsumed = 0;
      } else if (idx === 0) {
        itemStatus = "Current";
      }

      if (itemStatus === "Expired") {
        percentConsumed = 100;
        finalDate = "";
      }

      return {
        initialDate: cleanInitialDate,
        finalDate,
        status: itemStatus,
        planDescription: bundle?.bundleVariant[language] || "",
        percentConsumed,
      };
    });

    return mapItem;
  };

  return { items: data, error, loading, mapItemsFromJson };
};
