import avatar from "../../../assets/account/avatar.svg";
import { IUserProfileProps } from "../../../interfaces";

export const UserProfile = ({ profile }: IUserProfileProps): JSX.Element => {
  return (
    <div
      data-testid="user-profile"
      className="transition-all duration-300 flex flex-col md:flex-row gap-4 items-center">
      <img
        draggable="false"
        className="w-[100px] h-[100px] rounded-[83px]"
        src={avatar}
        alt="Avatar"
      />

      <div className=" flex flex-col gap-[3px] items-center md:items-start">
        <h1 className="font-bold md:font-medium text-xl md:text-[34px] leading-6 md:leading-10">
          {`${profile?.firstName || ""} ${profile?.lastName || ""} `}
        </h1>
        <p className="font-normal text-base leading-[22px]">
          {profile?.email?.length > 0 ? profile.email : ""}
        </p>
      </div>
    </div>
  );
};
