import { useNavigate } from "react-router-dom";
import { useCustomerStore } from "../store/customerStore";
import { ICustomerStore } from "../interfaces";

export const useCustomNavigate = () => {
  const navigate = useNavigate();
  const { isModifiedDataAccount } = useCustomerStore((state: ICustomerStore) => state);

  const handleNavigate = (path: string) => {
    if (isModifiedDataAccount) {
      return false;
    }
    navigate(path);
    return true;
  };

  return handleNavigate;
};
