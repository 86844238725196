import { useState } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";

import { IDropdown } from "../../interfaces";

export const Dropdown = ({ title, desc }: IDropdown): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggleDropdown = (): void => setIsOpen(!isOpen);

  const borderFocus = isOpen ? "border-b-0  rounded-b-none" : "";
  const iconRotate = isOpen ? "rotate-180" : "rotate-0";

  return (
    <div className="mb-2 flex flex-col">
      <div onClick={handleToggleDropdown} data-testid="dropdown">
        <div className={`bg-white border rounded-xl p-5 ${borderFocus} `}>
          <div className="flex flex-row justify-between items-center">
            <p className="font-normal text-sm">{title}</p>
            <button className="outline-none">
              <Icon
                className={`transition ${iconRotate}`}
                path={mdiChevronDown}
                size={1}
              />
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          data-testid="body_dropdown"
          className={`w-full p-5 flex transition-all duration-700 justify-start items-start bg-neutral-200 border-neutral-300 border-t-transparent border rounded-b-xl`}
        >
          <span className="w-full font-normal text-sm text-neutral-charcoal">
            {desc}
          </span>
        </div>
      )}
    </div>
  );
};
