import { t } from "i18next";
import { ITabOptions, ITabsBarProps } from "../../interfaces";

const TabsBar = ({
  onChange,
  options,
  containerStyles,
}: ITabsBarProps): JSX.Element => {
  const activeTab =
    "flex justify-center items-center rounded-[16px] h-[40px] min-w-[163px] py-[12px] px-[20px] border border-[#E4E6EA] font-bold";

  const standardTab =
    " flex justify-center items-center rounded-[16px] h-[40px] min-w-[163px] py-[12px] px-[20px] transition-all duration-100 hover:bg-neutral-300 active:bg-neutral-400 font-medium";

  const handleTabClicked = (tab: ITabOptions, touchedIndex: number) => {
    if (options[touchedIndex].active) return;

    onChange(tab);
  };

  return (
    <div
      data-testid="container-tabs"
      className={
        containerStyles ||
        "min-w-[343px] min-h-[52px] max-h-[52px] bg-[#FFF] rounded-[16px] shadow-card flex justify-center items-center p-[6px]"
      }
    >
      <div className="flex flex-wrap mx-auto md:mx-0 w-fit gap-[4px]">
        {options.map((tab, index) => (
          <button
            key={`${tab.id}-tab-${tab.label}`}
            aria-label={`${index}-tab-${tab.label}`}
            onClick={() => handleTabClicked(tab, index)}
            className={tab.active ? activeTab : standardTab}
          >
            {t(tab.label)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabsBar;
