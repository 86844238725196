import { SVGProps } from "react";

const HelpCenterIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M5.694 20c-.47 0-.87-.165-1.2-.494-.33-.33-.494-.73-.494-1.2V5.694c0-.47.165-.87.494-1.2.33-.33.73-.494 1.2-.494h12.612c.47 0 .87.165 1.2.494.33.33.494.73.494 1.2v12.612c0 .47-.165.87-.494 1.2-.33.33-.73.494-1.2.494H5.694Zm0-1.412h12.612c.063 0 .125-.031.188-.094s.094-.125.094-.188V5.694c0-.063-.031-.125-.094-.188s-.125-.094-.188-.094H5.694c-.063 0-.125.031-.188.094s-.094.125-.094.188v12.612c0 .063.031.125.094.188s.125.094.188.094Zm6.33-1.34a.93.93 0 0 0 .682-.283.93.93 0 0 0 .282-.683.93.93 0 0 0-.282-.682.93.93 0 0 0-.682-.282.93.93 0 0 0-.683.282.93.93 0 0 0-.282.682.93.93 0 0 0 .282.683.93.93 0 0 0 .683.282Zm.682-4c.047-.268.137-.503.27-.707.134-.204.365-.47.695-.8.517-.518.878-.949 1.082-1.294.204-.345.306-.737.306-1.176 0-.769-.27-1.393-.812-1.871-.541-.479-1.227-.718-2.059-.718-.627 0-1.184.134-1.67.4-.487.267-.855.612-1.106 1.036a.634.634 0 0 0-.082.54.533.533 0 0 0 .34.377.577.577 0 0 0 .495.013.833.833 0 0 0 .376-.342c.157-.235.373-.424.648-.565a2 2 0 0 1 .929-.212c.517 0 .913.134 1.188.4.275.267.412.596.412.989 0 .298-.087.596-.26.894-.172.298-.43.596-.776.894-.408.376-.717.745-.929 1.106-.212.36-.325.706-.341 1.035 0 .157.063.298.188.424a.61.61 0 0 0 .447.188.592.592 0 0 0 .436-.177.802.802 0 0 0 .223-.435Z"
    />
  </svg>
);
export default HelpCenterIcon;
