import React from "react";

export const ContainerCardInvoice = ({ children }): JSX.Element => {
  return (
    <div
      data-testid="container-card-invoice"
      className="self-stretch flex-[1_0_0] rounded-xl flex flex-col bg-neutral-200 p-[30px] w-full md:w-[496px]">
      {children}
    </div>
  );
};
