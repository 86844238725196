import React from "react";

import { PurchaseInfoDropdown } from "./PurchaseInfoDropdown";
import { AboutEsimsDropdown } from "./AboutEsimsDropdown";
import { ContentSectionGeneral } from "../../../components/content/ContentSectionGeneral";

export interface IInfoQnAProps {
  order: string;
  iccid: string;
}

export const InfoQnA = ({ order, iccid }: IInfoQnAProps): JSX.Element => {
  return (
    <ContentSectionGeneral>
      <PurchaseInfoDropdown order={order} iccid={iccid} />

      {/* <AboutEsimsDropdown /> */}
    </ContentSectionGeneral>
  );
};
