import React from "react";
import HolaflyLogo from "../../../holafly_logo.svg";

export const ContainerCard = ({ children,title }): JSX.Element => {
  return (
    <div className="shadow-card w-full md:w-[60%] lg:w-[400px] h-auto bg-red rounded-2xl p-7">
      <img
        className="mt-0 mb-4 mx-auto w-[45%]"
        src={HolaflyLogo}
        draggable={false}
        alt="logo"
      />
      <div className="flex flex-col gap-2">
        <h1 className="mb-3">{title}</h1>
        {children}
      </div>
    </div>
  );
};
