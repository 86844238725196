import { makeApiCall } from "../apiCall";
import { esimAdapter } from "../../adapters";
import { IGetAllByCustomerId } from "../../interfaces";
import { API_URLS } from "../../constants";

export const getAllByCustomerId = async (
  {
    customerId,
    page,
    perPage,
    isIncludeProvider,
    status: statusCard,
  }: IGetAllByCustomerId,
  signal?: AbortSignal
) => {
  const { URL_GET_ID_CUSTOMER } = API_URLS;

  try {
    const { status, data } = await makeApiCall<any>(
      "GET",
      URL_GET_ID_CUSTOMER(
        customerId,
        page,
        perPage,
        isIncludeProvider,
        statusCard
      ),
      null,
      signal
    );

    if (status === 200) {
      return {
        status,
        data: { ...data, cards: data.cards.map(esimAdapter) },
        error: null,
      };
    }

    return {
      status,
      data: {
        count: 0,
        pages_count: 0,
        cards: [],
      },
      error: "Bad Request",
    };
  } catch (error) {
    console.error("Error al obtener la información:", error);
    return {
      status: 500,
      data: {
        count: 0,
        pages_count: 0,
        cards: [],
      },
      error,
    };
  }
};
