export const getLocalStorage = <T>(
  key: string,
  type: "String" | "Other"
): T | null => {
  if (!localStorage.getItem(key)) {
    return null;
  }

  if (type === "String") {
    return localStorage.getItem(key) as T;
  }

  return JSON.parse(localStorage.getItem(key)!);
};

export const saveLocalStorage = <T>(
  key: string,
  data: T,
  type: "String" | "Other"
) => {
  return type === "String"
    ? localStorage.setItem(key, data as string)
    : localStorage.setItem(key, JSON.stringify(data));
};

export const getSessionStorage = (key: string) => {
  if (!sessionStorage.getItem(key)) return null;

  return sessionStorage.getItem(key);
};

export const saveSessionStorage = (key: string, data: any) => {
  sessionStorage.setItem(key, String(data));
};
