import * as React from "react";
import { SVGProps } from "react";

const CallPhoneIn = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M12.934 12.752a.705.705 0 0 0 .364.364c.08.036.17.05.269.04l3.758.014a.56.56 0 0 0 .41-.169.498.498 0 0 0 .155-.397.548.548 0 0 0-.161-.404.548.548 0 0 0-.404-.161l-2.492-.014 3.65-3.65a.557.557 0 0 0 .176-.404.557.557 0 0 0-.176-.404.557.557 0 0 0-.404-.176.557.557 0 0 0-.404.176l-3.65 3.65-.014-2.492a.493.493 0 0 0-.155-.41.583.583 0 0 0-.41-.155.499.499 0 0 0-.397.154.56.56 0 0 0-.169.411l.013 3.758a.53.53 0 0 0 .041.27Z"
    />
    <path
      fill="#292B2E"
      d="M7.87 19.906c-.381 0-.705-.133-.972-.4-.267-.266-.4-.59-.4-.971V5.278c0-.381.133-.705.4-.972.267-.266.59-.4.971-.4h5.143V5.05H7.87a.22.22 0 0 0-.161.066.221.221 0 0 0-.067.163V6h5.371v1.143H7.641v9.524h7.619v-1.524h1.143v3.39c0 .382-.133.706-.4.972-.267.267-.59.4-.972.4H7.87Zm0-1.143h7.161a.222.222 0 0 0 .163-.066.222.222 0 0 0 .066-.162v-.724H7.64v.724c0 .063.023.117.068.162a.22.22 0 0 0 .161.066Z"
    />
  </svg>
);
export default CallPhoneIn;
