import {
  CustomerProfile,
  GalleryElements,
  IOptionsTabSwitchTravelClub,
  IUserProfile,
  ThreeOreMoreArray,
} from "../interfaces";

import Learn from "../assets/main/LearnGallery.svg";
import Activate from "../assets/main/newLearn.svg";
import Discover from "../assets/main/newDiscover.svg";
import StepOne from "../assets/referrals/step_one.svg";
import StepTwo from "../assets/referrals/step_two.svg";
import StepThree from "../assets/referrals/step_three.svg";
import StepOneMobile from "../assets/referrals/step_one_mobile.svg";
import StepTwoMobile from "../assets/referrals/step_two_mobile.svg";
import StepThreeMobile from "../assets/referrals/step_three_mobile.svg";
import {
  CashbackSymbol,
  EsimPhone,
  HolaCoinIcon,
  Stars,
} from "../components/svg";

/**
 * Frequent doubts data
 */
export const frequentDoubts = [
  {
    question: "global.login.frequentDoubts.question_one",
    response: "global.login.frequentDoubts.response_one",
  },
  {
    question: "global.login.frequentDoubts.question_two",
    response: "global.login.frequentDoubts.response_two",
  },
  {
    question: "global.login.frequentDoubts.question_three",
    response: [
      "global.login.frequentDoubts.response_three.paragraph_1",
      "global.login.frequentDoubts.response_three.paragraph_2",
      "global.login.frequentDoubts.response_three.paragraph_3",
      "global.login.frequentDoubts.response_three.paragraph_4",
      "global.login.frequentDoubts.response_three.paragraph_5",
      "global.login.frequentDoubts.response_three.paragraph_6",
      "global.login.frequentDoubts.response_three.paragraph_7",
    ],
  },
  {
    question: "global.login.frequentDoubts.question_four",
    response: "global.login.frequentDoubts.response_four",
  },
  {
    question: "global.login.frequentDoubts.question_five",
    response: "global.login.frequentDoubts.response_five",
    link: "global.linkHolaFly",
  },
  {
    question: "global.login.frequentDoubts.question_six",
    response: "global.login.frequentDoubts.response_six.paragraph",
    link: "global.login.frequentDoubts.response_six.url",
  },
  {
    question: "global.login.frequentDoubts.question_seven",
    response: [
      "global.login.frequentDoubts.response_seven.paragraph_1",
      "global.login.frequentDoubts.response_seven.paragraph_2",
    ],
  },
  {
    question: "global.login.frequentDoubts.question_eight",
    response: "global.login.frequentDoubts.response_eight",
  },
  {
    question: "global.login.frequentDoubts.question_nine",
    response: "global.login.frequentDoubts.response_nine",
  },
  {
    question: "global.login.frequentDoubts.question_ten",
    response: "global.login.frequentDoubts.response_ten",
  },
  {
    question: "global.login.frequentDoubts.question_eleven",
    response: [
      "global.login.frequentDoubts.response_eleven.paragraph_1",
      "global.login.frequentDoubts.response_eleven.paragraph_2",
    ],
  },
  {
    question: "global.login.frequentDoubts.question_twelve",
    response: "global.login.frequentDoubts.response_twelve",
  },
  {
    question: "global.login.frequentDoubts.question_thriteen",
    response: "global.login.frequentDoubts.response_thriteen",
  },
  {
    question: "global.login.frequentDoubts.question_fourteen",
    response: [
      "global.login.frequentDoubts.response_fourteen.paragraph_1",
      "global.login.frequentDoubts.response_fourteen.paragraph_2",
      "global.login.frequentDoubts.response_fourteen.paragraph_3",
      "global.login.frequentDoubts.response_fourteen.paragraph_4",
      "global.login.frequentDoubts.response_fourteen.paragraph_5",
    ],
  },
  {
    question: "global.login.frequentDoubts.question_fifteen",
    response: [
      "global.login.frequentDoubts.response_fifteen.paragraph_1",
      "global.login.frequentDoubts.response_fifteen.paragraph_2",
    ],
  },
  {
    question: "global.login.frequentDoubts.question_sixteen",
    response: [
      "global.login.frequentDoubts.response_sixteen.paragraph_1",
      "global.login.frequentDoubts.response_sixteen.paragraph_2",
    ],
  },
  {
    question: "global.login.frequentDoubts.question_seventeen",
    response: [
      "global.login.frequentDoubts.response_seventeen.paragraph_1",
      "global.login.frequentDoubts.response_seventeen.paragraph_2",
    ],
  },
];

/**
 * Initial customer profile state
 */
export const initialCustomerProfile: CustomerProfile = {
  firstName: "",
  lastName: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    region: "",
    postalCode: "",
    country: "",
  },
  email: "",
  phone: "",
  languaje: "",
  currency: "",
  emailNotifications: 0,
  id: "",
  CustomerId: "",
};

export const initialUserProfile: IUserProfile = {
  name: {
    firstName: "",
    lastName: "",
  },
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    region: "",
    postalCode: "",
    country: "",
  },
  emails: [{ address: "", name: "" }],
  phone: "",
  languaje: "",
  currency: "",
  emailNotifications: 0,
  id: "",
  CustomerId: "",
};

/**
 * Initial profile state
 */
export const profileInitial = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

/**
 * About eSIMs information
 */
export const aboutEsims = [
  {
    title:
      "global.customerV2.details.generalInformation.dropdowm.second.list.Installation.title",
    description:
      "global.customerV2.details.generalInformation.dropdowm.second.list.Installation.description",
  },
  {
    title:
      "global.customerV2.details.generalInformation.dropdowm.second.list.Activation.title",
    description:
      "global.customerV2.details.generalInformation.dropdowm.second.list.Activation.description",
  },
  {
    title:
      "global.customerV2.details.generalInformation.dropdowm.second.list.TopUp.title",
    description:
      "global.customerV2.details.generalInformation.dropdowm.second.list.TopUp.description",
  },
  {
    title:
      "global.customerV2.details.generalInformation.dropdowm.second.list.PlanExpiration.title",
    description:
      "global.customerV2.details.generalInformation.dropdowm.second.list.PlanExpiration.description",
  },
  {
    title:
      "global.customerV2.details.generalInformation.dropdowm.second.list.Refunds.title",
    description:
      "global.customerV2.details.generalInformation.dropdowm.second.list.Refunds.description",
  },
  {
    title:
      "global.customerV2.details.generalInformation.dropdowm.second.list.Hotspot.title",
    description:
      "global.customerV2.details.generalInformation.dropdowm.second.list.Hotspot.description",
  },
  {
    title:
      "global.customerV2.details.generalInformation.dropdowm.second.list.InternationalDataPolicy.title",
    description:
      "global.customerV2.details.generalInformation.dropdowm.second.list.InternationalDataPolicy.description",
  },
];

/**
 * Information about adding more data
 */
export const aboutAddMoreData = [
  {
    title: "General",
    content: [
      "High speed and stable connection in the Democratic Republic of Congo.",
      "Receive your QR code and surf instantly. ",
      "Receive your QR code and surf instantly.",
      "Easy to set up and activate.",
      "Connect to the best network in the Democratic Republic of Congo.",
      "Forget about roaming or searching for public WIFI networks.",
    ],
    isList: true,
  },
  {
    title: "Description",
    content: [
      "Connect to the Internet in the Democratic Republic of Congo with a prepaid eSIM with data. Keep in touch with your family and friends through your favorite apps like WhatsApp, iMessage, or Skype. Forget about roaming and start saving on your trip.",
      "It’s easy to activate and use. After your purchase, we will send you a QR code to your email. Scan it to install the eSIM for the Democratic Republic of Congo and start using the service. No paperwork or waiting.",
    ],
    isList: false,
  },
  {
    title: "Technical specs",
    content: [
      "Speed: 3G. ",
      "Networks: Airtel. ",
      "Tethering / Hospot: Yes. ",
      "Data packages: 6 GB. ",
      "Days of use: 15. ",
      "Phone number: No. ",
      "Plan type: Prepaid. ",
      "SMS: No. ",
      "APN: CMHK. ",
      "Analog calls: No, only through app (VOIP). ",
      "Activation: Automatic, activated when connected to a cellular network. ",
      "Compatibility: all smartphones with eSIM technology enabled. Operation on smartwaches and tablets is not guarenteed.",
      "Shipping: via email. ",
      "Delivery time: immediate, after purchase. ",
      "Installation: Scan a QR code .",
      "ID required: No",
      "Technology: eSIM",
      "Coverage: You will enjoy good coverage in Kinshasa, Lubumbashi, Kananga, and other cities and tourist destinations in the Democratic Republic of Congo.",
      "Designed for: tourism, backpackers, vacations, digital nomads, and business. ",
    ],
    isList: true,
  },
];

/**
 * Information about calls
 */
export const aboutCalls = [
  {
    title: "How it works",
    description:
      "Adipisicing nulla ut dolore cupidatat incididunt. Incididunt qui mollit consequat nisi ullamco ut aliquip reprehenderit excepteur. Sunt non commodo proident dolore.",
  },
  {
    title: "How much time do I have",
    description:
      "Adipisicing nulla ut dolore cupidatat incididunt. Incididunt qui mollit consequat nisi ullamco ut aliquip reprehenderit excepteur. Sunt non commodo proident dolore.",
  },
  {
    title: "Which countries can I call?",
    description:
      "Adipisicing nulla ut dolore cupidatat incididunt. Incididunt qui mollit consequat nisi ullamco ut aliquip reprehenderit excepteur. Sunt non commodo proident dolore.",
  },
  {
    title: "Where is my number from?",
    description:
      "Adipisicing nulla ut dolore cupidatat incididunt. Incididunt qui mollit consequat nisi ullamco ut aliquip reprehenderit excepteur. Sunt non commodo proident dolore.",
  },
  {
    title: "How do I know that I have the active line?",
    description:
      "Adipisicing nulla ut dolore cupidatat incididunt. Incididunt qui mollit consequat nisi ullamco ut aliquip reprehenderit excepteur. Sunt non commodo proident dolore.",
  },
  {
    title: "Are the calls cumulative?",
    description:
      "Adipisicing nulla ut dolore cupidatat incididunt. Incididunt qui mollit consequat nisi ullamco ut aliquip reprehenderit excepteur. Sunt non commodo proident dolore.",
  },
  {
    title: "Receive and make calls. charges",
    description:
      "Adipisicing nulla ut dolore cupidatat incididunt. Incididunt qui mollit consequat nisi ullamco ut aliquip reprehenderit excepteur. Sunt non commodo proident dolore.",
  },
];

/**
 * Colors used for different call statuses
 */
export const COLOR_STATUS = {
  Current: "#EBF3FF",
  Expired: "#E4E6EA",
  Next: "#FFF8EC",
};

/**
 * Gallery elements for the main section
 */
export const galleryElements: ThreeOreMoreArray<GalleryElements> = [
  {
    id: 1,
    img: Activate,
    text: "global.customerV2.home.exploreMore.banner.referrals",
    link: `${process.env.REACT_APP_URL}/referralBoard`,
  },
  {
    id: 2,
    img: Discover,
    text: "global.customerV2.home.exploreMore.banner.cashback",
    link: `${process.env.REACT_APP_URL}/referralBoard?tab=cashback`,
  },
  {
    id: 3,
    img: Learn,
    text: "global.customerV2.banners.bannerHome.cardsBanner.cardOne.text",
    link: `${process.env.REACT_APP_URL}/helpCenter?scrollId=#section_instructions`,
  },
];

/**
 * Footer options list
 */
export const LIST_OPTS_FOOTER = {
  LEGAL: [
    {
      url: "global.footer.privacyPolicyLink",
      text: "global.footer.privacyPolicy",
    },
    {
      url: "global.footer.termsAndConditionsLink",
      text: "global.footer.termsAndConditions",
    },
    {
      url: "global.footer.refundPolicyLink",
      text: "global.footer.refundPolicy",
    },
  ],
  HOLAFLY: [
    {
      url: "global.footer.contactUsLink",
      text: "global.footer.contactUs",
    },
    {
      url: "global.footer.holaflyTeamLink",
      text: "global.footer.holaflyTeam",
    },

    {
      url: "global.footer.reviewsLink",
      text: "global.footer.reviews",
    },
  ],
  HELP: [
    {
      url: "global.footer.blogLink",
      text: "global.footer.blog",
    },
    {
      url: "global.footer.esimCompatibleDevicesLink",
      text: "global.footer.esimCompatibleDevices",
    },
    {
      url: "global.footer.howToSetupYourEsimLink",
      text: "global.footer.howToSetupYourEsim",
    },
    {
      url: "global.footer.faqsLink",
      text: "global.footer.faqs",
    },
  ],
};

/**
 * Order options list
 */
export const ORDEN_OPTIONS = [
  {
    value: "global.customerV2.invoice.main.dropdown.optOne",
    id: "DESC",
  },
  {
    value: "global.customerV2.invoice.main.dropdown.optTwo",
    id: "ASC",
  },
];

/**
 * Per page options list
 */
export const PER_PAGE_OPTIONS = [
  {
    value: "9",
    id: 1,
  },
  {
    value: "12",
    id: 2,
  },
  {
    value: "15",
    id: 3,
  },
  {
    value: "18",
    id: 4,
  },
];

/**
 * Initial response for getting order by name
 */
export const INITIAL_RESPONSE_GET_BY_ORDER_NAME = {
  id: 0,
  orderNumber: "",
  date: "0000/00/00",
  customerFirstName: "",
  customerLastName: "",
  customerEmail: "",
  customerPhone: "",
  currencyCode: "",
  currencySymbol: "",
  subTotal: 0,
  totalDiscount: 0,
  totalTax: 0,
  totalPaid: 0,
  lineItems: null,
  billingAddress: {
    address1: "",
    address2: null,
    country: "",
    province: null,
    city: "",
    zip: "",
  },
  invoiceExists: false,
  paymentMethod: null,
  currencyCodeShopMoney: "",
  totalPaidShopMoney: 0,
};

/**
 * Initial response by customer card
 */
export const INITIAL_RESPONSE_BY_CUSTOMER_CARD = {
  __typename: "",
  activable: 0,
  activation_date: "",
  activationEmailSentDate: null,
  boundle: {
    __typename: "",
    id: "",
    de: "",
    en: "",
    es: "",
    fr: "",
    it: "",
    ja: "",
    pt: "",
  },
  consumedCalls: [],
  consumedData: null,
  customer_id: "",
  deactivation_date: "",
  destination: {
    __typename: "",
    id: "",
    de: "",
    en: "",
    es: "",
    fr: "",
    it: "",
    ja: "",
    pt: "",
  },
  esim: 0,
  iccid: "",
  id: "",
  imsi: null,
  msisdn: null,
  order_id: 0,
  order_name: "",
  provider_name: "",
  provider_id: null,
  apiUserId: null,
  status: "",
  variant_id: "",
  createdAt: "",
  updatedAt: "",
  deletedAt: null,
};

/**
 * Steps for onboarding in the travel club
 */
export const STEPS_ONBOARDING_TRAVEL_CLUB = [
  {
    id: 1,
    img: StepOne,
    imgMobile: StepOneMobile,
    title: "global.customerV2.travelClub.onboarding.step1.title",
    description: "global.customerV2.travelClub.onboarding.step1.description",
  },
  {
    id: 2,
    img: StepTwo,
    imgMobile: StepTwoMobile,
    title: "global.customerV2.travelClub.onboarding.step2.title",
    description: "global.customerV2.travelClub.onboarding.step2.description",
  },
  {
    id: 3,
    img: StepThree,
    imgMobile: StepThreeMobile,
    title: "global.customerV2.travelClub.onboarding.step3.title",
    description: "global.customerV2.travelClub.onboarding.step3.description",
  },
];

/**
 * Initial months value to show on history travel club
 */
export const INITIAL_MONTHS = 2;

/**
 * Initial state for the InstallCode component
 */
export const INITIAL_STATE_INSTALLCODE = {
  SMDP: "",
  activationCode: "",
  universalLink: "",
  loader: true,
};

/**
 * Providers
 * eAIS
 * OFR: Orange
 */
export const INACTIVE_PROVIDERS = Object.freeze(["eAIS", "OFR"] as const);

/**
 * Options for switching tabs in the Travel Club section.
 * Each option includes a label for the tab and an associated icon.
 */
export const tabSwitchTravelClubOptions: IOptionsTabSwitchTravelClub[] = [
  {
    label: "global.customerV2.travelClub.tab.benefits",
    icon: Stars,
  },
  {
    label: "global.customerV2.travelClub.tab.earnHolacoins",
    icon: HolaCoinIcon,
  },
];

export const TAGS_LANDING_HERO = [
  {
    icon: CashbackSymbol,
    title: "global.customerV2.travelClub.landing.cashback",
    classBackground: "bg-blue-100",
  },
  {
    icon: HolaCoinIcon,
    title: "global.customerV2.holaCoins",
    classBackground: "bg-green-100",
  },
  {
    icon: EsimPhone,
    title: "global.customerV2.travelClub.landing.freeESIMs.title",
    classBackground: "bg-orange-100",
  },
];

export const FAQs_LANDING = [
  {
    id: 1,
    question: "global.customerV2.travelClub.landing.faqs.question1",
    response: "global.customerV2.travelClub.landing.faqs.answer1",
  },
  {
    id: 2,
    question: "global.customerV2.travelClub.landing.faqs.question2",
    response: "global.customerV2.travelClub.landing.faqs.answer2",
  },
  {
    id: 3,
    question: "global.customerV2.travelClub.landing.faqs.question3",
    response: "global.customerV2.travelClub.landing.faqs.answer3",
  },
  {
    id: 4,
    question: "global.customerV2.travelClub.landing.faqs.question4",
    response: "global.customerV2.travelClub.landing.faqs.answer4",
  },
  {
    id: 5,
    question: "global.customerV2.travelClub.landing.faqs.question5",
    response: "global.customerV2.travelClub.landing.faqs.answer5",
  },
  {
    id: 6,
    question: "global.customerV2.travelClub.landing.faqs.question6",
    response: "global.customerV2.travelClub.landing.faqs.answer6",
  },
  {
    id: 7,
    question: "global.customerV2.travelClub.landing.faqs.question7",
    response: "global.customerV2.travelClub.landing.faqs.answer7",
  },
  {
    id: 8,
    question: "global.customerV2.travelClub.landing.faqs.question8",
    response: "global.customerV2.travelClub.landing.faqs.answer9",
  },
];

/**
 * Footer options on the loyalty landing page
 */
export const LIST_FOOTER_LOYALTY_LANDING = Object.freeze([
  {
    title: "global.customerV2.travelClub.landing.footer.topDestinations.title",
    subItems: [
      {
        text: "global.customerV2.travelClub.landing.footer.topDestinations.option1",
        url: "global.link.topDestinations.USA",
      },
      {
        text: "global.customerV2.travelClub.landing.footer.topDestinations.option2",
        url: "global.link.topDestinations.Mexico",
      },
      {
        text: "global.customerV2.travelClub.landing.footer.topDestinations.option3",
        url: "global.link.topDestinations.Europe",
      },
      {
        text: "global.customerV2.travelClub.landing.footer.topDestinations.option4",
        url: "global.link.topDestinations.Thailand",
      },
    ],
  },
  {
    title: "global.customerV2.travelClub.landing.footer.legal.title",
    subItems: [
      {
        url: "global.footer.termsAndConditionsLink",
        text: "global.customerV2.travelClub.landing.footer.legal.terms",
      },
      {
        url: "global.footer.privacyPolicyLink",
        text: "global.customerV2.travelClub.landing.footer.legal.privacy",
      },
      {
        url: "global.footer.refundPolicyLink",
        text: "global.customerV2.travelClub.landing.footer.legal.refund",
      },
    ],
  },
  {
    title: "global.customerV2.travelClub.landing.footer.interest.title",
    subItems: [
      {
        url: "global.link.footer.HolaflyTravelClubBlog",
        text: "global.customerV2.travelClub.landing.footer.interest.travelClub",
      },
      {
        url: "global.link.footer.whatIsESIM",
        text: "global.customerV2.travelClub.landing.footer.interest.whatIsESIM",
      },
      {
        url: "global.link.footer.compatibleDevices",
        text: "global.customerV2.travelClub.landing.footer.interest.compatibleDevices",
      },
      {
        url: "global.link.footer.howToInstall",
        text: "global.customerV2.travelClub.landing.footer.interest.howToInstall",
      },
      {
        url: "global.link.footer.FAQs",
        text: "global.customerV2.travelClub.landing.footer.interest.FAQs",
      },
    ],
  },
]);

/** Error messages on VerificationCode of login */
export const VERIFICATION_CODE_ERRORS = {
  MORE_ATTEMPTS: "global.login.errors.moreAttemps",
  INTERNAL_ERROR: "global.login.errors.internalError",
  RESEND_WARNING: "global.customerV2.new-login.resendCode.Warning_1",
  RESEND_SECOND_WARNING: "global.customerV2.new-login.resendCode.Warning_2",
};

export const VERIFICATION_CODE_TIMER = 180;

/** Translation keys for redeemed history items */
export const HISTORY_REDEEMED_KEYS = {
  "redeemed coupon": "global.customerV2.travelClub.history.item.redeemedCoupon",
};
