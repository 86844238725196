import React from "react";
import { Alert } from "../../../components/alert/Alert";
import Icon from "@mdi/react";
import { mdiAlertOutline } from "@mdi/js";

export const AlertNotification = ({message}) => {
  return (
    <Alert
      type="custom"
      customStyle="flex items-center px-5 py-4 mb-5 mt-5 w-full border rounded-lg bg-semantic-100 border-none"
    >
      <div className="flex w-full gap-3 items-center">
        <Icon
          path={mdiAlertOutline}
          className="text-semantic-600 w-12 lg:w-6"
        />
        <p className="text-[12px] text-semantic-600">{message}</p>
      </div>
    </Alert>
  );
};
