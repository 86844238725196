import Icon from "@mdi/react";
import { IOptions, InstallOptions } from "./InstallOptions";
import { mdiCreation } from "@mdi/js";
import { useState } from "react";
import { DeviceType } from "../../PendingLite";
import { IOrderEsim } from "../../../../interfaces";
import HButton from "../../../../components/common/hbutton/HButton";
import { useNavigate } from "react-router-dom";
import { InfoQnA } from "../InfoQnA";
import { AutomaticOption, ManualOption, QrOption } from "./tabsOptions";
import { UAParser } from "ua-parser-js";
import { t } from "i18next";

interface PendingMobileProps {
  mobileDevice: DeviceType;
  cardDetails: IOrderEsim;
  defaultOpion: OptionType;
}

export type OptionType = "automatic" | "qr" | "manual";

export const PendingMobile = ({
  mobileDevice,
  cardDetails,
  defaultOpion,
}: PendingMobileProps) => {
  const [currentTabOption, setCurrentTabOption] =
    useState<OptionType>(defaultOpion);
  const handleChangeTabOption = (option: OptionType) => {
    setCurrentTabOption(option);
  };
  const navigate = useNavigate();
  const hanldeNavigateHelpCenter = () =>
    navigate("/helpCenter", {
      state: { prevRoute: "details", data: cardDetails, id: cardDetails.id },
    });

  const tabOptions: IOptions[] = [
    {
      option: "automatic",
      label: t("global.OneButInst_Automatic_Title"),
      icon: <Icon path={mdiCreation} size={1} />,
    },
    {
      option: "qr",
      label: t("global.login.helpCenter.sectionInstructions.tabsCard.second"),
    },
    {
      option: "manual",
      label: t("global.InstallOption_manualCodes"),
    },
  ];

  const tabOptionsAndroid: IOptions[] = [
    {
      option: "qr",
      label: t("global.login.helpCenter.sectionInstructions.tabsCard.second"),
    },
    {
      option: "manual",
      label: t("global.InstallOption_manualCodes"),
    },
  ];

  const renderCases = () => {
    switch (currentTabOption) {
      case "automatic":
        return <AutomaticOption />;
      case "qr":
        return (
          <QrOption
            cardDetails={cardDetails}
            mobileDevice={mobileDevice}
            hanldeNavigateHelpCenter={hanldeNavigateHelpCenter}
          />
        );
      case "manual":
        return (
          <ManualOption
            cardDetails={cardDetails}
            hanldeNavigateHelpCenter={hanldeNavigateHelpCenter}
          />
        );

      default:
        <></>;
    }
  };

  const deviceDetails = new UAParser();

  return (
    <div className="flex flex-col gap-5">
      <h1 className="text-[20px] font-medium text-center">
        {t("global.OneButInst_ChooseMethod_Title")}
      </h1>
      <InstallOptions
        options={mobileDevice === "Android" ? tabOptionsAndroid : tabOptions}
        selectedOption={currentTabOption}
        handleChangeOption={handleChangeTabOption}
      />
      {renderCases()}
      <InfoQnA order={cardDetails.order_name} iccid={cardDetails.iccid} />
      {currentTabOption === "automatic" &&
        Number(deviceDetails.getOS().version) >= 17.4 && (
          <HButton
            type="button"
            name="button install and activate"
            containerStyles="w-full mx-auto hover:bg-red-300 rounded-xl  bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold transition-all duration-300"
            text={t("global.customerV2.home.buttons.buttonInstallAndActivate")}
            className="h-12 flex items-center justify-center px-5 py-3 text-white gap-2 border-none font-normal text-base"
            iconColor="white"
            onClick={() =>
              navigate(
                `/details/step-instructions/${
                  cardDetails.consumedCalls &&
                  cardDetails?.consumedCalls.length > 0
                    ? "call"
                    : "no-call"
                }/${cardDetails.iccid}`
              )
            }
          />
        )}
    </div>
  );
};
