import Icon from "@mdi/react";
import { t } from "i18next";
import { IHeaderTitleIcon } from "../../../interfaces/pages/details/components/headerTitleIconInterface";

const classNameContainDefault =
  "flex justify-between gap-4 items-center text-[22px] font-bold";

export const HeaderTitleIcon = ({
  title,
  icon,
  iconSize = 1,
  isTranslate = false,
  classNameContain = classNameContainDefault,
  onClick = () => {},
}: IHeaderTitleIcon): JSX.Element => {
  return (
    <div data-testid="header-titleWithIcon" className={classNameContain}>
      <button onClick={onClick}>
        <Icon data-testid="icon-button" path={icon} size={iconSize} />
      </button>

      <h2>{isTranslate ? t(title) : title}</h2>
    </div>
  );
};
