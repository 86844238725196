import { useState } from "react";
import { useLocation } from "react-router-dom";
import { LayoutPageWithMapBackground } from "../../components/layouts/LayoutPageWithMapBackground";
import flying from "../../assets/referrals/Flying.svg";
import cloud from "../../assets/details/cloud.png";
import { ChipGroup } from "../../components/common/chips/ChipGroup";
import { Dropdown } from "../../components/dropdowns/Dropdown";
import { t } from "i18next";
import { Trans } from "react-i18next";

export const AboutTravelClubPage = (): JSX.Element => {
  const location = useLocation();

  const [selectedChip, setSelectedChip] = useState(
    location.state?.refIndex || 0
  );

  const refGroupChip = [
    "global.customerV2.travelClub.aboutHTC.tab.all",
    "global.customerV2.travelClub.aboutHTC.tab.HTC.title",
    "global.customerV2.travelClub.aboutHTC.tab.cashback.title",
    "global.customerV2.travelClub.aboutHTC.tab.referral.title",
  ];

  return (
    <LayoutPageWithMapBackground
      title={t("global.customerV2.travelClub.title")}
      goBack
      btnLabel={t("global.customerV2.buttons.backToHTC")}
    >
      <div className="flex flex-col gap-12">
        <section className="pt-5 md:pt-[100px] mx-auto pb-12">
          <div className="h-[218px] w-full md:w-[600px] rounded-xl bg-gradient/roaming">
            <div className="relative w-full h-full flex justify-center items-center pt-[120px] md:pt-[150px] pb-5 px-5">
              <img
                draggable={false}
                src={flying}
                alt="flying"
                className="absolute bottom-24 md:bottom-[75px] z-[1] h-[180px] md:h-auto"
              />

              <img
                draggable={false}
                src={cloud}
                alt="cloud"
                className="absolute -left-6 bottom-11 h-40"
              />

              <img
                draggable={false}
                src={cloud}
                alt="cloud"
                className="absolute right-16 bottom-[135px] h-16"
              />

              <h2 className="text-center font-medium text-neutral-charcoal text-sm md:text-xl">
                <Trans
                  i18nKey={"global.customerV2.travelClub.aboutHTC.description"}
                  components={{
                    strong: <strong />,
                  }}
                />
              </h2>
            </div>
          </div>
        </section>

        <section className="w-full relative flex flex-col gap-10">
          <ChipGroup
            className={"flex flex-row gap-2 md:justify-center overflow-x-auto"}
            titles={refGroupChip}
            defaultSelectChip={selectedChip}
            onChipSelect={(value) => setSelectedChip(value)}
          />

          {selectedChip === 1 || selectedChip === 0 ? (
            <div className="flex flex-col gap-5">
              <h2 className="font-medium">
                {t("global.customerV2.travelClub.aboutHTC.tab.HTC.title")}
              </h2>

              <div className="flex flex-col gap-2">
                <Dropdown
                  title={t(
                    "global.customerV2.travelClub.aboutHTC.tab.HTC.question1"
                  )}
                  desc={
                    <Trans
                      i18nKey={
                        "global.customerV2.travelClub.aboutHTC.tab.HTC.answer1"
                      }
                      components={{
                        strong: <strong />,
                      }}
                    />
                  }
                />

                <Dropdown
                  title={t(
                    "global.customerV2.travelClub.aboutHTC.tab.HTC.question2"
                  )}
                  desc={
                    <Trans
                      i18nKey={
                        "global.customerV2.travelClub.aboutHTC.tab.HTC.answer2"
                      }
                      components={{
                        strong: <strong />,
                      }}
                    />
                  }
                />

                <Dropdown
                  title={t(
                    "global.customerV2.travelClub.aboutHTC.tab.HTC.question3"
                  )}
                  desc={
                    <Trans
                      i18nKey={
                        "global.customerV2.travelClub.aboutHTC.tab.HTC.answer3"
                      }
                      components={{
                        strong: <strong />,
                      }}
                    />
                  }
                />
              </div>
            </div>
          ) : null}

          {selectedChip === 2 || selectedChip === 0 ? (
            <div className="flex flex-col gap-5">
              <h2 className="font-medium">
                {t("global.customerV2.travelClub.aboutHTC.tab.cashback.title")}
              </h2>

              <div className="flex flex-col gap-2">
                <Dropdown
                  title={t(
                    "global.customerV2.travelClub.aboutHTC.tab.cashback.question1"
                  )}
                  desc={
                    <Trans
                      i18nKey={
                        "global.customerV2.travelClub.aboutHTC.tab.cashback.answer1"
                      }
                      components={{
                        strong: <strong />,
                      }}
                    />
                  }
                />

                <Dropdown
                  title={t(
                    "global.customerV2.travelClub.aboutHTC.tab.cashback.question2"
                  )}
                  desc={
                    <ul className="flex flex-col gap-2">
                      <li>
                        <Trans
                          i18nKey={
                            "global.customerV2.travelClub.aboutHTC.tab.cashback.answer2.bullet1"
                          }
                          components={{
                            strong: <strong />,
                          }}
                        />
                      </li>
                      <li>
                        <Trans
                          i18nKey={
                            "global.customerV2.travelClub.aboutHTC.tab.cashback.answer2.bullet2"
                          }
                          components={{
                            strong: <strong />,
                          }}
                        />
                      </li>
                      <li>
                        <Trans
                          i18nKey={
                            "global.customerV2.travelClub.aboutHTC.tab.cashback.answer2.bullet3"
                          }
                          components={{
                            strong: <strong />,
                          }}
                        />
                      </li>
                    </ul>
                  }
                />
              </div>
            </div>
          ) : null}

          {selectedChip === 3 || selectedChip === 0 ? (
            <div className="flex flex-col gap-5">
              <h2 className="font-medium">
                {t("global.customerV2.travelClub.aboutHTC.tab.referral.title")}
              </h2>

              <div className="flex flex-col gap-2">
                <Dropdown
                  title={t(
                    "global.customerV2.travelClub.aboutHTC.tab.referral.question1"
                  )}
                  desc={
                    <Trans
                      i18nKey={
                        "global.customerV2.travelClub.aboutHTC.tab.referral.answer1"
                      }
                      components={{
                        strong: <strong />,
                      }}
                    />
                  }
                />

                <Dropdown
                  title={t(
                    "global.customerV2.travelClub.aboutHTC.tab.referral.question2"
                  )}
                  desc={
                    <ul className="flex flex-col gap-2">
                      <li>
                        <Trans
                          i18nKey={
                            "global.customerV2.travelClub.aboutHTC.tab.referral.answer2.bullet1"
                          }
                          components={{
                            strong: <strong />,
                          }}
                        />
                      </li>
                      <li>
                        <Trans
                          i18nKey={
                            "global.customerV2.travelClub.aboutHTC.tab.referral.answer2.bullet2"
                          }
                          components={{
                            strong: <strong />,
                          }}
                        />
                      </li>
                      <li>
                        <Trans
                          i18nKey={
                            "global.customerV2.travelClub.aboutHTC.tab.referral.answer2.bullet3"
                          }
                          components={{
                            strong: <strong />,
                          }}
                        />
                      </li>
                    </ul>
                  }
                />
              </div>
            </div>
          ) : null}
        </section>
      </div>
    </LayoutPageWithMapBackground>
  );
};
