import arrow from "../../assets/Arrow.svg";

import "./style.css";

import React, { CSSProperties } from "react";

type Props = {
  text: string;
  arrowPosition: "top" | "bottom" | "left" | "right";
  customStyle?: CSSProperties;
  className?: string;
};

const CustomTooltip: React.FC<Props> = ({
  text,
  arrowPosition,
  customStyle,
  className,
}) => {
  const tooltipStyle = {
    top: arrowPosition === "bottom" ? "calc(100% + 10px)" : "",
    bottom: arrowPosition === "top" ? "calc(100% + 10px)" : "",
    left: arrowPosition === "right" ? "calc(50% + 10px)" : "",
    right: arrowPosition === "left" ? "calc(50% + 10px)" : "",
  };

  const styleEffect = (): React.CSSProperties => {
    switch (arrowPosition) {
      case "top":
        return {
          top: "-10px",
          rotate: "0deg",
        };
      case "bottom":
        return {
          bottom: "-10px",
          rotate: "180deg",
        };

      case "right":
        return {
          right: "-12px",
          bottom: "calc(50% - 10px)",
          rotate: "90deg",
        };

      case "left":
        return {
          left: "-12px",
          bottom: "calc(50% - 10px)",
          rotate: "-90deg",
        };
    }
  };

  return (
    <div style={{ ...customStyle }} {...(className ? { className } : "")}>
      <div className="shadow-card lg:flex rounded-lg flex-col items-center w-[210px] m-auto absolute hidden md:right-[calc(50%-100px)] lg:right-[calc(24%-100px)]">
        <div
          className={`w-[210px] min-h-[52px] h-full border bg-white rounded-lg border-neutral-300 px-3 py-[10px] ${tooltipStyle}`}>
          <p className="text-xs font-normal text-neutral-charcoal">{text}</p>
        </div>

        <div style={styleEffect()} className="absolute h-5 w-6">
          <img className="h-5 w-10" src={arrow} alt="arrow" />
        </div>
      </div>
    </div>
  );
};

export default CustomTooltip;
