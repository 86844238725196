import { useState } from "react";
import { LayoutAdminEmail } from "../../components/layouts/LayoutAdminEmail";
import { ChangeEmailPanel } from "./components/ChangeEmailPanel";
import { ChangeStatusEsimPanel } from "./components/ChangeStatusEsimPanel";

const ChangeEmailPage = (): JSX.Element => {
  const [tabId, setTabId] = useState("Change_Email");
  
  return (
    <LayoutAdminEmail setTabId={setTabId}>
      {tabId === "Change_Email" ? (
        <ChangeEmailPanel />
      ) : (
        <ChangeStatusEsimPanel />
      )}
    </LayoutAdminEmail>
  );
};

export default ChangeEmailPage;
