import Icon from "@mdi/react";
import { mdiTrayArrowDown } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { IBannerMobile } from "../../../interfaces";
import defaultImage from "../../../assets/main/bannerDownloadApp.svg";

export const BannerMobile = ({
  img,
  onClick,
  textSide,
  text,
}: IBannerMobile): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="banner-download-app"
      style={{
        position: "relative",
        width: "100%",
        height: "155px",
        borderRadius: "12px",
      }}
      className="relative w-full h-[155px] rounded-xl">
      <img
        className="w-full h-[155px] rounded-xl object-cover"
        src={img || defaultImage}
        alt="Banner"
      />
      <div
        data-testid="container-text-banner"
        className={`absolute w-[70%] h-full flex flex-col justify-center px-4 py-[21px] items-center gap-4 mt-1 ${
          textSide === "left" ? "left-[0]" : "right-[0]"
        } top-0 z-10 font-normal`}>
        <p className="text-sm text-center font-bold">{text}</p>
        <button
          name="download-app"
          onClick={onClick}
          className=" py-[12px] px-[20px] gap-3 flex justify-between items-center bg-[#292B2E] text-white rounded-2xl truncate ">
          <p className="text-sm truncate max-w-[135px]">
            {t("global.customerV2.header.buttonDownload")}
          </p>
          <Icon path={mdiTrayArrowDown} size={1} color={"white"} />
        </button>
      </div>
    </div>
  );
};
