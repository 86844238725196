import { gql } from "@apollo/client";

export const QUERY = gql`
  query ($id: ID!) {
    CustomerCard(id: $id) {
      id
      customer_id
      variant_id
      provider_name
      consumedData {
        totalDataMb
        remainingDataMb
        usedData
        usedDataPercent
      }
      actions
      iccid
      order_id
      order_name
      esim
      activable
      activation_date
      activationEmailSentDate
      boundle
      destination
      apiUserId
      status
      imsi
      msisdn
      provider_name
    }
  }
`;

export const QUERY_V2 = gql`
  query ($id: ID!) {
    CustomerCardV2(id: $id) {
      id
      customer_id
      variant_id
      provider_name
      consumedData {
        totalDataMb
        remainingDataMb
        usedData
        usedDataPercent
        isUnlimited
        remainingDays
      }
      actions
      iccid
      order_id
      order_name
      esim
      activable
      activation_date
      deactivation_date
      activationEmailSentDate
      boundle {
        es
        en
        de
        fr
        it
        pt
        ja
      }
      destination {
        es
        en
        de
        fr
        it
        pt
        ja
      }
      apiUserId
      status
      imsi
      msisdn
      provider_name
    }
  }
`;

export const QUERY_V2_PROVIDER = gql`
  query ($id: ID!) {
    CustomerCardV2Provider(id: $id) {
      id
      customer_id
      variant_id
      provider_name
      consumedData {
        totalDataMb
        remainingDataMb
        usedData
        usedDataPercent
        isUnlimited
        remainingDays
      }
      actions
      iccid
      order_id
      order_name
      esim
      activable
      activation_date
      deactivation_date
      activationEmailSentDate
      boundle {
        es
        en
        de
        fr
        it
        pt
        ja
      }
      destination {
        es
        en
        de
        fr
        it
        pt
        ja
      }
      apiUserId
      status
      imsi
      msisdn
      provider_name
    }
  }
`;

export const QUERY_TRANSLATIONS = gql`
  query GET_TRANS($id: [ID!]) {
    getTranslations(id: $id) {
      id
      es
      de
      en
      fr
      it
      pt
    }
  }
`;

export const TOPUP_QUERY = gql`
query {
  shop {
    name
  }
  products(first: 10, sortKey: TITLE, query: "title:Albania") {
    edges {
      node {
        title

        variants(first: 10) {
          edges {
            node {
              title
              barcode
              sku
            }
          }
        }
      }
    }
  }
}
`;