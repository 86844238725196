import React from "react";
import { useTranslation } from "react-i18next";

export const ContentSectionGeneral = ({ children }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <section
      data-testid="content-section-general"
      className="flex flex-col gap-6 py-[50px] bg-white w-full">
      <h2 className="text-center font-medium text-[28px]">
        {t("global.customerV2.details.generalInformation.title")}
      </h2>

      <div className="flex flex-col gap-1">{children}</div>
    </section>
  );
};
