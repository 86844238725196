import { currencys } from "../constants";
import { ICurrency } from "../interfaces";

export const getCurrencyData = (currencyId: string, getByCurrency?: boolean) => {
    const defaultCurrency: ICurrency = {
        label: "Euro",
        navbarLabel: "EUR (€)",
        id: "EUR - €",
        geolocation: 'ES',
        currency: 'EUR'
    };
    let currencyData: ICurrency | undefined;
    if (getByCurrency) {
        currencyData = currencys.find((divise) => divise.currency === currencyId);
    }
    else {

        currencyData = currencys.find((divise) => divise.id === currencyId);
    }
    return currencyData || defaultCurrency;
};