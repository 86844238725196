import { mdiBookOpenPageVariantOutline } from "@mdi/js";
import HButton from "../../../../../components/common/hbutton/HButton";
import { QrImage } from "../../../../../components/qrCode/QrImage";
import { CallDown, ConnectionWifi } from "../../../../../components/svg";
import { DeviceType } from "../../../PendingLite";
import { IOrderEsim } from "../../../../../interfaces";
import { ActiveEsimSuggestion } from "../ActiveEsimSuggestion";
import { suggestion } from "./suggestions";
import { Trans } from "react-i18next";
import { t } from "i18next";

interface QrOptionProps {
  mobileDevice: DeviceType;
  cardDetails: IOrderEsim;
  hanldeNavigateHelpCenter: () => void;
}
export const QrOption = ({
  mobileDevice,
  cardDetails,
  hanldeNavigateHelpCenter,
}: QrOptionProps) => {

  return (
    <div className="flex flex-col gap-5 w-full">
      <div className="gap-5 w-full flex flex-col p-[20px] border rounded-[12px] border-[#E4E6EA]">
        <article className="w-full items-center justify-center flex gap-2">
          <CallDown />
          <h1>1. {t("global.login.frequentDoubts.titleActiveEsim")}</h1>
        </article>
        <p className="text-center text-[16px] font-normal">
          {mobileDevice === "Iphone" ? (
            <Trans
              i18nKey={"global.InstallOption_QRCode_longPress"}
              components={{
                strong: <strong />,
              }}
            />
          ) : (
            <Trans
              i18nKey={"global.InstallOption_QRCode_android"}
              components={{
                strong: <strong />,
              }}
            />
          )}
        </p>
        <QrImage
          customerId={cardDetails.customer_id}
          cardId={cardDetails.id || ""}
        />
        {mobileDevice === "Iphone" && (
          <p className="text-center text-[16px] font-normal">
            <Trans
              i18nKey={"global.InstallOption_QRCode_scanQR"}
              components={{
                strong: <strong />,
              }}
            />
          </p>
        )}
        <HButton
          type="button"
          name="button activation guide"
          containerStyles="w-full mx-auto hover:bg-red-300 rounded-xl  bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold transition-all duration-300"
          text={t(
            "global.customerV2.details.pending.buttons.buttonActivationGuides"
          )}
          className="h-12 flex items-center justify-center px-5 py-3 text-white gap-2 border-none font-normal text-base"
          icon={mdiBookOpenPageVariantOutline}
          iconColor="white"
          onClick={hanldeNavigateHelpCenter}
        />
      </div>
      <div className="border border-neutral-300 rounded-xl px-4 pb-4 pt-6 md:p-[30px] flex flex-col gap-6">
        <div className="flex flex-row gap-[10px] justify-center items-center">
          <ConnectionWifi />
          <h3
            data-testid="activation-destination-title"
            className="font-bold text-base md:text-xl text-neutral-charcoal"
          >
            {t(
              "global.customerV2.details.pending.sectionActivateDestination.title"
            )}
          </h3>
        </div>
        <article className="w-full flex justify-center">
          <p
            data-testid="activation-destination-description"
            className="text-center text-base font-normal text-neutral-charcoal md:max-w-[820px]"
          >
            {t(
              "global.customerV2.details.pending.sectionActivateDestination.description"
            )}
          </p>
        </article>

        <ActiveEsimSuggestion suggestions={suggestion} />

        <HButton
          type="button"
          name="button activation guide"
          containerStyles="min-w-[215px] mx-auto hover:bg-red-300 rounded-xl  bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold transition-all duration-300"
          text={t(
            "global.customerV2.details.pending.buttons.buttonActivationGuides"
          )}
          className="h-12 flex items-center justify-center px-5 py-3 text-white gap-2 border-none font-normal text-base"
          icon={mdiBookOpenPageVariantOutline}
          iconColor="white"
          onClick={hanldeNavigateHelpCenter}
        />
      </div>
    </div>
  );
};
