import { mdiCogOutline, mdiPhoneInTalkOutline } from "@mdi/js";
import { Isuggestion } from "../ActiveEsimSuggestion";
import SimSuggestion from "../../../../../assets/details/SimSuggestion.svg";
import SwitchSuggestion from "../../../../../assets/details/SwitchSuggestion.svg";
import Icon from "@mdi/react";
import { t } from "i18next";

export const suggestion: Isuggestion[] = [
  {
    icon: <Icon path={mdiCogOutline} size={1} />,
    title: t("global.instructions.optionOne.2"),
  },
  {
    icon: <img src={SimSuggestion} alt="sim" />,
    title: t("global.instructions.optionThree"),
  },
  {
    icon: <img src={SwitchSuggestion} alt="switch" />,
    title: t("global.instructions.optionFour"),
  },
];
