import { t } from "i18next";
import { THEME } from "../../styles/theme";
import Chip from "../svg/chip/Chip";
import Sim from "../svg/sim/Sim";

export const EsimOrChip = ({ card }: any) => {
  return (
    <div className="flex">
      {card?.esim ? (
        <Chip className="h-[18px] w-[18px]" color={THEME.colors.black} />
      ) : (
        <Sim className="h-[18px] w-[18px]" color={THEME.colors.black} />
      )}
      <p className="ml-3 self-center text-xs">
        {card?.esim ? t("global.main.card.esim") : t("global.main.card.sim")}
      </p>
    </div>
  );
};
