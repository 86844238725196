import { SVGProps } from "react";

const YoutubeLight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M14.585 2.896a.65.65 0 0 0-.431-.473C13.804 2.324 12.1 2.1 8.5 2.1s-5.302.224-5.655.323c-.195.054-.368.234-.43.473-.087.34-.315 1.761-.315 4.004 0 2.243.228 3.664.315 4.005.061.238.234.418.43.472.353.1 2.055.323 5.655.323s5.303-.224 5.655-.322c.196-.055.368-.235.43-.474.087-.339.315-1.764.315-4.004 0-2.24-.228-3.664-.315-4.004Zm1.55-.397C16.5 3.924 16.5 6.9 16.5 6.9s0 2.976-.365 4.402c-.204.788-.798 1.408-1.55 1.617-1.368.381-6.085.381-6.085.381s-4.714 0-6.084-.38a2.249 2.249 0 0 1-1.55-1.618C.5 9.876.5 6.9.5 6.9S.5 3.924.866 2.5c.203-.788.797-1.408 1.55-1.618C3.786.501 8.5.501 8.5.501s4.717 0 6.084.38c.756.213 1.35.832 1.55 1.618ZM6.9 9.7V4.1l4.8 2.8-4.8 2.8Z"
    />
  </svg>
);
export default YoutubeLight;
