import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import global_fr from "./translations/fr/global.json";
import global_de from "./translations/de/global.json";
import global_it from "./translations/it/global.json";
import global_pt from "./translations/pt/global.json";
import global_jp from "./translations/ja/global.json";
import global_nl from "./translations/nl/global.json";
import global_ko from "./translations/ko/global.json";
import { getLanguage } from "./utils";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      global: global_en,
    },
  },
  es: {
    translation: {
      global: global_es,
    },
  },
  fr: {
    translation: {
      global: global_fr,
    },
  },
  de: {
    translation: {
      global: global_de,
    },
  },
  it: {
    translation: {
      global: global_it,
    },
  },
  pt: {
    translation: {
      global: global_pt,
    },
  },
  ja: {
    translation: {
      global: global_jp,
    },
  },
  nl: {
    translation: {
      global: global_nl,
    },
  },
  ko: {
    translation: {
      global: global_ko,
    },
  },
};

export const LANGUAGES_KEYS = Object.freeze(Object.keys(resources));

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLanguage(LANGUAGES_KEYS),

    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
