import { Fragment } from "react";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";
import { emailRegex } from "../../../utils";
import { useTimeout, useUpdateUserEmail } from "../../../hooks";
import { ContainerCard } from "./ContainerCard";
import { AlertNotification } from "../../../components/alert/AlertNotification";
import { FloatingLabelInput } from "../../../components/input/FloatingLabelInput";
import HButton from "../../../components/common/hbutton/HButton";
import { GENERIC_STYLES } from "../../../constants";

export const ChangeEmailPanel = (): JSX.Element => {
  const [showAlert, resetAlert] = useTimeout(4000);
  const {
    isLoading,
    isError,
    message,
    newEmail,
    oldEmail,
    onChange,
    sendChangeEmail
  } = useUpdateUserEmail();

  const emailPanelStyle =
    emailRegex.test(oldEmail) && emailRegex.test(newEmail)
      ? GENERIC_STYLES.standard
      : GENERIC_STYLES.default;

  const areBothEmailsInvalid =
    !emailRegex.test(oldEmail) && !emailRegex.test(newEmail);

  const handleEmailChange = () => {
    sendChangeEmail();
    resetAlert();
  };

  return (
    <Fragment>
      {!isLoading && (
        <AlertNotification
          isVisible={showAlert}
          alertType={isError ? "error" : "success"}
          message={message}
          customClassName={`${GENERIC_STYLES.alertNotification} ${
            !isError ? "bg-semantic-success-100" : "bg-semantic-error-100"
          }`}
        />
      )}

      <ContainerCard title={"Change Email"}>
        <FloatingLabelInput
          label={"Old Email"}
          name="oldEmail"
          type="email"
          value={oldEmail}
          error={false}
          errorMessage={"Correo invalido"}
          onChange={(text: string) => onChange(text, "oldEmail")}
        />

        <FloatingLabelInput
          label={"New Email"}
          name="newEmail"
          type="email"
          value={newEmail}
          error={false}
          errorMessage={"Correo invalido"}
          onChange={(text: string) => onChange(text, "newEmail")}
        />

        {isLoading ? (
          <HButton
            type="button"
            name="button load save email"
            className={`h-btn w-full h-12 px-5 py-3 rounded-2xl font-medium text-base flex items-center 
            justify-center ${GENERIC_STYLES.standard}`}
            disabled
            icon={
              <Icon
                className="animate-spin h-8 w-8 mr-3"
                color={"white"}
                path={mdiLoading}
              />
            }
          />
        ) : (
          <HButton
            type="button"
            name="button save email"
            className={`h-btn w-full h-12 px-5 py-3 rounded-2xl font-medium text-base flex items-center 
            justify-center ${emailPanelStyle}`}
            disabled={areBothEmailsInvalid}
            text="Save Email"
            onClick={handleEmailChange}
          />
        )}
      </ContainerCard>
    </Fragment>
  );
};
