import { Fragment } from "react";
import { mdiArrowRight } from "@mdi/js";
import { useCustomerStore } from "../../store/customerStore";
import { IReferralsProfileProps, ICustomerStore } from "../../interfaces";
import { useTranslation } from "react-i18next";
import HButton from "../common/hbutton/HButton";
import { HolaCoinIcon } from "../svg";

export const ReferralsProfile = ({
  flexAlign,
  isMobile,
  padding,
  pTop,
  pBottom,
  mobileNavigation,
  customText,
}: IReferralsProfileProps): JSX.Element => {
  const { t } = useTranslation();
  const { referralUser } = useCustomerStore((store: ICustomerStore) => store);
  return (
    <div
      data-testid="referral-profile"
      style={{
        padding: `${padding}px`,
        paddingTop: `${pTop}px`,
        paddingBottom: `${pBottom}px`,
      }}
      className="relative w-full justify-center items-center">
      <div
        style={{
          background:
            "var(--gradients-roaming, linear-gradient(166deg, #F2FDEB 0%, #E0F4FD 100%))",
          justifyContent: flexAlign || "center",
        }}
        className="w-full h-[48px] rounded-[200px] py-[12px] px-[15px] flex wrap items-center gap-[3px]">
        {customText ? (
          customText
        ) : (
          <Fragment>
            <HolaCoinIcon 
              className="w-[24px] h-[24px]"
            />
            <p className="text-[#292B2E] text-[16px] font-bold">
              {t(
                "global.customerV2.referrals.discountCode.RedeemHolaPoints.title",
                {
                  referralPoint: referralUser?.points_balance || 0,
                }
              )}
            </p>
          </Fragment>
        )}
        {isMobile && (
          <HButton
            type="button"
            name="button go back"
            icon={mdiArrowRight}
            onClick={mobileNavigation}
            containerStyles="ml-auto flex justify-center items-center"
            className=""
          />
        )}
      </div>
    </div>
  );
};
