import { ChartCircle } from "../../../../components/bandWidth/ChartCircle";

import { UnlimitedComsuptionSM } from "./UnlimitedComsuptionSM";

import { COLOR_STATUS } from "../../../../constants";
import { IHistoryItem } from "../../../../interfaces";

export const HistoryItem = ({
  planType,
  initialDate,
  finalDate,
  status,
  planDescription,
  daysRestant,
  percentConsumed,
}: IHistoryItem): JSX.Element => {
  return (
    <div
      data-testid="history-item"
      className="mb-[36px] w-full flex gap-[12px] items-center">
      <div
        className={`${
          planType === "limited" ? "pt-[15px]" : ""
        }  flex items-center justify-center w-[32px] h-[32px] bg-[#F8F8FB] rounded-[12px] p-[6px]`}>
        {planType === "limited" ? (
          <ChartCircle
            size={20}
            percent={percentConsumed ? percentConsumed : 0}
            trackWidth={1}
            indicatorWidth={2}
            customContent={<></>}
          />
        ) : (
          <UnlimitedComsuptionSM
            daysRestant={status === "Current" ? daysRestant : undefined}
          />
        )}
      </div>
      <div className=" h-full w-full lg:w-[341px] flex flex-col justify-start">
        <p
          data-testid="title-history-item"
          className="text-[#292B2E] text-[14px] font-bold w-full">
          {status === "Current" || status === "Next"
            ? planDescription
            : `Started on ${initialDate} - Expires on ${finalDate}`}
        </p>
        <p
          data-testid="description-history-item"
          className="text-[12px] font-normal text-[#56575A]">
          {status === "Current"
            ? `Expires on ${finalDate}`
            : status === "Next"
            ? "It will be activated when the current plan ends"
            : planDescription}
        </p>
      </div>
      <div
        style={{
          backgroundColor: COLOR_STATUS[status],
        }}
        className={`h-[24px py-[4px] px-[12px] rounded-[24px] ml-auto`}>
        <p className="text-[12px] font-normal text-[#002968]">{status}</p>
      </div>
    </div>
  );
};
