import axios from "axios";
import { makeApiCall } from "../apiCall";
import { API_URLS } from "../../constants";
import { setToken } from "../../utils";

const {
  URL_SIGNIN_LOGIN,
  URL_SIGNIN_GOOGLE_LOGIN,
  URL_SIGNIN_APPLE_LOGIN,
  URL_VERIFICATION_EMAIL_LOGIN,
} = API_URLS;

export const postSignIn = async (email: string, verificationCode: string) => {
  try {
    const { data, status } = await axios.post(URL_SIGNIN_LOGIN, {
      email,
      verificationCode,
    });

    if (status === 200) {
      return {
        data,
        status,
        isLoading: false,
      };
    }

    return {
      data: {},
      status,
      isLoading: false,
    };
  } catch (error) {
    const data: any = error.response?.data;
    return {
      data,
      status: 500,
      isLoading: false,
    };
  }
};

export const postEmailVerification = async (
  email: string,
  language: string
) => {
  const body = { email, language };

  try {
    const { data } = await makeApiCall<any>(
      "POST",
      URL_VERIFICATION_EMAIL_LOGIN,
      body
    );

    return { data, isError: false };
  } catch (error) {
    return { data: null, isError: true };
  }
};

export const postSignInWithGoogle = async (code: string) => {
  const body = {
    authorizationToken: code,
  };

  try {
    const { data } = await makeApiCall<any>(
      "POST",
      URL_SIGNIN_GOOGLE_LOGIN,
      body
    );

    return { data, isError: false };
  } catch (error) {
    return { data: null, isError: false };
  }
};

export const sendEmailVerification = async ({
  email,
  language,
}: {
  email: string;
  language: string;
}) => {
  await makeApiCall<any>("POST", URL_VERIFICATION_EMAIL_LOGIN, {
    email,
    language,
  });
};

export const signInApple = async (event: any) => {
  try {
    const { data: response } = await makeApiCall<any>(
      "POST",
      URL_SIGNIN_APPLE_LOGIN,
      {
        idToken: event.authorization.id_token,
      }
    );
    setToken(response.token);
    localStorage.setItem("customerId", response.customerId);

    return {
      data: response,
      error: false,
    };
  } catch (e) {
    return { data: null, error: true };
  }
};
