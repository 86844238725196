import { useTranslation } from "react-i18next";
import { mdiCartOutline } from "@mdi/js";
import HButton from "../common/hbutton/HButton";

import { redirectToWeb } from "../../utils/redirectLinkEsim";

export const CardDefault = (): JSX.Element => {

  const { t } = useTranslation()
  return (
    <div className="border border-neutral-400 border-dashed rounded-xl  w-full md:w-[340px] h-[302px] p-[30px]">
      <div className="flex flex-col justify-center items-center gap-2 h-full ">
        <h4 className="font-bold text-sm text-neutral-charcoal">
          {t('global.customerV2.home.buttons.buttonBuyText')}
        </h4>

        <HButton
          type="button"
          name="button redirect shop Esim"
          className="border-none bg-digital-green rounded-xl p-3 w-12 h-12 hover:bg-brand-green-500 active:bg-semantic-success-400 hover:text-white"
          icon={mdiCartOutline}
          onClick={redirectToWeb}
        />
      </div>
    </div>
  );
};
