import { initialCustomerProfile } from "../constants";
import { CustomerProfile, IUserProfile } from "../interfaces";

export const userProfileAdapter = (data: IUserProfile): CustomerProfile => {
  const reset = Object.assign({}, data);
  delete reset.name;
  delete reset.phone;
  delete reset.emails;

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const initialData: CustomerProfile = {
        ...reset,
        firstName: data.name?.firstName || "",
        lastName: data.name?.lastName || "",
        email:
          data.emails !== undefined
            ? data.emails.length > 0
              ? data.emails[0]?.address || ''
              : ""
            : "",
        phone: data.phone || "",
      };

      Object.keys(data).map((_, index) => {
        const key = Object.keys(initialData)[index];

        initialData[key] = initialData[key] ?? "";
      });

      return initialData;
    }
  }
  return initialCustomerProfile;
};
