import { IHeaderLogin } from "../../../interfaces";

import logo from "../../../assets/Holafly_Logo.svg";
import { MenuLanguage } from "../../../components/menus/MenuLanguage";

export const HeaderLogin = ({
  displayBackButton,
  children,
}: IHeaderLogin): JSX.Element => {
  return (
    <div
      data-testid="header-login"
      className={`flex md:flex-row md:justify-between items-center gap-4 `}
    >
      {displayBackButton ? children : null}
      <img
        data-testid="logo-desktop"
        draggable={false}
        className={`w-[90px] md:w-[160px]`}
        src={logo}
        alt="logo_Holafly"
      />

      <div className="ml-auto md:ml-0 border rounded-xl py-1 flex items-center h-full">
        <MenuLanguage onlyLanguage displayArrow customColor="#292B2E" />
      </div>
    </div>
  );
};
