import { useSearchParams } from "react-router-dom";
import { languages } from "../constants";
import i18n from "../i18n";

import { ICustomerStore } from "../interfaces";
import { useCustomerStore } from "../store/customerStore";

export const useSetLanguageFromUrl = () => {
  const { setLanguage } = useCustomerStore((store: ICustomerStore) => store);

  let [searchParams] = useSearchParams();

  const handleSetLanguage = () => {
    const languageQuery = searchParams.get("language");
    if (!languageQuery) {
      return;
    }

    const language = languages.find(
      (element) => element.id === languageQuery.toLowerCase()
    );

    if (language) {
      i18n.changeLanguage(language.id);
      setLanguage(language.id);
      return;
    }
    setLanguage("en");
  };

  return handleSetLanguage;
};
