import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { t } from "i18next";
import i18n from "../../../../i18n";
import { useCustomerStore } from "../../../../store/customerStore";
import { ICustomerStore } from "../../../../interfaces";
import { TabsControl } from "../../../../components/tabsBar/TabsControl";
import { languages } from "../../../../constants";
import { ItemSelector } from "../../../../components/languageSelector/ItemSelector";

export const LanguageSelector = ({
  handleChangeVisible,
}: {
  handleChangeVisible: (value: boolean) => void;
}): JSX.Element => {
  let [searchParams, setSearchParams] = useSearchParams();

  const { language: currentLanguage, setLanguage } = useCustomerStore(
    (state: ICustomerStore) => state
  );

  const [tabIndex, setTabIndex] = useState(0);

  const handleLanguageChange = (id: string) => {
    i18n.changeLanguage(id);
    setLanguage(id);
    setSearchParams({
      ...searchParams,
      language: id,
    });
  };

  return (
    <div
      data-testid="container_language"
      className="top-0 flex justify-center items-center w-full p-2 h-screen fixed bg-gray-400 z-20"
    >
      <div className="flex flex-col gap-4 sm:items-center w-[95%] h-[95%] bg-white rounded-lg p-4">
        <div
          data-testid="btn_language_close"
          className="ml-auto cursor-pointer"
          onClick={() => handleChangeVisible(false)}
        >
          <Icon path={mdiClose} title="Close" size={1.5} />
        </div>

        <div className="w-full md:w-auto mt-2 mr-auto">
          <TabsControl
            activeTab={tabIndex}
            bgColorContainer="bg-white"
            widthContainer="w-full md:w-[310px]"
            widthTab="w-full md:w-36"
            styleTabInactive="border-none bg-white"
            styleTabActive="bg-[#F5FFF9] border border-digital-green md:px-6 text-center py-2"
            tabsData={[
              {
                id: "language",
                title: t("global.header.langTitle"),
              },
            ]}
            handleTabClick={setTabIndex}
          />
        </div>

        <h3 className="mt-3 mr-auto font-semibold text-2xl">
          {t("global.header.suggestedLanguage")}
        </h3>

        <div className="mt-3 overflow-auto overflow-x-hidden flex flex-col gap-4 w-full max-h-[330px] sm:max-h-full sm:flex-row sm:flex-wrap">
          {languages.map((language, index) => (
            <ItemSelector
              key={`${language.id}-${index}`}
              id={language.id}
              current={currentLanguage}
              onClick={handleLanguageChange}
              label={language.label}
              type={"LANGUAGE"}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
