export const SkeletonStep = () => {
  return (
    <div className="flex flex-col items-center w-full animate-pulse gap-[20px]">
      <article className="w-full h-[334px] bg-[#E4E6EA] rounded-[12px]" />
      <div className="flex flex-col gap-2 items-center">
        <article className="h-[22px] bg-[#E4E6EA] rounded-[12px] w-[204px]" />
        <article className="h-[22px] bg-[#E4E6EA] rounded-[12px] w-[305px]" />
        <article className="h-[22px] bg-[#E4E6EA] rounded-[12px] w-[293px]" />
        <article className="h-[22px] bg-[#E4E6EA] rounded-[12px] w-[204px]" />
        <article className="h-[22px] bg-[#E4E6EA] rounded-[12px] w-[293px]" />
      </div>
      <article className="h-[48px] w-[343px] bg-[#E4E6EA] rounded-[12px]" />
    </div>
  );
};
