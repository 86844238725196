import { Icon } from "@mdi/react";
import { IDownloadFileButton } from "../../../interfaces";

export default function DownloadFileButton({
  url,
  fileName,
  text,
  icon,
}: IDownloadFileButton): JSX.Element {
  return (
    <a
      target="_blank"
      href={url}
      rel="noopener noreferrer"
      download={fileName}
      className="rounded-xl bg-white border-neutral-300 font-medium text-base py-3 px-5 
            gap-2 flex flex-row-reverse items-center justify-center text-neutral-charcoal 
            cursor-pointer hover:shadow-card"
    >
      <Icon data-testid="icon" path={icon} size={1} color={"black"} />
      {text}
    </a>
  );
}
