import React from "react";
import { QrImage } from "../../../components/qrCode/QrImage";
import { IQrCodeGuideCardProps } from "../../../interfaces";

export const QrCodeGuideCard = ({
  customerId,
  cardId,
}: IQrCodeGuideCardProps): JSX.Element => {
  return (
    <div data-testid="guide-qr-code" className="flex flex-col gap-2 mt-8">
      <h4 className="font-normal text-base text-neutral-charcoal">
        Your QR Code
      </h4>
      <div className="flex flex-col p-[30px] bg-neutral-200  gap-6 rounded-xl">
        <QrImage customerId={customerId} cardId={cardId} />
      </div>
    </div>
  );
};
