import React, { Fragment, useState } from "react";
import Icon from "@mdi/react";
import { mdiCalendarOutline, mdiLoading } from "@mdi/js";
import HButton from "../../../components/common/hbutton/HButton";
import { AlertNotification } from "../../../components/alert/AlertNotification";
import { ContainerCard } from "./ContainerCard";
import { FloatingLabelInput } from "../../../components/input/FloatingLabelInput";
import { useForm, useTimeout, useUpdateStatusCard } from "../../../hooks";
import { formatYYYYMMDD } from "../../../utils";
import { GENERIC_STYLES, STATUS_ESIM } from "../../../constants";

const initialState = {
  iccid: "",
  status: "",
  activationDate: "",
  deactivationDate: "",
};

export const ChangeStatusEsimPanel = (): JSX.Element => {
  const [showAlert, resetAlert] = useTimeout(6000);
  const { iccid, status, activationDate, deactivationDate, onChange, form } =
    useForm(initialState);

  const [error, setError] = useState<string>("");
  const {
    loading,
    shouldShowAlert,
    error: errorResponse,
    getAlertClassName,
    getAlertMessage,
    fetchUpdateEsim,
  } = useUpdateStatusCard();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    resetAlert();
    event.preventDefault();

    if (!iccid || !status || !activationDate || !deactivationDate) {
      setError("All fields must be completed");
      return;
    }

    if (status === "#") {
      setError("Select an option");
      return;
    }

    if (!formatYYYYMMDD.test(form.activationDate && form.deactivationDate)) {
      setError(
        "The date and time must be in the format “YYYYY-MM-DD HH:MM:SS”"
      );
      return;
    }

    setError("");
    fetchUpdateEsim(form);
  };

  return (
    <Fragment>
      {shouldShowAlert && (
        <AlertNotification
          isVisible={showAlert}
          alertType={Boolean(errorResponse) ? "error" : "success"}
          message={getAlertMessage()}
          customClassName={getAlertClassName()}
        />
      )}

      <ContainerCard title={"Change Status Esim"}>
        <form
          noValidate
          onSubmit={handleSubmit}
          className="w-full flex flex-col gap-2"
        >
          <FloatingLabelInput
            label={"Iccid"}
            name="iccid"
            type="string"
            value={iccid}
            error={false}
            errorMessage={""}
            onChange={(text: string) => onChange(text, "iccid")}
          />

          <div className="flex flex-col gap-2 mb-3">
            <h2 className="text-sm">Select status:</h2>
            <div className="relative text-gray-800 border rounded-lg border-gray-300 bg-white h-9">
              <select
                className="appearance-none outline-none w-full py-1 px-2 bg-white text-sm border rounded-lg border-transparent h-8"
                name="status"
                id="frm-whatever"
                value={status}
                onChange={(text) => onChange(text.target.value, "status")}
              >
                <option value="#">Please choose&hellip;</option>
                {STATUS_ESIM.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.status}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                <svg
                  className="h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <FloatingLabelInput
            label={"Activation date - (YYYY-MM-DD HH:MM:SS)"}
            name="activationDate"
            type="string"
            value={activationDate}
            error={false}
            errorMessage={""}
            onChange={(text: string) => onChange(text, "activationDate")}
            iconPath={mdiCalendarOutline}
          />

          <FloatingLabelInput
            label={"Desactivation date - (YYYY-MM-DD HH:MM:SS)"}
            name="deactivationDate"
            type="string"
            value={deactivationDate}
            error={false}
            errorMessage={""}
            onChange={(text: string) => onChange(text, "deactivationDate")}
            iconPath={mdiCalendarOutline}
          />
          {error && <div style={{ color: "red" }}>{error}</div>}

          {!loading ? (
            <HButton
              text="Submit"
              type="submit"
              name="submit change status Esim"
              className={`h-btn w-full h-12 px-5 py-3 rounded-2xl font-medium text-base flex items-center 
              justify-center ${GENERIC_STYLES.standard}`}
            />
          ) : (
            <HButton
              disabled
              type="button"
              name="loading change status Esim"
              className={`h-btn w-full h-12 px-5 py-3 rounded-2xl font-medium text-base flex items-center 
              justify-center ${GENERIC_STYLES.standard}`}
              icon={
                <Icon
                  className="animate-spin h-8 w-8 mr-3"
                  color={"white"}
                  path={mdiLoading}
                />
              }
            />
          )}
        </form>
      </ContainerCard>
    </Fragment>
  );
};
