import { INACTIVE_PROVIDERS } from "../constants";


/**
 * Validation function that checks if the provider is active
 * @param provider String with the provider name
 * @returns Boolean value that indicates if the provider is active
 */
export function isProviderActive(provider: string) {
  return !INACTIVE_PROVIDERS.find(p => p === provider);
}
