import { mdiFaceAgent } from "@mdi/js";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";

import { TermsAndPoliticsTextLink } from "../../../components/termsAndPolitics/TermsAndPoliticsTextLink";

import { ChatSvg } from "../../../components/svg";

export const BannerContact = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <section
      data-testid="banner-contact-help"
      className="bg-white 2xl:px-0 pt-20 pb-4 xl:px-56 lg:px-32 md:px-20 px-8">
      <div className="2xl:w-[60%]  2xl:my-0 2xl:mx-auto bg-brand-orange-300  w-full  rounded-xl border border-transparent p-4 md:py-[30px] px-[30px]">
        <div className="flex flex-col lg:flex-row items-center justify-between  h-full gap-4 md:gap-6 p-5">
          <div className="flex flex-col gap-6 justify-center h-full">
            <h2 className="font-medium text-center text-[22px] lg:text-[28px] lg:text-start text-neutral-charcoal w-full md:w-80 leading-[34px]">
              {t("global.login.frequentDoubts.textSupport")}
            </h2>

            <div className="hover:shadow-card hover:cursor-pointer transition-all flex flex-row gap-2 bg-white w-max self-center lg:self-start items-center justify-center px-5 py-3 rounded-xl">
              <Icon path={mdiFaceAgent} size={0.8} />
              <a
                target={"_blank"}
                href={t("global.qrModal.linkFaq")}
                className="transition-all duration-300 text-base text-neutral-charcoal font-medium"
                rel="noreferrer">
                {t("global.login.frequentDoubts.textBtnSupport")}
              </a>
            </div>
          </div>

          <ChatSvg className="hidden lg:flex" />
        </div>
      </div>
      <div className="flex justify-center h-10 md:h-20 mt-6 items-center">
        <TermsAndPoliticsTextLink isUnderline />
      </div>
    </section>
  );
};
