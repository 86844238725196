import React, { Fragment, useState } from "react";
import { t } from "i18next";
import HButton from "../../../../components/common/hbutton/HButton";
import { STEPS_ONBOARDING_TRAVEL_CLUB } from "../../../../constants";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { useCustomerStore } from "../../../../store/customerStore";
import { ICustomerStore } from "../../../../interfaces";

export const OnboardingTravelClubModal = ({ closeModal }): JSX.Element => {
  const { setHasSeenOnboardingTravelClub } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  const [currentStep, setCurrentStep] = useState(0); // Iniciamos desde la primera etapa (0)

  const handleNext = () => {
    if (currentStep === STEPS_ONBOARDING_TRAVEL_CLUB.length - 1) {
      setHasSeenOnboardingTravelClub(true);
      closeModal();
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep === 0) {
      return;
    }
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleClose = () => {
    closeModal();
    setHasSeenOnboardingTravelClub(true);
  };

  return (
    <div data-testid="OnboardingTravelClubModal" className="relative bg-white w-full md:w-[600px] rounded-xl mt-[80px] md:mt-0">
      <div className="p-2 hidden md:flex justify-end">
        <button
          name="button close friend discount modal"
          aria-label="button close friend discount modal"
          onClick={handleClose}
          className="border border-neutral-300 hover:bg-neutral-200 rounded-full w-8 h-8 flex 
    justify-center items-center transition-all duration-300"
        >
          <Icon path={mdiClose} size={0.8} />
        </button>
      </div>

      <div className="flex md:hidden py-5 px-4 items-center justify-between">
        <div />
        <h2 className="text-[22px] font-medium text-neutral-charcoal">
          {t("global.customerV2.travelClub.title")}
        </h2>

        <p
          onClick={handleClose}
          className="underline text-sm font-normal text-neutral-charcoal"
        >
          {t("global.customerV2.button.skip")}
        </p>
      </div>

      <div className="p-4 md:px-6 md:pb-6 flex flex-col gap-6 justify-center items-center">
        {STEPS_ONBOARDING_TRAVEL_CLUB.map((step, index) => {
          // Solo mostramos la etapa actual
          if (index !== currentStep) return null;
          return (
            <Fragment key={step.id}>
              <img
                className="hidden md:flex"
                width={522}
                height={284}
                draggable={false}
                src={step.img}
                loading="lazy"
                alt={`step_${index}`}
              />

              <img
                className="flex md:hidden w-full"
                width={358}
                height={343}
                draggable={false}
                src={step.imgMobile}
                loading="lazy"
                alt={`step_${index}`}
              />

              <div className="flex flex-col gap-5 w-full">
                <h2 className="text-center text-base md:text-[22px] font-medium text-neutral-charcoal">
                  {t(step.title)}
                </h2>

                <p className="text-center text-base text-normal text-neutral-charcoal">
                  {t(step.description)}
                </p>
              </div>
            </Fragment>
          );
        })}

        <div className="flex flex-row gap-3 w-full justify-center">
          {currentStep !== 0 && (
            <HButton
              type="button"
              name="button back step onboardig"
              onClick={handleBack}
              containerStyles="w-full md:w-[190px] bg-white hover:bg-neutral-300 transition-all duration-300 py-3 px-5 rounded-xl border border-neutral-300"
              className="flex justify-center items-center text-base font-medium text-neutral-charcoal"
              text={t("global.qrModal.thirdSection.ctaBack")}
            />
          )}

          <HButton
            type="button"
            name="button next step onboarding"
            onClick={handleNext}
            containerStyles="w-full md:w-[190px] bg-gradient/holafly hover:bg-gradient/holafly-hover transition-all duration-300 py-3 px-5 rounded-xl"
            className="flex justify-center items-center text-base font-medium text-white"
            text={
              currentStep === STEPS_ONBOARDING_TRAVEL_CLUB.length - 1
                ? t("global.customerV2.button.finishOnboarding")
                : t("global.customerV2.button.next")
            }
          />
        </div>
      </div>
    </div>
  );
};
