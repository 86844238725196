import { SVGProps } from "react";

const LinkedinLight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}>
    <path
      fill={props.color || "#292B2E"}
      d="M8.272 6.032c.838-.857 1.93-1.417 3.2-1.417A5.029 5.029 0 0 1 16.5 9.643V16.1a.4.4 0 0 1-.4.4h-1.028a.4.4 0 0 1-.4-.4V9.643a3.2 3.2 0 0 0-6.4 0V16.1a.4.4 0 0 1-.4.4H6.843a.4.4 0 0 1-.4-.4V5.472c0-.221.18-.4.4-.4h1.029c.22 0 .4.179.4.4v.56Zm-6.4-2.789a1.371 1.371 0 1 1 0-2.743 1.371 1.371 0 0 1 0 2.743ZM.957 5.472c0-.221.18-.4.4-.4h1.029c.22 0 .4.179.4.4V16.1a.4.4 0 0 1-.4.4H1.357a.4.4 0 0 1-.4-.4V5.472Z"
    />
  </svg>
);
export default LinkedinLight;
