import { EsimOrChip } from "../EsimOrChip";
import { DestinationText } from "../countryStateHeaderCard/DestinationText";
import { StatusAndBundle } from "../countryStateHeaderCard/StatusAndBundle";
import { IDataAndStatusDestination } from "../../../interfaces";

export const CountryAndStateContent = ({
  card,
  showStatusDestination,
}: IDataAndStatusDestination): JSX.Element => {
  return(
  <div className="flex h-full py-[0.7rem]">
    <div className="flex flex-col justify-evenly h-full w-full">
      <EsimOrChip card={card} />
      <DestinationText
        showStatusDestination={showStatusDestination}
        card={card}
      />
      <StatusAndBundle
        showStatusDestination={showStatusDestination}
        card={card}
      />
    </div>
  </div>
);}
