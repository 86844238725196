import { ISvg } from "../../../interfaces/components/svg/svgInterface";
import { THEME } from "../../../styles/theme";

const Circle = ({
  className,
  color = THEME.colors.black,
}: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="circle"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(className && { className })}
    >
      <rect x="0.989868" width="16" height="16" rx="8" fill={color} />
    </svg>
  );
};

export default Circle;
