import { Fragment } from "react";
import { ExpiredSectionInfo } from "../../details/components/ExpiredSectionInfo";
import { TABS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { CardDefault } from "../../../components/cards/CardDefault";
import { IExpiredSectionOrDefaultProps } from "../../../interfaces";

export const ExpiredSectionOrDefault = ({
  tabs,
  cards,
}: IExpiredSectionOrDefaultProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {tabs.find((tab) => tab.id === TABS.EXPIRED)?.active
        ? !(cards.length > 0) && (
            <ExpiredSectionInfo
              shadowOff
              title="global.customerV2.home.sectionExpired.title"
              content="global.customerV2.home.sectionExpired.subtitle"
              btnText="global.customerV2.home.buttons.buttonBuyText"
            />
          )
        : !cards.length && (
            <div
              data-testid="section-default"
              className="flex flex-col gap-6 justify-center items-center"
            >
              <div className="flex flex-col gap-1 items-center">
                <h2 className="text-neutral-charcoal font-bold md:font-medium text-xl md:text-[28px]">
                  {t("global.customerV2.home.sectionCurrent.default.title")}
                </h2>
                <p className="text-neutral-charcoal font-normal text-base text-center">
                  {t("global.customerV2.home.sectionCurrent.default.subtitle")}
                </p>
              </div>

              <CardDefault />
            </div>
          )}
    </Fragment>
  );
};
