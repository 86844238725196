import Icon from "@mdi/react";
import { ITabsControl } from "../../interfaces";
import { DEFAULT_ACTIVE_STYLE, DEFAULT_INACTIVE_STYLE } from "../../constants";

export const TabsControl = ({
  tabsData,
  widthContainer = "md:w-64",
  widthTab = "md:w-28",
  activeTab,
  bgColorContainer = "bg-neutral-200",
  handleTabClick,
  styleTabActive = DEFAULT_ACTIVE_STYLE,
  styleTabInactive = DEFAULT_INACTIVE_STYLE,
}: ITabsControl): JSX.Element => {
  return (
    <div
      className={`h-14 relative ${widthContainer} 
      w-full mx-auto grid grid-cols-2 items-center px-3 py-2 rounded-full ${bgColorContainer} overflow-hidden transition gap-2`}
    >
      {tabsData.map((tab, index) => (
        <button
          data-testid="button-tab-control"
          key={tab.id}
          role="tab"
          aria-selected={index === activeTab}
          aria-controls={tab.id}
          id={`tab-${index}`}
          tabIndex={index === activeTab ? 0 : -1}
          className={`${
            index === activeTab ? styleTabActive : styleTabInactive
          } flex gap-2 justify-center items-center h-10  tab ${widthTab} w-full rounded-full transition duration-300 p-2`}
          onClick={() => handleTabClick(index)}
        >
          {tab.icon && (
            <Icon path={tab.icon} className="text-neutral-charcoal" size={1} />
          )}

          <span
            className={`${
              index === activeTab
                ? "font-bold text-neutral-900"
                : "font-medium text-neutral-charcoal"
            } text-base truncate`}
          >
            {tab.title}
          </span>
        </button>
      ))}
    </div>
  );
};
