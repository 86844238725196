import { makeApiCall } from "../apiCall";
import {
  IGetUserOrderResponse,
  IGetUserOrders,
  IGetUserOrdersResult,
} from "../../interfaces";
import { API_URLS } from "../../constants";

const initialResponseData = {
  count: 0,
  pages_count: 0,
  orders: [],
};

export const getUserOrders = async (
  { customerId, page, perPage, sort }: IGetUserOrders,
  signal?: AbortSignal
): Promise<IGetUserOrdersResult> => {
  const { URL_GET_USER_ORDERS_INVOICE } = API_URLS;

  try {
    const { data } = await makeApiCall<IGetUserOrderResponse>(
      "GET",
      URL_GET_USER_ORDERS_INVOICE(customerId, page, perPage, sort),
      null,
      signal
    );

    return {
      data,
      isError: false,
    };
  } catch (error) {
    console.error("Error al obtener la información:", error);
    return {
      data: initialResponseData,
      isError: true,
    };
  }
};
