import background from "../../assets/backgrounds/patternBackground.jpg";

export const LayoutBgLinearGradient = ({ children }: any): JSX.Element => {
  return (
    <div className="relative bg-[#F8F8FB]">
      <img
        className="absolute w-full h-screen"
        src={background}
        alt="Not found"
      />
      {children}
    </div>
  );
};
