import "./style.css";
import { Icon } from "@mdi/react";
import { IHButtonProps } from "../../../interfaces";

const HButton = ({
  className,
  disabled,
  containerStyles,
  icon,
  text,
  iconColor,
  onClick,
  testID,
  name,
  type
}: IHButtonProps): JSX.Element => {
  const disabledButton = disabled && disabled;

  return (
    <div data-testid="container_hbutton" className={containerStyles}>
      <button
        type={type}
        data-testid={testID ? testID : "hbutton"}
        disabled={disabledButton}
        onClick={onClick}
        aria-label={name}
        name={name}
        className={`transition duration-300 w-full ${className}`}>
        {icon &&
          (typeof icon === "string" ? (
            <Icon
              data-testid="icon"
              path={icon}
              size={1}
              color={iconColor ? iconColor : "black"}
            />
          ) : (
            icon
          ))}

        {text}
      </button>
    </div>
  );
};

export default HButton;
