import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiAlertCircleOutline } from "@mdi/js";
import { Alert } from "../../../components/alert/Alert";

import { useTimeout } from "../../../hooks/useTimeout";

export const AlertError = (): JSX.Element => {
  const { t } = useTranslation();
  const [showAlert, resetAlert] = useTimeout(4000);

  useEffect(() => {
    resetAlert();
  }, []);

  return showAlert ? (
    <Alert
      type="custom"
      customStyle="mt-20 absolute p-5 rounded-xl bg-semantic-error-100 z-10 mx-2 transition-all duration-300 animate-slideDown">
      <div className="flex flex-row gap-4 items-center w-full">
        <Icon
          data-testid="alert-circle-outline"
          path={mdiAlertCircleOutline}
          className="text-semantic-error-600 w-7 h-5"
        />

        <p
          data-testid="alert-text-error"
          className="font-normal text-sm leading-5 text-semantic-error-600">
          {t("global.account.alerts.alertErrorFailedNet")}
        </p>
      </div>
    </Alert>
  ) : (
    <></>
  );
};
