import { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";

import { TDropdownFooter } from "../../interfaces";

export const DropdownFooter = ({
  title,
  opts,
}: TDropdownFooter): JSX.Element => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const iconRotate: string = isOpen ? "rotate-180" : "rotate-0";

  const handleToggleDropdown = (): void => setIsOpen(!isOpen);

  return (
    <div className="flex flex-col">
      <div className="py-3 px-5">
        <div
          onClick={handleToggleDropdown}
          className="flex flex-row justify-between items-center transition-all duration-300"
        >
          <h4 className="font-medium text-base text-neutral-charcoal w-full text-center">
            {t(title)}
          </h4>
          <Icon className={`transition ${iconRotate}`} size={0.8} path={mdiChevronDown} />
        </div>

        {isOpen && (
          <ul className="mt-4 text-center transition-all duration-300">
            {opts.map(({ url, text }) => {
              return (
                <li key={url} className="my-2 whitespace-nowrap">
                  <a
                    className="font-medium text-sm text-neutral-charcoal"
                    target="_blank"
                    rel="noreferrer"
                    href={t(url)}
                  >
                    {t(text)}
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className="border-b border-neutral-300" />
    </div>
  );
};
