interface CheckboxProps {
  isActive: boolean;
  changeActive: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = ({ isActive, changeActive }: CheckboxProps) => {
  return (
    <div
      data-testid="checkbox"
      className="flex flex-wrap justify-between items-center gap-4">
      <input
        onChange={changeActive}
        className="h-[20px] w-[20px]"
        type="checkbox"
        checked={isActive}
      />
      <label htmlFor="validate">Validate</label>
    </div>
  );
};
