import * as React from "react";
import { SVGProps } from "react";
const CallDown = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={33}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M11.162 26.923c-.508 0-.94-.177-1.295-.533a1.763 1.763 0 0 1-.534-1.295V7.419c0-.508.178-.94.534-1.296a1.763 1.763 0 0 1 1.295-.533h6.857v1.524h-6.857a.293.293 0 0 0-.215.088.295.295 0 0 0-.09.217v.965h7.162v1.523h-7.162v12.699h10.159v-2.032h1.524v4.52c0 .509-.178.94-.534 1.296a1.763 1.763 0 0 1-1.295.533h-9.549Zm0-1.523h9.55c.084 0 .156-.03.216-.089a.296.296 0 0 0 .088-.216v-.965H10.857v.965c0 .084.03.157.09.216.059.059.13.088.215.088Zm10.87-7.848a.938.938 0 0 1-.342-.064.707.707 0 0 1-.293-.215l-3.556-3.53a.745.745 0 0 1-.228-.547c0-.211.076-.385.228-.52a.777.777 0 0 1 .534-.241.657.657 0 0 1 .533.24l2.362 2.337V8.13c0-.22.072-.403.215-.547a.742.742 0 0 1 .547-.215c.22 0 .402.072.546.215a.743.743 0 0 1 .216.547v6.882l2.362-2.336a.731.731 0 0 1 .533-.229c.203 0 .381.076.533.229a.664.664 0 0 1 .229.52.746.746 0 0 1-.229.546l-3.555 3.53a.707.707 0 0 1-.293.216.938.938 0 0 1-.342.064Z"
    />
  </svg>
);
export default CallDown;
