import { SVGProps } from "react";

export const EmptyBox = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      data-testid="empty_box"
      xmlns="http://www.w3.org/2000/svg"
      width={201}
      height={200}
      fill="none"
      {...props}
    >
      <path
        d="M67.15 91.153c22.814 2.102 30.438-27.575 17.99-35.405-12.446-7.83-18.723 7.796-5.913 12.434 9.845 3.565 18.17-4.267 23.387-14.317 2.518-4.85 3.36-16.146-1.287-22.535"
        stroke="#292B2E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="8 8"
      />
      <path
        fill="#292B2E"
        stroke="#292B2E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M131.426 84.005h30.385v89.89h-30.385z"
      />
      <path
        d="M132.826 83.744a.502.502 0 0 0-.441.735l9.293 17.425a.502.502 0 0 0 .441.264h29.041a.5.5 0 0 0 .441-.735l-9.293-17.425a.5.5 0 0 0-.441-.264h-29.041Z"
        fill="#fff"
        stroke="#292B2E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.698 83.744a.5.5 0 0 1 .441.735l-9.293 17.425a.501.501 0 0 1-.44.264H24.363a.5.5 0 0 1-.441-.735l9.293-17.425a.5.5 0 0 1 .441-.264h29.041Z"
        fill="#292B2E"
        stroke="#292B2E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#fff"
        stroke="#292B2E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M33.586 83.744h98.578v90.446H33.586z"
      />
      <path
        stroke="#292B2E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16 173.985h168.599M41.556 167.382h18.748M41.556 161.573h18.748"
      />
    </svg>
  );
};
