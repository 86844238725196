import { useState } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import { IDropdownDetailPay, ICustomerStore } from "../../../interfaces";
import { useCustomerStore } from "../../../store/customerStore";

export const DropdownDetailPay = ({
  data,
  currencySymbol,
}: IDropdownDetailPay): JSX.Element => {
  const { language } = useCustomerStore((store: ICustomerStore) => store);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const iconRotate: string = isOpen ? "rotate-180" : "rotate-0";

  const handleToggleDropdown = (): void => setIsOpen(!isOpen);
  
  return (
    <div className="flex flex-col w-full gap-2 bg-white rounded-xl p-4">
      <div
        data-testid="dropdown"
        onClick={handleToggleDropdown}
        className="flex flex-row justify-between items-center transition-all duration-300">
        <h4 className="text-neutral-charcoal font-bold text-base">
          ({data.totalDestinationQuantity}) x {data.destination[language]}
        </h4>

        <div className="flex flex-row gap-1 items-center">
          <p className="text-neutral-charcoal font-bold text-base">
            {currencySymbol}{data.totalDestinationPrice}
          </p>
          <Icon
            className={`transition ${iconRotate}`}
            size={0.8}
            path={mdiChevronDown}
          />
        </div>
      </div>

      {isOpen && (
        <div
          data-testid="list-detail-bundle"
          className="flex flex-col gap-6 px-6 transition-all duration-300">
          {data.items.map((item, idx) => {
            return (
              <div
                key={`${idx}_${data.destination[language]}`}
                className="flex flex-col gap-2">
                <div className="flex flex-row justify-between">
                  <h6 className="text-neutral-charcoal text-sm font-normal">
                    ({item.bundleQuantity}) x {item.bundle[language]}
                  </h6>

                  <p className="text-neutral-charcoal text-sm font-normal">
                    {currencySymbol}{item.totalBundlePrice}
                  </p>
                </div>

                <p className="text-neutral-charcoal text-sm font-normal">
                  Unit price: {currencySymbol}{item.unitBundlePrice}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
