import Icon from "@mdi/react";
import { mdiAlertCircleOutline, mdiCheckCircleOutline } from "@mdi/js";
import { Alert } from "./Alert";
import { IAlertNotification } from "../../interfaces";
import { useEffect } from "react";

export const AlertNotification = ({
  alertType,
  message,
  customClassName,
  isVisible,
  resetAlert,
}: IAlertNotification): JSX.Element => {
  const iconPath =
    alertType === "error" ? mdiAlertCircleOutline : mdiCheckCircleOutline;
  const textColor =
    alertType === "error"
      ? "text-semantic-error-600"
      : "text-semantic-success-600";

  useEffect(() => {
    if (resetAlert) {
      resetAlert();
    }
  }, [message]);

  return isVisible ? (
    <Alert type="custom" customStyle={customClassName}>
      <div className="flex flex-row gap-4 items-center w-full h-5">
        <Icon path={iconPath} size={0.8} className={textColor} />
        <p
          data-testid="alert-notification-text"
          className={`font-normal text-sm leading-5 ${textColor}`}
        >
          {message}
        </p>
      </div>
    </Alert>
  ) : (
    <></>
  );
};
