const storefrontAccessToken = process.env.REACT_APP_STOREFRONT_ACCESS_TOKEN;
const domain = process.env.REACT_APP_SHOP_TOPUP_DOMAIN_URL;

export const buyBundle = async (
  cardDetails: any,
  bundleSelected: any,
  geolocation: string,
  lang: any
) => {

  const cart = [
    {
      quantity: 1,
      variantId: bundleSelected.id,
    },
  ] as any;

  const query = `
  mutation createCart($cartInput: CartInput) @inContext(language:${lang}, country:${geolocation}){
        cartCreate(input: $cartInput) {
          cart {
          id
          createdAt
          updatedAt
          lines(first:10) {
            edges {
            node {
              id
              merchandise {
              ... on ProductVariant {
                id
              }
              }
            }
            }
        
          }
          attributes {
            key
            value
          }
          estimatedCost {
            totalAmount {
            amount
            currencyCode
            }
            subtotalAmount {
            amount
            currencyCode
            }
            totalTaxAmount {
            amount
            currencyCode
            }
            totalDutyAmount {
            amount
            currencyCode
            }
          }
          }
        }
        }`;
  const lines = cart.map(({ quantity, variantId }: any) => ({
    quantity,
    merchandiseId: variantId,
  }));
  const variables = {
    cartInput: {
      ...(lines.length && {
        lines,
      }),
      ...(geolocation && {
        buyerIdentity: {
          countryCode: geolocation,
        },
      }),
      note: `{\"iccid\": \"${cardDetails.iccid}\", \"provider\": \"${cardDetails.provider_name}\", \"customerCardId\": \"${cardDetails.id}\", \"customerId\": \"${cardDetails.customer_id}\"}`,
    },
  };
  const params = {
    query,
    variables,
  };
  const optionsQuery = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Shopify-Storefront-Access-Token": storefrontAccessToken as string,
    },
    body: JSON.stringify(params),
  };
  const cartRes = await fetch(domain + `/api/2022-01/graphql`, optionsQuery);
  const cartResponse = await cartRes.json();
  const query2 = `
      query checkoutURL @inContext(language:${lang}, country:${geolocation}) {
        cart(id: "${cartResponse.data.cartCreate.cart.id}") {
        checkoutUrl
        }
      }
      `;
  const optionsQuery2 = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
    },
    body: JSON.stringify({
      query: query2,
    }),
  } as any;
  const checkoutRes = await fetch(
    domain + `/api/2022-01/graphql`,
    optionsQuery2
  );
  const checkoutResponse = await checkoutRes.json();
  if (checkoutResponse?.data?.cart?.checkoutUrl) {
    var url = new URL(checkoutResponse?.data?.cart?.checkoutUrl);
    var search_params = url.searchParams;
    search_params.set("locale", lang);
    url.search = search_params.toString();
    var new_url = url.toString();
    window.location.href = new_url;
  }

  // client.checkout.create(input).then(checkout => {
  //   const id = checkout.id;
  //   client.checkout.addLineItems(id, [{quantity: 1, variantId: "gid://shopify/ProductVariant/42929591910614"}] as any).then(checkout => {
  //     if (checkout.webUrl) {
  //       var url = new URL(checkout.webUrl);
  //       var search_params = url.searchParams;
  //       search_params.set('locale', 'es');
  //       url.search = search_params.toString();
  //       var new_url = url.toString();
  //       window.location.href = new_url;
  //     }
  //   });
  // });
};
