import { SVGProps } from "react";

const CalendaryOutlineSM = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.61133 6.93555H19.3891V15.4022C19.3891 16.5223 19.3891 17.0824 19.1711 17.5102C18.9794 17.8865 18.6734 18.1925 18.2971 18.3842C17.8693 18.6022 17.3092 18.6022 16.1891 18.6022H4.81133C3.69122 18.6022 3.13117 18.6022 2.70335 18.3842C2.32702 18.1925 2.02106 17.8865 1.82932 17.5102C1.61133 17.0824 1.61133 16.5223 1.61133 15.4022V6.93555Z"
      stroke="#292B2E"
      strokeWidth="0.5"
    />
    <path
      d="M1.61133 5.69121C1.61133 4.57111 1.61133 4.01105 1.82932 3.58323C2.02106 3.20691 2.32702 2.90094 2.70335 2.7092C3.13117 2.49121 3.69122 2.49121 4.81133 2.49121H16.1891C17.3092 2.49121 17.8693 2.49121 18.2971 2.7092C18.6734 2.90094 18.9794 3.20691 19.1711 3.58323C19.3891 4.01105 19.3891 4.57111 19.3891 5.69121V6.93566H1.61133V5.69121Z"
      stroke="#292B2E"
      strokeWidth="0.5"
    />
    <path
      d="M4.94531 1.51855V3.74078"
      stroke="#292B2E"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <path
      d="M15.7773 1.51855V3.74078"
      stroke="#292B2E"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
  </svg>
);
export default CalendaryOutlineSM;
