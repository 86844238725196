import { useActivationTopUp } from '../../hooks';
import { Checkbox } from '../checkbox/Checkbox';
import { Input } from '../input/Input';

export const ActivationTopUp = () => {
  const {
    customerCardId,
    isValidate,
    onChangeCustomerId,
    onChangeIsValidate,
    makeActivation,
  } = useActivationTopUp();

  return (
    <div className="mt-3 p-3 flex flex-col justify-start gap-8 items-center w-full">
      <h1 className="font-bold text-3xl">Manual Activation Top Up</h1>
      <Input
        name="customerCardId"
        handleChange={onChangeCustomerId}
        label="Customer Card Id"
        type={"text"}
        value={customerCardId}
      />

      <Checkbox changeActive={onChangeIsValidate} isActive={isValidate} />

      <button
        disabled={customerCardId.length < 36}
        className={`w-full max-w-sm h-12 rounded-2xl ${
          customerCardId.length < 36 ? 'bg-slate-300' : 'bg-digital-green'
        } font-semibold`}
        onClick={makeActivation}
      >
        Activate
      </button>
    </div>
  );
};
