import { useNavigate } from "react-router-dom";
import HButton from "../../../components/common/hbutton/HButton";
import { IPropsHtml } from "../../../interfaces";
import bannerReferral from "../../../assets/main/banner_referrals.svg";
import bannerReferralMobile from "../../../assets/main/banner_referrals_mobile.svg";
import { t } from "i18next";
import { Trans } from "react-i18next";

export const BannerReferrals = ({ testId }: IPropsHtml): JSX.Element => {
  const navigate = useNavigate();

  const onNavigate = () => navigate("/referralBoard");
  return (
    <section data-testid={testId} className="md:py-[50px] py-6 max-w-[1016px]">
      <div className="relative flex justify-end items-start md:items-center">
        <img
          className="w-full md:block hidden"
          src={bannerReferral}
          draggable={false}
          alt="banner-referral"
        />
        <img
          className="w-screen md:hidden block h-[60vh] object-cover xs:h-full"
          src={bannerReferralMobile}
          draggable={false}
          alt="banner-referral"
        />
        <div className="absolute p-6 lg:p-[60px] lg:ml-[30%] w-full md:w-[420px] lg:w-auto">
          <div className="flex flex-col items-center md:items-start gap-4 ">
            <div className="flex flex-row justify-center items-center text-center md:text-left">
              <p className="font-medium text-xl lg:text-[28px] text-neutral-charcoal">
                {t(
                  "global.customerV2.home.exploreMore.banner.travelClub.title"
                )}
              </p>
            </div>
            <p className="text-center md:text-left">
              <Trans
                i18nKey={
                  "global.customerV2.home.exploreMore.banner.travelClub.description"
                }
                components={{
                  strong: <strong />,
                }}
              />
            </p>

            <HButton
              type="button"
              name="buton go to Holafly Travel Club"
              onClick={onNavigate}
              className="bg-neutral-charcoal rounded-xl py-3 px-5 flex justify-center items-center text-sm lg:text-base text-white font-medium"
              text={t(
                "global.customerV2.home.exploreMore.banner.travelClub.cta"
              )}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
