import { Trans, useTranslation } from "react-i18next";
import { LinkText } from "../../../components/linkText/LinkText";
import { TDescItemElement } from "../../../interfaces";

export const DescItemElement = ({ item }: TDescItemElement): JSX.Element => {
  const { t } = useTranslation();
  const { response, link } = item;

  if (Array.isArray(response)) {
    return (
      <>
        {response.map((paragraph, index) => (
          <p className="mb-4 last:mb-0" key={index}>
            {paragraph}
          </p>
        ))}
      </>
    );
  } else if (link) {
    return (
      <Trans
        i18nKey={response}
        components={{
          a: (
            <LinkText
              to={t(link)}
              className="font-bold underline cursor-pointer"
            />
          ),
        }}
      />
    );
  } else {
    return <p>{response}</p>;
  }
};
