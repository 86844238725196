import { Dropdown } from "../../../../components/dropdowns/Dropdown";
import { PurchaseInfoDropdown } from "../PurchaseInfoDropdown";
import { AboutEsimsDropdown } from "../AboutEsimsDropdown";
import { InstallCode } from "../../../../components/installCode/InstallCode";

import { aboutAddMoreData, aboutCalls } from "../../../../constants";
import { ContentSectionGeneral } from "../../../../components/content/ContentSectionGeneral";
import { useTranslation } from "react-i18next";
export interface IInfoQnAProps {
  order: string;
  iccid: string;
  customer_id: string;
}

export const InfoActive = ({
  order,
  iccid,
  customer_id,
}: IInfoQnAProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ContentSectionGeneral>
      <PurchaseInfoDropdown order={order} iccid={iccid} />

      <Dropdown
        title={t(
          "global.customerV2.details.pending.sectionInstructions.installationCode.title"
        )}
        desc={
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-[2px]">
              <InstallCode
                customerIdCard={customer_id || ""}
                iccidCard={iccid}
                bgContainerCard={"bg-white"}
              />
            </div>
          </div>
        }
      />

      {/* <AboutEsimsDropdown /> */}

      {/* <Dropdown
        title={t(
          "global.customerV2.details.generalInformation.dropdowm.three.title"
        )}
        desc={aboutAddMoreData.map(({ title, content, isList }, index) => {
          return (
            <div
              key={`${title}-${index}`}
              className="flex flex-col gap-[20px]  last-of-type:mb-0  mb-6"
            >
              <h6 className="font-bold text-neutral-charcoal text-sm">
                {title}
              </h6>
              {isList ? (
                <ul className="list-disc px-[30px] ">
                  {content.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              ) : (
                content.map((description, index) => (
                  <p
                    key={`${description}-${index}`}
                    className="font-normal text-neutral-charcoal text-sm"
                  >
                    {description}
                  </p>
                ))
              )}
            </div>
          );
        })}
      /> */}

      {/* <Dropdown
        title="About calls"
        desc={aboutCalls.map(({ title, description }) => {
          return (
            <div
              key={title}
              className="flex flex-col gap-[2px]  last-of-type:mb-0  mb-6"
            >
              <h6 className="font-bold text-neutral-charcoal text-sm">
                {title}
              </h6>
              <p className="font-normal text-neutral-charcoal text-sm">
                {description}
              </p>
            </div>
          );
        })}
      /> */}
    </ContentSectionGeneral>
  );
};
