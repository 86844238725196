import { TopUp } from "../../interfaces";
import { makeApiCall } from "../apiCall";

export async function getDetails({ destination, currentCurrency }) {
  const { REACT_APP_API_URL, REACT_APP_HFC_API_GET_TOPUP } = process.env;

  const URL = `${REACT_APP_API_URL}${REACT_APP_HFC_API_GET_TOPUP}/${destination}?currency=${currentCurrency}`;
  
  const { data } = await makeApiCall<any>("GET", URL);

  if (data) return data.data as TopUp[];
}
