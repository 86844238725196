import { CSSProperties } from "react";
import { IBuildBoxProps } from "../../../interfaces";

export const BuildBox = ({
  height,
  rounded,
  width,
  numberOfBoxes,
}: IBuildBoxProps) => {
  const styles: CSSProperties = {
    height: `${height}px`,
    width: width ? `${width}px` : "100%",
    borderRadius: `${rounded}px`,
  };

  const renderBoxes = (numberItems: number) => {
    let boxes: JSX.Element[] = [];

    for (let i = 1; i <= numberItems; i++) {
      boxes.push(
        <div key={`box-${i}`} style={styles} className="bg-[#E4E6EA]"></div>
      );
    }
    return boxes;
  };

  return (
    <div data-testid="build-box" className="w-full flex gap-[16px]">
      {renderBoxes(numberOfBoxes)}
    </div>
  );
};
