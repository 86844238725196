import { useState } from "react";
import { useScreen, useCustomNavigate } from "../../../hooks";
import { IMenuItems } from "../../../interfaces";
import "../menuItem/menuItems.css";

export const MenuItems = ({
  items,
  selectedId,
  overrideStyles,
  customStyles,
}: IMenuItems): JSX.Element => {
  const screenSize = useScreen();
  const [currentId, setCurrentId] = useState(selectedId);

  const navigate = useCustomNavigate();

  const defaultClick = (path: string, id: number) => {
    const redirect = navigate(path);

    if (!redirect) {
      return;
    }
    setCurrentId(id);
  };
  return (
    <ul
      data-testid="menu-list"
      id="items-list"
      className={
        customStyles
          ? `${customStyles}`
          : `${overrideStyles} flex-col sm:flex-row sm:gap-11 ml-10 w-full h-full`
      }>
      {items.map((item, idx) => (
        <div key={`${item.id}_item${item.name}${item.id}`}>
          {item.isCustomElement ? (
            screenSize <= 425 ? (
              item.customElement
            ) : (
              <li onClick={() => defaultClick(item.path, item.id)}>
                {item.customElement}
              </li>
            )
          ) : (
            <li
              className="inline cursor-pointer hover:text-red-500 w-auto font-medium"
              onClick={() => defaultClick(item.path, item.id)}>
              {item.name}
              {currentId === idx && (
                <p
                  data-testid="line_red"
                  className="bg-red-400 sm:w-full m-auto sm:h-[2px] rounded-sm relative top-[22px] z-0"></p>
              )}
            </li>
          )}
        </div>
      ))}
    </ul>
  );
};
