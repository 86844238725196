import { useEffect, useState } from "react";
import { getSessionStorage, saveSessionStorage } from "../utils";

enum StorageItem {
  paginationPosition = "paginationPosition",
  paginationInitialPosition = "paginationInitialPosition",
}

const getPageOnStorage = (item: StorageItem) =>
  Number(getSessionStorage(item)) || 0;

const setPageOnStorage = (item: StorageItem, value: number) =>
  saveSessionStorage(item, value);

const ITEMS_PER_PAGE = 3;

function setPageNumbers(current: number, numPagination: number) {
  const startPage = current * ITEMS_PER_PAGE + 1;
  const endPage = Math.min(startPage + ITEMS_PER_PAGE - 1, numPagination);

  const pageNumbers: number[] = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return { pageNumbers, startPage, endPage };
}

export function usePagination({
  numPagination,
  limitList,
  setInitialLimit,
  setCurrentPos,
}: {
  limitList: number;
  numPagination: number;
  setInitialLimit: (currentNextPage: number) => void;
  setCurrentPos?: (currentPosition: number) => void;
}) {
  const [currentPosition, setCurrentPosition] = useState(
    getPageOnStorage(StorageItem.paginationPosition)
  );
  const [currentNextPage, setCurrentNextPage] = useState(0);
  const [initialPosition, setInitialPosition] = useState(
    getPageOnStorage(StorageItem.paginationInitialPosition)
  );

  const { pageNumbers, endPage } = setPageNumbers(
    currentPosition,
    numPagination
  );

  function handleGoPrevious() {
    setInitialPosition(0);
    setCurrentPosition(currentPosition - 1);
    setCurrentNextPage((count) => count - 12);
    if (setCurrentPos) setCurrentPos(currentPosition);
  }

  function handleChangePage(pageNumber: number, index:number) {
    setInitialLimit(limitList * (pageNumber - 1));
    setInitialPosition(index);
    if (setCurrentPos) setCurrentPos(pageNumber);
  }

  function handleGoNext() {
    setInitialPosition(0);
    setCurrentPosition(currentPosition + 1);
    setCurrentNextPage((count) => count + 12);
    if (setCurrentPos) setCurrentPos(endPage + 1);
  }

  useEffect(
    function savePositionOnChange() {
      // Update local storage on position change
      setPageOnStorage(StorageItem.paginationPosition, currentPosition);
      setPageOnStorage(StorageItem.paginationInitialPosition, initialPosition);
    },
    [currentPosition, initialPosition]
  );

  useEffect(
    function setInitialLimitOnChange() {
      setInitialLimit(currentNextPage);
    },
    [currentNextPage, setInitialLimit]
  );

  return {
    currentPosition,
    currentNextPage,
    initialPosition,
    pageNumbers,
    endPage,
    setCurrentPosition,
    setCurrentNextPage,
    setInitialPosition,
    handleGoPrevious,
    handleChangePage,
    handleGoNext,
  };
}
