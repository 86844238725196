import { useEffect } from "react";
import { useGetCustomerProfile } from "../../hooks/useGetCustomerProfile";
import { LanguageSelector } from "../languageSelector/LanguageSelector";
import { NavbarDesktop } from "./navbar/NavbarDesktop";
import { NavbarMobile } from "./navbar/NavbarMobile";
import { useCustomerStore } from "../../store/customerStore";
import { ICustomerStore } from "../../interfaces";
import "./style.css";

const Header = (): JSX.Element => {
  const { customerId, isOpenMenuLanguage, toggleMenuLanguage } =
    useCustomerStore((state: ICustomerStore) => state);
  const { loadProfileData } = useGetCustomerProfile();

  useEffect(() => {
    loadProfileData();
  }, [customerId]);

  const handleCloseLanguage = () => toggleMenuLanguage(false);

  return (
    <header data-testid="header" className="fixed w-full bg-white z-20">
      <nav className="h-[72px] w-full max-w-[1440px] mx-auto py-3 px-4 lg:px-28 flex justify-center items-center md:border-none border-b border-b-neutral-300">
        <div className="flex justify-between items-center w-full">
          <NavbarMobile />

          <NavbarDesktop />
        </div>

        {isOpenMenuLanguage && (
          <LanguageSelector handleChangeVisible={handleCloseLanguage} />
        )}
      </nav>
      <div className="border-b w-full" />
    </header>
  );
};

export default Header;
