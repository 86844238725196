import { SVGProps } from "react";

const Cashback = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M13.379 12.379c-.55 0-1.017-.19-1.398-.572a1.901 1.901 0 0 1-.572-1.398c0-.55.19-1.016.572-1.398a1.901 1.901 0 0 1 1.398-.572c.55 0 1.016.191 1.398.572.38.382.572.848.572 1.398 0 .55-.191 1.016-.572 1.398a1.901 1.901 0 0 1-1.398.572Zm-5.197 2.44c-.392 0-.727-.14-1.006-.42a1.371 1.371 0 0 1-.418-1.005v-5.97c0-.391.139-.727.418-1.006A1.372 1.372 0 0 1 8.182 6h10.394c.391 0 .727.14 1.006.418.278.28.418.615.418 1.006v5.97c0 .392-.14.727-.418 1.006a1.371 1.371 0 0 1-1.006.418H8.182Zm1.182-1.183h8.03c0-.393.14-.728.418-1.006a1.373 1.373 0 0 1 1.006-.418V8.606a1.37 1.37 0 0 1-1.007-.418 1.373 1.373 0 0 1-.417-1.006h-8.03c0 .393-.14.728-.419 1.007a1.373 1.373 0 0 1-1.006.417v3.606c.393 0 .729.14 1.007.418.278.28.418.615.418 1.006Zm-3.94 3.94c-.391 0-.727-.14-1.006-.419A1.371 1.371 0 0 1 4 16.151V8.94c0-.167.057-.307.17-.42a.572.572 0 0 1 .421-.17c.168 0 .308.056.421.17.113.113.17.253.17.42v7.212c0 .061.025.117.076.167.05.05.106.076.166.076h11.637c.167 0 .307.057.42.17.114.113.17.254.17.421a.571.571 0 0 1-.17.421.572.572 0 0 1-.42.17H5.424Zm2.758-3.94h-.243V7.182h.243a.233.233 0 0 0-.17.072.233.233 0 0 0-.073.17v5.97c0 .066.024.122.072.17a.233.233 0 0 0 .17.072Z"
    />
  </svg>
);
export default Cashback;
