import { useState } from "react";
import Icon from "@mdi/react";
import { mdiCheck, mdiContentCopy } from "@mdi/js";
import { t } from "i18next";
import { ICopyToClipboard } from "../../../interfaces";

const CopyToClipboard = ({ copyText }: ICopyToClipboard): JSX.Element => {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        setIsCopied(false);
      });
  };

  return (
    <div className="items-center relative flex justify-center">
      <div
        data-testid="copy_text"
        className={`h-12 bg-[#292B2E] py-3 px-6 text-white text-xs rounded-[40px] ${
          isCopied ? "inline-flex" : "hidden"
        } justify-center items-center absolute bottom-6 transition duration-100 ease-out`}>
        <div className="flex items-center gap-1">
          <Icon path={mdiCheck} size={0.7} />
          <p className="text-[14px]">
            {t("global.details.manualInstallationModule.copy").split("!")[0]}
          </p>
        </div>
      </div>
      <div data-testid="copy_clipboard" onClick={() => handleCopyClick()}>
        <Icon
          path={mdiContentCopy}
          size={0.8}
          title={t("global.customerV2.texts.copy")}
          className="ml-auto cursor-pointer hover:text-slate-800"
        />
      </div>
    </div>
  );
};

export default CopyToClipboard;
