import { useTranslation } from "react-i18next";
import { IMainInitialBannerProps } from "../../interfaces";
import Map from "../../whiteMap.svg";

export const MainInitialBanner = ({
  centralClickElement,
  title,
  subTitle,
}: IMainInitialBannerProps) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        background:
          "linear-gradient(107.62deg, #BFEBFF -7.97%, rgba(249, 238, 248, 0.5) 56.43%, #FFCCB0 111.34%)",
      }}
      className="relative h-[198px] mt-[72px] w-full flex justify-center items-center">
      <img
        draggable={false}
        className="absolute text-center h-auto w-[580px] right-0 left-0 ml-auto mr-auto z-0"
        src={Map}
        alt="Map img"
      />
      <div className="flex justify-center flex-col items-center absolute text-black opacity-100">
        <h1 className="text-[22px] sm:text-[34px] font-medium">
          {t(`${title}`)}
        </h1>
        <h5 className="md:text-base text-sm font-bold">{t(subTitle)}</h5>
      </div>

      <div className="absolute  max-h-[70px] -bottom-4 ">
        {centralClickElement}
      </div>
    </div>
  );
};
