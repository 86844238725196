export const capitalize = (word: string) => {
  const str = word?.trim().split(" ");
  let capitalizedStr = "";

  if (str && word) {
    capitalizedStr = str.reduce((acc, curr) => {
      return acc + " " + curr[0].toUpperCase() + curr.slice(1).toLowerCase();
    }, "");
  } else {
    capitalizedStr = "";
  }

  return capitalizedStr ? capitalizedStr.trim() : "";
};

export const capitalizeFirstLetter = (cadena: string) => {
  return cadena.replace(/\b\w/g, function (letra) {
    return letra.toUpperCase();
  });
};
