import { IInput } from "../../interfaces";

export const Input = ({
  handleChange,
  type,
  label,
  value,
  name,
}: IInput): JSX.Element => {
  return (
    <input
      data-testid="input"
      className="w-full max-w-md h-14 border py-1 px-4 border-gray-300 rounded-lg font-semibold "
      placeholder={label}
      type={type}
      name={name}
      value={value}
      onChange={handleChange}
      required
    />
  );
};
