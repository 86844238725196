import { useState } from "react";

type ValidationRule<T> = {
  regex: RegExp;
  message: string;
};

export const useForm = <T extends Object>(
  initState: T,
  validationRules?: { [K in keyof T]?: ValidationRule<T[K]> }
) => {
  const [state, setState] = useState(initState);
  const [errors, setErrors] = useState<{ [K in keyof T]?: string }>({});
  const [touchedFields, setTouchedFields] = useState<{ [K in keyof T]?: boolean }>({});

  const onChange = (value: string, field: keyof T) => {
    setState({
      ...state,
      [field]: value,
    });

    if (value !== initState[field]) {
      setTouchedFields({
        ...touchedFields,
        [field]: true,
      });
    } else {
      setTouchedFields({
        ...touchedFields,
        [field]: false,
      });
    }

    if (errors[field]) {
      setErrors({
        ...errors,
        [field]: "",
      });
    }
  };

  const validate = () => {
    const newErrors: { [K in keyof T]?: string } = {};

    for (const field in validationRules) {
      const rule = validationRules[field as keyof T];
      const value = state[field as keyof T] as unknown as string;

      if (rule) {
        if (!value) {
          newErrors[field as keyof T] = rule.message;
        } else if (!rule.regex.test(value)) {
          newErrors[field as keyof T] = rule.message;
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const hasAnyFieldModified = () => {
    return Object.values(touchedFields).some((value) => value === true);
  };

  return {
    ...state,
    form: state,
    errors,
    onChange,
    setForm: setState,
    validate,
    hasAnyFieldModified,
  };
};
