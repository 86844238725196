import React from "react";
import { useQRImage } from "../../hooks/useQRImage";

export interface IQrImageProps {
  customerId: string;
  cardId: string;
}

export const QrImage = ({ customerId, cardId }: IQrImageProps): JSX.Element => {
  const { loadingQR, qrImg } = useQRImage(customerId, cardId);
  return (
    <div className="m-auto w-[175px] h-[163px] rounded-xl p-1 border border-[#E4E6EA] bg-white">
      {loadingQR ? (
        <div
          data-testid="loading-QrCode"
          className="m-auto animate-pulse bg-gray-300 w-[155px] mt-1.5 h-[142px] rounded-xl"
        />
      ) : (
        <img draggable={false} className="w-full h-full" src={qrImg} alt="qr" />
      )}
    </div>
  );
};
