export const LoadingItemSkeleton = (): JSX.Element => (
  <div
    data-testid="loading-item-skeleton"
    className="flex flex-row justify-between">
    <h2
      className={`w-[295px] h-6 bg-neutral-300 animate animate-pulse rounded-lg`}
    />
    <p className="w-20 h-6 bg-neutral-300 animate animate-pulse rounded-lg" />
  </div>
);
