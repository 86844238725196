import { Divider } from "../../../../components/common/divider/Divider";

export function LoadingSkeleton() {
  return (
    <div className="lg:px-[220px] flex flex-col gap-10 animate-pulse">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-4">
          <div className="bg-gray-300 rounded-lg w-40 h-6" />
          <Divider className="bg-neutral-300 h-[1px] w-full" />
          <div className="flex flex-col gap-2">
            <div className="bg-gray-300 rounded-lg w-full h-6" />
            <div className="bg-gray-300 rounded-lg w-full h-6" />
            <div className="bg-gray-300 rounded-lg w-full h-6" />
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="bg-gray-300 rounded-lg w-40 h-6" />

          <Divider className="bg-neutral-300 h-[1px] w-full" />
          <div className="flex flex-col gap-2">
            <div className="bg-gray-300 rounded-lg w-full h-6" />
            <div className="bg-gray-300 rounded-lg w-full h-6" />
            <div className="bg-gray-300 rounded-lg w-full h-6" />
          </div>
        </div>
      </div>
    </div>
  );
}
