export const SkeletonAddData = ({ num }: { num: number }): JSX.Element => {
  const skeletonList = new Array(num).fill(undefined).map((val, idx) => idx);

  return (
    <div className="w-full flex flex-wrap  gap-[8px] animate animate-pulse">
      {skeletonList.map((_, idx) => (
        <div
          key={idx}
          className="border-[#E4E6EA]  bg-white h-[86px] lg:w-[210px]  border rounded-[12px] p-[12px] w-full flex"
        >
          <div className="h-full w-[90%]  flex flex-col justify-between">
            <div className="bg-gray-300 w-[40%] lg:w-[80px] h-[20px] rounded-lg" />
            <p className="bg-gray-300 w-[60%] lg:w-[110px] h-[25px] rounded-lg"></p>
          </div>
          <div className="ml-auto bg-gray-300 w-[40px] h-full rounded-full" />
        </div>
      ))}
    </div>
  );
};
