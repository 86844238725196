import React, { useState } from "react";
import { Chip } from "./Chip";
import { IChipGroupProps } from "../../../interfaces";

export const ChipGroup = ({
  titles,
  onChipSelect,
  className,
  defaultSelectChip,
}: IChipGroupProps):JSX.Element => {
  const [selectedChipIndex, setSelectedChipIndex] = useState(defaultSelectChip);

  const handleChipSelect = (index) => {
    setSelectedChipIndex(index);
    onChipSelect(index);
  };

  return (
    <div {...(className && { className })}>
      {titles.map((title, index) => (
        <Chip
          key={index}
          title={title}
          onSelect={() => handleChipSelect(index)}
          selected={selectedChipIndex === index}
        />
      ))}
    </div>
  );
};
