import { useTranslation } from "react-i18next";
import { BannerGallery } from "../../../components/bannerGallery/BannerGallery";
import { galleryElements } from "../../../constants";

export const SectionGallery = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <h3 className="mt-10 md:mt-[100px] text-[20px] font-bold lg:text-[34px] lg:font-medium">
        {t("global.customerV2.banners.bannerHome.title")}
      </h3>

      <BannerGallery elements={galleryElements} />
    </>
  );
};
