import * as React from "react";
import { SVGProps } from "react";
const ConnectionWifi = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={33}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M11.838 27.393c-.484 0-.827-.232-1.027-.696-.2-.463-.12-.874.237-1.232l14.19-14.223c.36-.379.77-.469 1.233-.269.464.2.696.554.696 1.06v14.222a1.1 1.1 0 0 1-.331.806 1.1 1.1 0 0 1-.807.332H11.84Zm10.904-1.897h2.528v-11.63l-2.528 2.528v9.102Zm-13.4-13.811a.928.928 0 0 1-.285-.68c0-.263.106-.479.316-.647a8.304 8.304 0 0 1 2.229-1.107 7.782 7.782 0 0 1 2.386-.379c.78 0 1.564.121 2.355.363.79.243 1.521.606 2.196 1.091a.84.84 0 0 1 .332.647c.01.264-.09.49-.3.68a.754.754 0 0 1-.554.268.906.906 0 0 1-.616-.204 5.265 5.265 0 0 0-1.644-.837c-.59-.18-1.18-.27-1.77-.27-.59 0-1.184.09-1.784.27-.601.178-1.165.457-1.692.837a.906.906 0 0 1-.616.204.806.806 0 0 1-.553-.236ZM6.118 8.398a.828.828 0 0 1-.285-.648c0-.263.095-.48.285-.648a12.476 12.476 0 0 1 3.744-2.134 12.277 12.277 0 0 1 8.234 0 12.422 12.422 0 0 1 3.761 2.134.81.81 0 0 1 .285.632.91.91 0 0 1-.285.664.704.704 0 0 1-.552.253.984.984 0 0 1-.617-.221 9.958 9.958 0 0 0-3.192-1.785 10.929 10.929 0 0 0-3.508-.586c-1.201 0-2.376.195-3.524.586A9.996 9.996 0 0 0 7.287 8.43a.983.983 0 0 1-.615.22.705.705 0 0 1-.554-.252Zm7.838 7.901a1.58 1.58 0 0 1-1.154-.49 1.613 1.613 0 0 1-.49-1.185c0-.442.164-.827.49-1.153a1.58 1.58 0 0 1 1.154-.49c.442 0 .827.163 1.154.49.326.326.49.71.49 1.153 0 .464-.164.859-.49 1.185a1.58 1.58 0 0 1-1.154.49Z"
    />
  </svg>
);
export default ConnectionWifi;
