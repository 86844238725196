import { useState, useEffect } from "react";

export const useScreen = (): number => {
  const [screenMobile, setScreenMobile] = useState(window.screen.width);

  useEffect(() => {
    const handleResize = () => {
      setScreenMobile(window.screen.width);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenMobile;
};
