import { API_URLS } from "../../constants";
import { makeApiCall } from "../apiCall";

export async function logInAdmin(email: string): Promise<{ success: boolean }> {
  const { URL_LOGIN_ADMIN } = API_URLS;
  try {
    const { data } = await makeApiCall<any>("POST", URL_LOGIN_ADMIN, { email });
    if (data.message.isActive) {
      localStorage.setItem("isAdmin", data.message.isActive);
      return { success: true };
    }
    return { success: false };
  } catch (error) {
    return { success: false };
  }
}
