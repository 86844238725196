import { useTranslation } from "react-i18next";
import { dateFormatMMDDYYYY } from "../../../../utils";
import banner_invoice from "../../../../assets/invoice/banner_invoice.svg";
import banner_invoice_tablet from "../../../../assets/invoice/banner_invoice_tablet.svg";
import banner_invoice_mobile from "../../../../assets/invoice/banner_invoice_mobile.svg";
import { IOrderDetailBanner } from "../../../../interfaces";

export const OrderDetailBanner = ({
  orderNumber,
  date,
  currencyCode,
  totalAmount,
  currencySymbol
}: IOrderDetailBanner): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="order-detail-banner"
      className="relative flex justify-center items-start md:items-center rounded-xl h-full ">
      <img
        loading="lazy"
        className="lg:block hidden"
        src={banner_invoice}
        alt="banner"
        draggable={false}
      />

      <img
        loading="lazy"
        className="md:block lg:hidden hidden"
        src={banner_invoice_tablet}
        alt="banner"
        draggable={false}
      />

      <img
        loading="lazy"
        className="md:hidden block"
        src={banner_invoice_mobile}
        alt="banner"
        draggable={false}
      />

      <div className="absolute p-6 md:p-[30px]">
        <div className="flex flex-col justify-center items-center w-full gap-2">
          <h2 className="text-neutral-charcoal text-base font-bold">
            {t("global.customerV2.invoice.details.orderDetailBanner.title", {
              order: orderNumber,
            })}
          </h2>
          <p className="text-neutral-charcoal text-base font-normal">
            {t(
              "global.customerV2.invoice.details.orderDetailBanner.textFieldTotal"
            )}{" "}
            <span data-testid="banner-total-amount" className="text-[26px] font-bold">
             {currencySymbol}{totalAmount} {currencyCode}
            </span>
          </p>
          <p className="text-neutral-charcoal text-base font-normal">
            {t(
              "global.customerV2.invoice.details.orderDetailBanner.textFieldDatePurchase"
            )}{" "}
            {dateFormatMMDDYYYY(date)}
          </p>
        </div>
      </div>
    </div>
  );
};
