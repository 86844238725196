import { useTranslation } from "react-i18next";
import GirlOffice from "../../../../assets/referrals/GirlOffice.svg";

export function EmptyHistoryTravelClub() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center">
      <img
        src={GirlOffice}
        alt="Girl Office"
        className="w-full max-h-[200px]"
      />
      <p className="text-lg font-medium text-center text-neutral-charcoal">
        {t('global.customerV2.travelClub.history.emptyState.title')}
      </p>
      <p className="text-center  text-sm pt-5">
        {t('global.customerV2.travelClub.history.emptyState.description')}
      </p>
    </div>
  );
}