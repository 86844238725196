import React from "react";
import { IFlagImageProps } from "../../../interfaces";

export const FlagImage = ({
  flagImg,
  backgroundImage,
}: IFlagImageProps): JSX.Element => (
  <img
    draggable={false}
    className={`w-6 h-6 object-contain z-20 ${backgroundImage}`}
    src={flagImg}
    alt="country_image"
  />
);
