import { SVGProps } from "react";

const HolaCoinIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    role="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M12.001 20.063a7.792 7.792 0 0 1-3.12-.63 8.082 8.082 0 0 1-2.54-1.71 8.077 8.077 0 0 1-1.71-2.54A7.786 7.786 0 0 1 4 12.065c0-1.106.21-2.146.63-3.12.42-.973.99-1.82 1.71-2.54a8.077 8.077 0 0 1 2.54-1.71 7.785 7.785 0 0 1 3.119-.631c1.106 0 2.146.21 3.12.63.973.42 1.82.99 2.54 1.71s1.29 1.566 1.71 2.54c.42.972.631 2.012.631 3.119 0 1.106-.21 2.146-.63 3.12a8.08 8.08 0 0 1-1.71 2.54 8.077 8.077 0 0 1-2.54 1.71c-.972.42-2.012.63-3.119.63ZM12 18.8c1.867 0 3.456-.656 4.768-1.969 1.313-1.312 1.969-2.901 1.969-4.768s-.656-3.456-1.969-4.768C15.456 5.982 13.867 5.326 12 5.326s-3.456.656-4.768 1.969c-1.313 1.312-1.969 2.901-1.969 4.768s.656 3.456 1.969 4.768C8.544 18.144 10.133 18.8 12 18.8Z"
    />
    <circle cx={12} cy={12.063} r={5.5} stroke="#292B2E" strokeWidth={0.5} />
    <path
      fill="#292B2E"
      fillRule="evenodd"
      d="M11.613 11.7c1.128.225 1.94.118 2.462-.32V9.188h.972v5.75h-.972V12.4c-.401.19-.872.284-1.418.284-.322 0-.67-.033-1.044-.1v2.354h-.973v-2.58a12.567 12.567 0 0 1-.39-.12c-.697-.224-1.592-.114-1.977.051l-.195-.856c.558-.193 1.553-.309 2.424-.028l.138.043v-2.26h.973v2.511Z"
      clipRule="evenodd"
    />
  </svg>
);
export default HolaCoinIcon;
