import { SVGProps } from "react";

const Monetization = (props: SVGProps<SVGSVGElement>) => (
  <svg
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M12.001 20a7.794 7.794 0 0 1-3.12-.63 8.083 8.083 0 0 1-2.54-1.71 8.077 8.077 0 0 1-1.71-2.54A7.785 7.785 0 0 1 4 12.002c0-1.106.21-2.146.63-3.12.42-.973.99-1.82 1.71-2.54a8.077 8.077 0 0 1 2.54-1.71A7.786 7.786 0 0 1 11.998 4c1.106 0 2.146.21 3.12.63.973.42 1.82.99 2.54 1.71s1.29 1.566 1.71 2.54c.42.972.631 2.012.631 3.119 0 1.106-.21 2.146-.63 3.12a8.08 8.08 0 0 1-1.71 2.54 8.077 8.077 0 0 1-2.54 1.71c-.972.42-2.012.631-3.119.631ZM12 18.737c1.88 0 3.474-.653 4.779-1.958 1.305-1.305 1.958-2.898 1.958-4.779 0-1.88-.653-3.474-1.958-4.779C15.474 5.916 13.881 5.263 12 5.263c-1.88 0-3.474.653-4.779 1.958C5.916 8.526 5.263 10.119 5.263 12c0 1.88.653 3.474 1.958 4.779 1.305 1.305 2.898 1.958 4.779 1.958Zm-.021-1.004a.552.552 0 0 0 .402-.173.552.552 0 0 0 .173-.402v-.446a3.711 3.711 0 0 0 1.774-.736c.53-.402.794-1.011.794-1.829 0-.59-.174-1.11-.521-1.564-.348-.454-1.027-.863-2.037-1.228-.886-.302-1.474-.564-1.764-.785-.29-.221-.436-.53-.436-.928 0-.397.15-.72.446-.968.298-.249.708-.373 1.232-.373.313 0 .587.059.822.176.235.117.433.273.595.469a.83.83 0 0 0 .356.262c.135.05.263.048.384-.006a.565.565 0 0 0 .337-.332.438.438 0 0 0-.045-.431 2.949 2.949 0 0 0-.836-.793 2.27 2.27 0 0 0-1.06-.359v-.445a.552.552 0 0 0-.171-.402.552.552 0 0 0-.403-.173.552.552 0 0 0-.402.173.552.552 0 0 0-.173.402v.445c-.718.139-1.27.43-1.654.874-.385.444-.577.938-.577 1.481 0 .633.191 1.142.574 1.527.384.386 1.008.734 1.874 1.045.895.328 1.506.622 1.834.883.328.26.492.61.492 1.05 0 .528-.19.912-.572 1.151-.381.24-.811.359-1.29.359-.4 0-.762-.1-1.085-.3a2.403 2.403 0 0 1-.812-.866.64.64 0 0 0-.345-.3.588.588 0 0 0-.425.006.562.562 0 0 0-.328.31.491.491 0 0 0-.002.439c.236.472.543.849.922 1.132.379.283.83.48 1.352.592v.488c0 .153.057.287.172.402.115.115.25.173.403.173Z"
    />
  </svg>
);
export default Monetization;
