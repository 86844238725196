import { Route, Routes } from "react-router-dom";
import ChangeEmailPage from "../pages/adminDashboard/ChangeEmail";
import { ActivationTopUp } from "../components/activationTopUp/ActivationTopUp";

import { RoutesNameAdmin } from "../interfaces";


export const HolaflyAdminRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path={RoutesNameAdmin.dashboard} element={<ActivationTopUp />} ></Route>
      <Route path={RoutesNameAdmin.changeEmail} element={<ChangeEmailPage />} ></Route>
    </Routes>
  );
};
