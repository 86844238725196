import { useState, useEffect } from "react";
import { ISlider } from "../../interfaces";
import { slidesDefault } from "./default";

import "./slide.css";

const styles = {
  active : 'opacity-100 fadeInRight',
  inactive : 'opacity-0 fadeOutLeft'
}


const Slider = ({ slides }: ISlider) => {
  const sliders = slides ? slides : slidesDefault;
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (activeIndex + 1) % sliders.length;
      setActiveIndex(nextIndex);
    }, 4000);
    return () => clearInterval(interval);
  }, [activeIndex, sliders.length]);

  return (
    <div className="relative w-[433px]  h-[670px]">
      {sliders.map((slide, index) => (
        <div
          key={slide.id}
          className={`absolute inset-0 flex justify-center items-center transition-opacity duration-[2000]
            ${index === activeIndex ? styles.active : styles.inactive}`}
        >
          <img
            draggable={false}
            className="w-full h-full"
            src={slide.image}
            alt={"not found"}
          />
          {slide.content}
        </div>
      ))}
    </div>
  );
};

export default Slider;
