import React from "react";
import { VideoPlayer } from "../../../components/video/VideoPlayer";
import { IVideoSectionProps } from "../../../interfaces";

export const VideoSection = ({activeTab, instructions}:IVideoSectionProps) => {
  const videoId =
    activeTab !== undefined
      ? instructions[activeTab].idVideo
      : instructions[0].idVideo;

  return (
    <div className="mt-6 h-[213px] w-full md:w-[446px] md:h-[335px] bg-slate-200 rounded-lg mb-5 text-center">
      {videoId && <VideoPlayer key={`video-${videoId}`} idVideo={videoId} />}
    </div>
  );
};
