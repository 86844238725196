import { useState, useEffect } from "react";
import { getByCustomerIccid } from "../services";
import {
  IEsimAdapterGeneric,
  IOrderEsim,
  IGetByCustomerIccid as IUseCustomerCard,
} from "../interfaces";
import { INITIAL_RESPONSE_BY_CUSTOMER_CARD } from "../constants";

export const useGetCustomerCard = ({
  iccid,
  isIncludeProvider = true,
}: IUseCustomerCard) => {
  const [data, setData] = useState<IEsimAdapterGeneric>(
    INITIAL_RESPONSE_BY_CUSTOMER_CARD
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [initIncludeProvider, setInitIncludeProvider] =
    useState(isIncludeProvider);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const fetchCustomerCard = async () => {
    setLoading(true);

    const { data: result, error } = await getByCustomerIccid(
      {
        iccid,
        isIncludeProvider: initIncludeProvider,
      },
      signal
    );

    setLoading(false);
    setData(result);
    setError(error);
  };

  useEffect(() => {
    fetchCustomerCard();

    return () => {
      abortController.abort();
    };
  }, [iccid, initIncludeProvider]);

  const updateIncludeProvider = (newIncludeProvider: boolean) => {
    setInitIncludeProvider(newIncludeProvider);
  };

  return { data, loading, error, updateIncludeProvider };
};
