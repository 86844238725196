export const CLASSNAME_CONTAIN_DEFAULT_CSNC =
  "bg-white info-card rounded-xl absolute inset-0 w-11/12 xs:w-4/5 -top-10 mx-auto h-40 z-10 px-8 border-gray-100";

/**
 * Initialization of class of the ItemSelector component
 */
export const BASE_CLASS_IS =
  "px-3 py-2 min-h-[70px] h-auto rounded-xl min-w-[188px] w-auto border flex flex-col";
export const SELECTED_CLASS_IS =
  "border-digital-green bg-digital-green-light cursor-pointer";
export const UNSELECTED_CLASS_IS = "border-gray-300 bg-white cursor-pointer";

/**
 * Initialization of styles generic
 */
export const GENERIC_STYLES = {
  standard:
    "bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold text-white hover:bg-gradient-to-l from-magenta to-magenta-900",
  default: "bg-neutral-200 text-neutral-400 ",
  outline:
    "rounded-xl px-5 py-3 border-2 flex items-center justify-center transition-all duration-300 h-btn w-full font-medium text-base text-neutral-charcoal",
  alertNotification:
    "mt-2 absolute p-5 rounded-xl z-10 mx-2 transition-all duration-300 animate-slideDown",
};

/**
 * Used for active tabs on TabsControl component
 */
export const DEFAULT_ACTIVE_STYLE =
  "border rounded-[40px] border-neutral-charcoal bg-white";
/**
 * Used for inactive tabs on TabsControl component
 */
export const DEFAULT_INACTIVE_STYLE = "border-none bg-transparent";