const prefix = "global.customerV2.travelClub";

export const items = Object.freeze([
  {
    id: 0,
    title: `${prefix}.menuTab.aboutClub`,
    path: `#about`,
    isCustomElement: false,
  },
  {
    id: 1,
    title: `${prefix}.landing.benefits.tab`,
    path: "#benefits",
    isCustomElement: false,
  },
  {
    id: 2,
    title: `${prefix}.landing.howToJoin.tab`,
    path: "#how-to-join",
    isCustomElement: false,
  },
  {
    id: 3,
    title: `${prefix}.landing.faqs.tab`,
    path: "#faqs",
    isCustomElement: false,
  },
]);
