import { mdiArrowLeft } from "@mdi/js";
import { t } from "i18next";
import HButton from "../../../components/common/hbutton/HButton";
import { LanguageSelector } from "../../../components/languageSelector/LanguageSelector";
import { MenuLanguage } from "../../../components/menus/MenuLanguage";
import { useCustomerStore } from "../../../store/customerStore";
import { IHelpCenterHeader, ICustomerStore } from "../../../interfaces";
import HolaflyLogo from "../../../holafly_logo.svg";
import Map from "../../../map.svg";
import GirlPcPhone from "../../../girl-pc-phone.svg";

export const HelpCenterHeader = ({
  handleGoBack,
}: IHelpCenterHeader): JSX.Element => {
  const { isOpenMenuLanguage, toggleMenuLanguage } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  return (
    <div className="px-2 z-1 h-[25rem] md:h-[41rem] lg:h-[33rem] w-full flex flex-col justify-start items-center">
      <img
        draggable={false}
        className="absolute opacity-50 -z-1 object-cover h-[184px] w-[286px]  md:w-[654px] md:h-[420px]"
        src={Map}
        alt="Not found"
      />

      <div className="2xl:w-[60%] 2xl:px-0 md:px-[100px] py-2  md:py-[54px] flex justify-between items-center w-full ">
        <HButton
          type="button"
          name="button go back"
          className="flex justify-center items-center h-12 w-12 rounded-xl border-[0.7px] border-neutral-300 hover:bg-neutral-200"
          icon={mdiArrowLeft}
          onClick={handleGoBack}
        />

        <img
          draggable={false}
          className="h-[25px] md:h-[30px]"
          src={HolaflyLogo}
          alt="logo_Holafly"
        />
        <MenuLanguage />
      </div>

      <div
        className="w-full lg:w-[77%] xl:w-[70%] 2xl:w-[60%] 2xl:justify-between 
      flex flex-col items-center lg:flex-row md:justify-center lg:gap-20 p-2">
        <div className="flex flex-col items-center lg:items-start md:justify-center max-w-lg">
          <h1 className="font-medium text-[34px] md:text-[48px] text-center mt-[40px]">
            {t("global.login.frequentDoubts.title")}
          </h1>
          <h3 className="font-medium text-lg md:text-[22px] text-center md:text-start">
            {t("global.login.frequentDoubts.description")}
          </h3>
        </div>
        <img
          draggable={false}
          className=" h-[200px] w-[200px] md:h-[398px] md:w-[398px]"
          src={GirlPcPhone}
          alt="girl_image"
        />
      </div>

      {isOpenMenuLanguage && (
        <LanguageSelector
          handleChangeVisible={() => toggleMenuLanguage(false)}
        />
      )}
    </div>
  );
};
