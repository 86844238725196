import { format } from "date-fns";
import { t } from "i18next";
import { IPropsIconTextStatus } from "../../interfaces/components/iconsText/IconTextStatusInterface";
import Circle from "../svg/circle/Circle";

export const IconTextStatus = ({
  color,
  status,
  initDate,
}: IPropsIconTextStatus) => {
  return (
    <div data-testid="icon-text-status" className="flex flex-row items-center">
      <Circle color={color} />
      <p data-testid="status-text" className="ml-2 text-[13px]">
        {t(`global.common.states.${status}.card_status`, {
          date: initDate && format(new Date(initDate), "dd-MM-yyyy"),
        })}
      </p>
    </div>
  );
};
