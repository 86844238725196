import { useTranslation } from "react-i18next";
import HButton from "../common/hbutton/HButton";
import { INotificationInternalErrorProps } from "../../interfaces";
import withoutConnection from "../../assets/invoice/no_connection.svg";

export const NotificationInternalError = ({
  onClick,
  title,
  description,
  textButton,
}: INotificationInternalErrorProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="notification-internal-error"
      className="flex flex-col justify-center items-center gap-4">
      <img
        src={withoutConnection}
        alt="no-connection"
        draggable={false}
        loading="lazy"
      />

      <h2 className="text-neutral-charcoal font-bold text-xl text-center">
        {t(title)}
      </h2>

      <p className="text-neutral-charcoal font-normal text-sm md:text-base text-center w-[260px]">
        {t(description)}
      </p>

      <HButton
        type="button"
        name="button try again connection"
        onClick={onClick}
        containerStyles="w-auto border border-neutral-300 rounded-xl hover:bg-neutral-200 "
        className="px-5 py-3 text-neutral-charcoal text-sm font-medium flex justify-center items-center"
        text={t(textButton)}
      />
    </div>
  );
};
