import CryptoJS from "crypto-js";

export const getAndDecryptLocalStorageData = <T>(key: string): T | null => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData) {
    const decryptedData = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_ENCRYPTION_KEY!
    ).toString(CryptoJS.enc.Utf8);

    return JSON.parse(decryptedData);
  }
  return null;
};
