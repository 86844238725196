import { mdiAlertOutline, mdiFaceAgent } from "@mdi/js";
import Icon from "@mdi/react";
import { t } from "i18next";
import { EsimCounter } from "./EsimCounter";
import { ICardAdviceProps } from "../../../interfaces";
import { Trans } from "react-i18next";
import { LinkText } from "../../../components/linkText/LinkText";

export const CardsAdvice = ({
  active,
  pending,
}: ICardAdviceProps): JSX.Element => {
  return (
    <div
      data-testid="card-advice-delete-account"
      className="gap-[24px] lg:w-[496px] w-full lg:h-[330px] bg-[#F8F8FB] rounded-[12px] p-[30px] flex flex-col">
      <article className="w-full flex gap-[3px] items-center">
        <Icon path={mdiAlertOutline} size={1} />
        <h4 className="font-medium">{t("global.customerV2.deleteAccount.cardAdvice.warningText")}</h4>
      </article>

      <article className="flex gap-[16px]">
        <EsimCounter count={active} cardType="active" />
        <EsimCounter count={pending} cardType="pending" />
      </article>

      <article className="text-[14px] font-normal w-full flex flex-col gap-3">
        <p>{t("global.customerV2.deleteAccount.cardAdvice.refundInfo")}</p>
        <p>
          <Trans
            i18nKey="global.customerV2.deleteAccount.cardAdvice.learnAbout.refundPolicy"
            components={{
              a: (
                <LinkText
                  to={t("global.footer.refundPolicyLink")}
                  className="font-bold underline cursor-pointer"
                />
              ),
            }}
          />
        </p>
      </article>

      <a
        target="_blank"
        rel="noreferrer"
        href={t("global.footer.contactUsLink")}
        className="gap-[8px] py-[12px] px-[20] hover:bg-[#F8F8FB] flex justify-center items-center h-[48px] w-full bg-[#FFF] border border-[#E4E6EA] rounded-[12px]">
        <Icon path={mdiFaceAgent} size={1} />
        <p className="text-[16px] font-medium">
          {t("global.customerV2.deleteAccount.cardAdvice.contactBtn")}
        </p>
      </a>
    </div>
  );
};
