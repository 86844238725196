import {
  getAndDecryptLocalStorageData,
  getLocalStorage,
  saveEncryptedLocalStorageData,
  saveLocalStorage,
} from "../utils";
import { getUserDetails, getUserReferralLink } from "../services";
import { initialCustomerProfile } from "../constants";
import { IUserStore } from "../interfaces/store/userStoreInterface";

export const createUserSlice = (set): IUserStore => ({
  customerId: getLocalStorage("customerId", "String") || "",
  setCustomerId: (customerId) => {
    saveLocalStorage("customerId", customerId, "String");
    set({ customerId });
  },
  emailLocalStorage: getAndDecryptLocalStorageData("email") || "",
  setEmailLocalStorage: (email) => {
    saveEncryptedLocalStorageData("email", email);
    set({ emailLocalStorage: email });
  },
  profile: initialCustomerProfile,
  setProfile: (profile) => set({ profile }),
  referralUser: getAndDecryptLocalStorageData("referralUser") || null,
  fetchReferralGetUser: (typeFilter, shouldGroupByMonth) =>
    getUserDetails(set, shouldGroupByMonth, typeFilter),
  referralLinkUser: null,
  fetchReferralLinkUser: () => getUserReferralLink(set),
  isModifiedDataAccount: false,
  setIsModifiedDataAccount: (isModifiedDataAccount) =>
    set({ isModifiedDataAccount }),
  clearReferralUser: () => set({ referralUser: null }),
  orderUser: null,
  setOrderUser: (orderUser) => set({ orderUser }),
});
