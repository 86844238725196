import { mdiTrayArrowDown } from "@mdi/js";
import { useTranslation } from "react-i18next";

import strokeHelpRight from "../../../components/svg/strokeHelpRight.svg";
import strokeHelpLeft from "../../../components/svg/strokeHelpLeft.svg";
import DownloadFileButton from "../../../components/common/hbutton/DownloadFileButton";


export const BannerDownloadInstructionsGuide = ({
  urlDownloadPdf,
}: {
  urlDownloadPdf: string;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="banner-download-instructions-guide"
      className="relative bg-brand-babyBlue-300 w-full h-[312px] py-20 px-4 md:px-[108px]"
    >
      <img
        draggable={false}
        className="absolute top-0 right-0"
        src={strokeHelpRight}
        alt="vector-stroke-right"
      />
      <img
        draggable={false}
        className="absolute bottom-0 left-0"
        src={strokeHelpLeft}
        alt="vector-stroke-left"
      />
      <div className="relative z-10 h-full flex flex-col justify-center items-center gap-4 md:gap-6">
        <h2 className="text-neutral-charcoal font-medium text-[22px] md:text-[28px] text-center">
          {t("global.login.helpCenter.sectionDownloadGuide.title")}
        </h2>

        <p className="text-neutral-charcoal font-bold text-base text-center">
          {t("global.login.helpCenter.sectionDownloadGuide.description")}
        </p>

        <DownloadFileButton
          text={t("global.login.helpCenter.sectionDownloadGuide.buttonText")}
          icon={mdiTrayArrowDown}
          url={urlDownloadPdf}
          fileName={t("global.header.instructions")}
        />
      </div>
    </div>
  );
};
