import { SVGProps } from "react";
const FacaIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    data-testid="face-icon"
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}>
    <path
      fill="#292B2E"
      d="M9.689 14.521c-.295 0-.544-.102-.747-.306a1.014 1.014 0 0 1-.306-.747c0-.294.102-.543.306-.746.203-.204.452-.306.747-.306s.544.102.747.306c.204.203.306.452.306.746 0 .295-.102.544-.306.747a1.014 1.014 0 0 1-.747.306Zm5.053 0c-.295 0-.544-.102-.747-.306a1.014 1.014 0 0 1-.306-.747c0-.294.102-.543.306-.746.203-.204.452-.306.747-.306.294 0 .543.102.747.306.203.203.305.452.305.746 0 .295-.102.544-.305.747a1.014 1.014 0 0 1-.747.306Zm-2.527 4.842c1.881 0 3.474-.652 4.78-1.958 1.304-1.305 1.957-2.898 1.957-4.779 0-.336-.02-.663-.063-.979a4.076 4.076 0 0 0-.232-.915c-.294.07-.589.123-.884.158a7.902 7.902 0 0 1-.926.052 8.242 8.242 0 0 1-3.621-.82 8.407 8.407 0 0 1-2.905-2.296 8.31 8.31 0 0 1-1.926 2.853A8.233 8.233 0 0 1 5.478 12.5v.126c0 1.881.653 3.474 1.958 4.78 1.306 1.305 2.899 1.957 4.78 1.957Zm0 1.263c-1.108 0-2.15-.21-3.126-.631a8.068 8.068 0 0 1-2.537-1.706 8.067 8.067 0 0 1-1.705-2.537 7.8 7.8 0 0 1-.632-3.126c0-1.108.21-2.15.632-3.126a8.07 8.07 0 0 1 1.705-2.537A8.067 8.067 0 0 1 9.09 5.258a7.8 7.8 0 0 1 3.126-.632c1.11 0 2.151.21 3.127.632a8.07 8.07 0 0 1 2.536 1.705A8.07 8.07 0 0 1 19.584 9.5c.42.975.631 2.018.631 3.126 0 1.11-.21 2.151-.631 3.126a8.068 8.068 0 0 1-1.706 2.537 8.071 8.071 0 0 1-2.536 1.706 7.807 7.807 0 0 1-3.127.631ZM10.678 6.08c.646 1.179 1.534 2.074 2.664 2.684 1.13.61 2.298.916 3.505.916.224 0 .446-.01.663-.032.218-.02.439-.06.663-.115a6.538 6.538 0 0 0-2.442-2.653 6.555 6.555 0 0 0-3.516-.99c-.28 0-.544.018-.79.053-.245.035-.494.08-.747.137ZM5.71 11.026c.8-.407 1.555-.99 2.264-1.747.708-.758 1.168-1.67 1.378-2.737a6.348 6.348 0 0 0-2.337 1.8 6.62 6.62 0 0 0-1.305 2.684Z"
    />
  </svg>
);
export default FacaIcon;
