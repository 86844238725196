import { useState } from "react";
import { useForm } from "./useForm";
import { AxiosIsError } from "../utils";
import { updateUserEmail } from "../services";
import { IResponseUseUpdatUserEmail } from "../interfaces";

const initialState = {
  oldEmail: "",
  newEmail: "",
};

export const useUpdateUserEmail = (): IResponseUseUpdatUserEmail => {
  const { oldEmail, newEmail, onChange, setForm } = useForm(initialState);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  const sendChangeEmail = async (): Promise<void> => {
    setIsLoading(true);

    const { isError, message } = await updateUserEmail(oldEmail, newEmail);

    if(isError){
      setIsLoading(false);
      setIsError(isError);
      setMessage(message);
      return
    }

    setIsLoading(false);
    setIsError(isError);
    setMessage(message);
    setForm(initialState);
  };
  return {
    sendChangeEmail,
    isLoading,
    isError,
    message,
    oldEmail,
    newEmail,
    onChange,
  };
};
