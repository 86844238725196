import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { UserProfile } from "./components/UserProfile";
import { EditableUserProfile } from "./components/EditableUserProfile";
import HButton from "../../components/common/hbutton/HButton";
import { AlertSuccess } from "./components/AlertSuccess";
import { AlertError } from "./components/AlertError";
import { ContentModal } from "../../components/modal/ContentModal";
import { LogoutConfirmationModal } from "./components/LogoutConfirmationModal";
import { FooterWave } from "../../components/footer/FooterWave";
import Header from "../../components/header/Header";
import Loader from "../../components/common/loader/Loader";
import { useGetCustomerProfile } from "../../hooks/useGetCustomerProfile";
import { useCustomerStore } from "../../store/customerStore";
import { LogoutOffset } from "../../components/svg";
import { ICustomerStore } from "../../interfaces";
import Map from "../../map.svg";
import { mdiTrashCanOutline } from "@mdi/js";
import { useCustomNavigate } from "../../hooks";

const AccountPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { profile, customerId } = useCustomerStore((store: ICustomerStore) => store);

  const [isModalLogout, setIsModalLogout] = useState<boolean>(false);

  const { loadProfileData, saveChangesProfile, loading, isError, isSuccess } =
    useGetCustomerProfile();

  useEffect(() => {
    loadProfileData();
  }, [customerId]);

  const handleShowLogoutModal = () => setIsModalLogout(!isModalLogout);

  const handleDeleteAccount = () => navigate("/account/delete");

  return (
    <>
      <Header />
      <main>
        <div
          data-testid="account-page"
          className="relative min-h-[80vh] md:min-h-[75vh] lg:h-[70%] px-2 w-full flex flex-col justify-start items-center">
          <img
            draggable={false}
            className="w-[80%] md:w-auto absolute opacity-50 -z-1 object-cover top-0 md:-top-16"
            src={Map}
            alt="Not found"
          />
          {isSuccess ? <AlertSuccess /> : <></>}

          {isError ? <AlertError /> : <></>}

          {loading ? (
            <div className=" min-h-[80vh] md:min-h-[75vh]  w-full flex justify-center items-center">
              <Loader className="loader border-[8px] w-24 h-24 mt-7" />
            </div>
          ) : (
            <section
              data-testid="section-account"
              className="mb-12 md:mb-40 p-4 w-full relative top-4 md:top-24 gap-6 flex flex-col items-center mt-[71px]">
              <UserProfile profile={profile} />

              {!loading && (
                <EditableUserProfile
                  saveData={saveChangesProfile}
                  initialState={profile}
                />
              )}

              <div className="w-full lg:w-[885px] flex flex-col justify-center items-center">
                <HButton
                  type="button"
                  name="button delete account"
                  onClick={handleDeleteAccount}
                  icon={mdiTrashCanOutline}
                  className="hover:bg-gray-50 cursor-pointer rounded-[12px] flex items-center gap-[8px] py-[12px] px-[20px] text-[14px] font-bold text-neutral-charcoal"
                  text={t("global.customerV2.deleteAccount.btnDelete")}
                />
                <p className="w-[185px] lg:w-auto text-[12px] font-normal text-center">
                  {t("global.customerV2.deleteAccount.descriptionDelete")}
                </p>
              </div>

              <HButton
                type="button"
                name="button open logout"
                className="w-full md:w-auto h-12  bg-white md:bg-transparent border border-neutral-300 rounded-xl  
                md:border-transparent border-none text-neutral-charcoal font-normal text-sm leading-5 flex justify-center 
                flex-row items-center  py-3 px-5"
                text={t("global.account.btnLogout")}
                onClick={handleShowLogoutModal}
                icon={<LogoutOffset color="black" />}
              />
            </section>
          )}
        </div>
      </main>
      <FooterWave />
      <ContentModal isModal={isModalLogout} toggleModal={handleShowLogoutModal}>
        <LogoutConfirmationModal closeModal={handleShowLogoutModal} />
      </ContentModal>
    </>
  );
};

export default AccountPage;
