import { useNavigate } from "react-router-dom";import { Link } from "react-router-dom";import { t } from "i18next";
import { mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { HelpCenterIcon, HolaCoinOutlineIcon } from "../../../../components/svg";
import { useCustomerStore } from "../../../../store/customerStore";
import { ICustomerStore } from "../../../../interfaces";
import logo from "../../../../assets/referrals/HolaLogo.svg";
import holaProfile from "../../../../assets/account/avatar.svg";
import holaPoints from "../../../../assets/referrals/HolaPoints.svg";

export const Passport = ():JSX.Element => {
  const navigate = useNavigate()
  const { referralUser, profile ,fetchReferralGetUser,clearReferralUser} = useCustomerStore((store: ICustomerStore) => store);

  return (
    <div className="relative md:mb-24 flex flex-col justify-center items-center  w-full">
      <div className="w-full md:flex md:justify-center">
        <div className="relative ">
          <img
            draggable={false}
            className="hidden lg:block w-[130px] h-[115px] absolute top-[140px] -left-[80px] overflow-visible z-10 "
            src={holaPoints}
            alt="holaPoints"
          />
          <div
            style={{
              boxShadow:
                "0px 0px 0.5px 0px rgba(66, 71, 76, 0.32), 0px 4px 8px 0px rgba(66, 71, 76, 0.05), 0px 4px 40px 0px #EEE",
            }}
            className="h-full overflow-hidden relative rounded-[12px] w-full md:w-[600px] md:h-[296px] bg-white flex flex-col "
          >
            <article
              style={{
                transform: "translateY(-50%)",
              }}
              className="absolute top-[50%] translate-y-0 bottom-0 -left-[30px] w-[60px] h-[60px] rounded-[50%] border  bg-[#F5F5F5]"
            />
            <article
              style={{
                transform: "translateY(-50%)",
              }}
              className=" absolute top-[50%] translate-y-0 bottom-0 -right-[30px] w-[60px] h-[60px] rounded-[50%] border bg-[#F5F5F5]"
            />

            <div className="w-full h-full flex p-[20px] items-center justify-between bg-gradient/roaming">
              <article className="flex gap-[8px]">
                <img draggable={false} src={logo} alt="logo" />
                <h1 className="text-[18px] font-medium">
                  {t("global.customerV2.travelClub.passport.title")}
                </h1>
              </article>
              <HelpCenterIcon
                className="cursor-pointer"
                onClick={() =>
                  navigate("/referralBoard/about-travel-club", {
                    state: { refIndex: 0 },
                  })
                }
              />
            </div>

            <article className="w-full  flex items-start gap-[20px] py-[20px] px-[40px]">
              <img
                src={holaProfile}
                alt="Avatar"
                draggable={false}
                width={62}
                height={62}
              />
              <article className="w-full flex flex-col gap-[20px]">
                <div className="flex flex-col md:flex-row w-full  md:justify-between  md:items-center">
                  <p className="text-[16px] font-bold">
                    {t("global.customerV2.travelClub.passport.yourHolacoins")}
                  </p>
                  <div className="flex gap-[8px]">
                    <HolaCoinOutlineIcon />
                    <p className="text-[22px] font-medium">
                      {referralUser?.points_balance || 0}
                    </p>
                  </div>
                </div>
                <hr className="border-dashed border-[#E4E6EA]" />
                <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                  <p className="text-[16px] font-bold mdfont-normal">
                    {t("global.customerV2.travelClub.passport.name")}
                  </p>
                  <p className="text-[16px] font-normal md:text-[22px] md:font-medium">{`${profile.firstName} ${profile.lastName}`}</p>
                </div>
              </article>
            </article>
            <article className="mt-auto w-full bg-[#F8FDFF]  py-[20px] flex justify-center items-center">
              <Link
                onClick={() => {
                  clearReferralUser();
                  fetchReferralGetUser("All", true);
                }}
                to={"/referralBoard/history"}
                className="cursor-pointer flex gap-1  items-center"
              >
                <p className="text-[14] font-normal">
                  {t("global.customerV2.travelClub.passport.history")}
                </p>
                <Icon path={mdiChevronRight} size={1} />
              </Link>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};
