import Icon from "@mdi/react";
import { mdiBellOutline, mdiClose, mdiTagOutline } from "@mdi/js";
import HButton from "../common/hbutton/HButton";

export const NotificationInfoModal = ({ onCloseModal }: any): JSX.Element => {
  return (
    <div
      data-testid="notification-info-modal"
      className="h-full overflow-y-auto flex items-center">
      <div className="my-[40px] relative bg-white mx-[30px] pt-6 pb-4 px-4 md:p-[30px] lg:p-[50px] md:w-[45rem] lg:w-[800px] rounded-xl">
        <button
          data-testid="close-modal"
          onClick={onCloseModal}
          className="absolute top-4 right-4 border border-neutral-300 hover:bg-neutral-200 rounded-full w-8 h-8 flex 
  justify-center items-center transition-all duration-300">
          <Icon path={mdiClose} size={0.8} />
        </button>
        <div className="flex flex-col gap-6">
          <h1 className="text-neutral-charcoal text-[22px] font-medium text-center">
            Notification info
          </h1>

          <div className="flex flex-col gap-3 justify-center">
            <div className="flex flex-row border border-neutral-300 rounded-xl p-[15px] gap-[10px]">
              <Icon
                className="hidden md:flex"
                path={mdiBellOutline}
                size={0.8}
              />
              <Icon className="md:hidden flex" path={mdiBellOutline} size={2} />

              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <p className="text-neutral-charcoal text-sm font-bold">
                    Usage notifications
                  </p>

                  <p className="text-neutral-charcoal text-sm font-normal">
                    Get alerts about your plan's status: data and call usage,
                    remaining days, top-ups, and more.
                  </p>
                </div>

                <p className="text-neutral-charcoal text-xs font-normal">
                  Always enabled
                </p>
              </div>
            </div>

            <div className="flex flex-row border border-neutral-300 rounded-xl p-[15px] gap-[10px]">
              <Icon
                className="hidden md:flex"
                path={mdiTagOutline}
                size={0.8}
              />
              <Icon className="md:hidden flex" path={mdiTagOutline} size={2} />

              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <p className="text-neutral-charcoal text-sm font-bold">
                    Promotion notifications
                  </p>

                  <p className="text-neutral-charcoal text-sm font-normal">
                    Alerts about promotions. Get the latest on discounts, gifts,
                    and more special offers just for you.
                  </p>
                </div>

                <p className="text-neutral-charcoal text-xs font-normal">
                  Always enabled
                </p>
              </div>
            </div>
          </div>

          <HButton
            type="button"
            name="button got it"
            onClick={onCloseModal}
            containerStyles="flex justify-center items-center"
            className="px-5 py-3 bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold
         text-white hover:bg-gradient-to-l rounded-xl w-full md:w-[200px]"
            text="Got it!"
          />
        </div>
      </div>
    </div>
  );
};
