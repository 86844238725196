import CopyToClipboard from "../../../../components/common/copyToClipboard/CopyToClipboard";

import { ICallLogDetailsProps } from "../../../../interfaces";
import { CallPhoneIn, CallPhoneOut } from "../../../../components/svg";
import { useTranslation } from "react-i18next";

export const CallLogDetail = ({
  numberPhone,
}: ICallLogDetailsProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="call-log"
      className="w-full flex flex-col rounded-[12px] gap-1 mt-[24px]">
      <div className="w-full h-48px bg-[#F8F8FB] p-[15px] rounded-t-[12px] flex flex-row justify-between items-center">
        <div className="flex flex-col w-full">
          <h3 className="text-[#292B2E] text-[16px] font-bold">
            {numberPhone}
          </h3>
          <p className="text-[14px] font-normal">
            {t("global.customerV2.details.active.textYourPhone")}
          </p>
        </div>
        <div className="flex items-center gap-3">
          <hr className="h-[12px] w-[1px] bg-neutral-300 rounded-sm" />
          <CopyToClipboard copyText={numberPhone} />
        </div>
      </div>
      <div className="w-full flex gap-1">
        <div className="flex bg-[#F8F8FB] w-full p-[15px] items-center gap-3 rounded-bl-[12px]">
          <CallPhoneOut />
          <div className="flex flex-col">
            <h3 className="text-[16px] font-bold">
              60 {t("global.customerV2.details.active.calls.minutes")}
            </h3>
            <p className="text-[14px] font-normal">
              {t("global.customerV2.details.active.calls.outgoing")}
            </p>
          </div>
        </div>
        <div className="flex bg-[#F8F8FB] w-full p-[15px] items-center gap-3 rounded-br-[12px]">
          <CallPhoneIn />
          <div className="flex flex-col">
            <h3 className="text-[16px] font-bold">
              60 {t("global.customerV2.details.active.calls.minutes")}
            </h3>
            <p className="text-[14px] font-normal">
              {t("global.customerV2.details.active.calls.incoming")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
