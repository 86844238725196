import { Fragment } from "react";
import { useCustomerStore } from "../../../store/customerStore";
import { ICustomerStore, IDataAndStatusDestination } from "../../../interfaces";

export const DestinationText = ({
  showStatusDestination,
  card,
}: IDataAndStatusDestination): JSX.Element => {
  const { language } = useCustomerStore((state: ICustomerStore) => state);

  return (
    <Fragment>
      {!showStatusDestination && (
        <p
          data-testid="destination_remaining_days"
          className="font-bold text-xl"
        >
          {card.destination ? card.destination[language] : ""}
        </p>
      )}
    </Fragment>
  );
};
