
export const useDataLayer = () => {

    const setDataLayer = (data) => {
        if (window.dataLayer && typeof window.dataLayer.push === 'function') {
            window.dataLayer.push(data);
        } else {
            console.error('Error: No se pudo acceder a window.dataLayer.push');
        }
    };

    return {
        dataLayer: window.dataLayer,
        setDataLayer: setDataLayer
    }
}
