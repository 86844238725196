import React from "react";
import CopyToClipboard from "../common/copyToClipboard/CopyToClipboard";
import { IChildren } from "../../interfaces";

export interface IContentCopyProps extends IChildren {
  textCopy: string;
  orientation: "UP" | "DOWN" | "NONE";
  bgContainer: string;
}

export const ContentCopy = ({
  children,
  textCopy,
  orientation,
  bgContainer,
}: IContentCopyProps): JSX.Element => {
  const styleOrientation = (): string => {
    switch (orientation) {
      case "UP":
        return "rounded-t-xl";

      case "DOWN":
        return "rounded-b-xl";

      case "NONE":
        return "rounded-xl";
    }
  };

  return (
    <div
      className={`flex flex-row items-center justify-between ${bgContainer} p-4 ${styleOrientation()}`}>
      {children}
      <div className="flex items-center gap-3">
        <hr className="h-[12px] w-[1px] bg-[#E4E6EA] rounded-sm" />
        <CopyToClipboard copyText={textCopy} />
      </div>
    </div>
  );
};
