import { API_URLS, initialUserProfile } from "../../../constants";
import { makeApiCall } from "../../apiCall";
import { IUserProfile } from "../../../interfaces";

export const postUpdatedUserProfile = async (id: string, body: any) => {
  const { URL_UPDATE_PROFILE_USER } = API_URLS;
  try {
    const { data } = await makeApiCall<IUserProfile>(
      "POST",
      URL_UPDATE_PROFILE_USER(id),
      body
    );

    return { data, loading: false, isCompleted: true, isError: false };
  } catch (error) {
    return {
      data: initialUserProfile,
      loading: false,
      isError: true,
      isCompleted: false,
    };
  }
};
