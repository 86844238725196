import { useEffect, useState } from "react";
import Icon from "@mdi/react";
import {
  mdiChevronDown,
  mdiChevronUp,
  mdiInformationOutline,
} from "@mdi/js";
import { t } from "i18next";

import { buyBundle } from "../../../../services/topup";
import HButton from "../../../../components/common/hbutton/HButton";
import { PackagePurchaseConfirmationModal } from "../PackagePurchaseConfirmationModal";
import { ContentModal } from "../../../../components/modal/ContentModal";
import { TopUpItem } from "./TopUpItem";
import { SkeletonAddData } from "./SkeletonAddData";
import CustomTooltip from "../../../../components/tooltip/CustomTooltip";

import { useCustomerStore } from "../../../../store/customerStore";
import { useScreen } from "../../../../hooks/useScreen";

import { IAddDattaProps, ICustomerStore, TopUp } from "../../../../interfaces";

import "../../../../styles/scrollbar.css";
import { TopupTooltipModal } from "./TopupTooltipModal";
import { getCurrencyData } from "../../../../utils/getCurrencyData";

const disableButton = "bg-[#E4E6EA] text-neutral-500";

const activeButton =
  "cursor-pointer bg-digital-green hover:bg-brand-green-500 active:bg-semantic-success-400 hover:text-white";

export const AddDatta = ({
  topups,
  cardDetails,
  loadingTopUps,
  haveNextPackages,
}: IAddDattaProps): JSX.Element => {
  const screen = useScreen();
  const [isModalTopUp, setIsModalTopUp] = useState<boolean>(false);
  const [isModalInfo, setIsModalInfo] = useState<boolean>(false);
  const [isPendingToupupModal, setIsPendingTopupModal] =
    useState<boolean>(false);

  const [showMore, setShowMore] = useState(false);

  const { language: lang, currency } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  const [item, setItem] = useState({} as TopUp);

  const buyTopup = async (bundleSelected: any) => {
    buyBundle(
      cardDetails,
      bundleSelected,
      getCurrencyData(currency).geolocation,
      lang.toUpperCase()
    );
  };

  const toggleModalTopUp = () => setIsModalTopUp(!isModalTopUp);
  const [animation, setAnimation] = useState("");

  const handleSetMoreItems = () => {
    setShowMore(!showMore);
    setAnimation("animate-fadeOutIn");
  };

  const handleClick = (item: TopUp) => {
    toggleModalTopUp();
    setItem(item);
  };

  const [currentTopUp, setCurrentTopUp] = useState<TopUp>();

  const handleChangeCurrentTop = (item: TopUp) => {
    setCurrentTopUp(item);
  };

  useEffect(() => {
    const timeIntervalAnimation = setTimeout(() => {
      setAnimation("");
    }, 800);

    return () => clearTimeout(timeIntervalAnimation);
  }, [showMore, setAnimation]);

  useEffect(() => {
    screen >= 1024 && setIsModalInfo(false);
  }, [screen]);

  return (
    <>
      {topups && (
        <div className="h-full border border-[#E4E6EA] rounded-[12px] p-[30px] min-h-[398px] w-full lg:w-[496px]">
          <div className="flex justify-between items-center w-full">
            <h3 id="topups" className="text-[20px] font-bold">
              {t("global.customerV2.details.active.textAddMoreData")}
            </h3>

            <div className="flex md:flex-row lg:flex-co">
              <div
                className="tooltip-active"
                onClick={() => {
                  if (screen <= 768) {
                    setIsModalInfo(true);
                  }
                }}
              >
                <Icon
                  className=" cursor-pointer transition duration-300 ease-in-out"
                  path={mdiInformationOutline}
                  size={1}
                />
              </div>

              <CustomTooltip
                className="custom-tooltip relative lg:-top-8 md:right-36 xl:-top-[105px] xl:right-2"
                text={t("global.customerV2.details.active.tooltips.two")}
                arrowPosition={screen >= 1440 ? "bottom" : "right"}
              />
            </div>
          </div>

          {loadingTopUps ? (
            <div className="mt-6">
              <SkeletonAddData num={4} />
            </div>
          ) : (
            <>
              <div
                style={{
                  flexDirection: topups.length >= 4 ? "row" : "column",
                }}
                className={`${animation} w-full h-full mt-[24px] flex flex-wrap p-0 items-start gap-[8px]`}
              >
                {!showMore
                  ? topups
                      .slice(0, 6)
                      .map((item, index) => (
                        <TopUpItem
                          key={`${item.id}-${index}`}
                          isActive={item.id === currentTopUp?.id}
                          isFullWidth={topups.length >= 4 ? false : true}
                          data={item}
                          handleChangeActive={handleChangeCurrentTop}
                        />
                      ))
                  : topups.map((item, index) => (
                      <TopUpItem
                        key={`${item.id}-${index}`}
                        isActive={item.id === currentTopUp?.id}
                        data={item}
                        isFullWidth={topups.length >= 4 ? false : true}
                        handleChangeActive={handleChangeCurrentTop}
                      />
                    ))}
              </div>
              {topups.length > 6 && (
                <div
                  onClick={handleSetMoreItems}
                  className="my-[20px] mx-auto flex gap-[8px] max-w-[200px] justify-center items-end cursor-pointer"
                >
                  <p className="text-[16px] font-medium select-none">
                    {showMore
                      ? t(
                          "global.customerV2.details.active.buttons.buttonShowLessPlans"
                        )
                      : t(
                          "global.customerV2.details.active.buttons.buttonShowMorePlans"
                        )}
                  </p>
                  <Icon
                    path={showMore ? mdiChevronUp : mdiChevronDown}
                    size={1}
                  />
                </div>
              )}
              {topups.length >= 1 && (
                <>
                  <HButton
                    type="button"
                    name="button buy package"
                    className={`${
                      !currentTopUp || haveNextPackages
                        ? disableButton
                        : activeButton
                    }  min-h-[48px] px-5 py-3 font-medium rounded-[14px] cursor-pointer`}
                    containerStyles={`${
                      haveNextPackages ? "tooltip-active" : ""
                    } w-full md:min-w-[197px] md:max-w-[130px] mx-auto mt-[10px]`}
                    text={t("global.customerV2.buttons.buttonBuyPackage")}
                    disabled={!currentTopUp || haveNextPackages}
                    onClick={() => {
                      if (haveNextPackages && screen <= 768) {
                        setIsPendingTopupModal(true);
                        return;
                      }

                      handleClick(currentTopUp!);
                    }}
                  />

                  {haveNextPackages ? (
                    <CustomTooltip
                      className="custom-tooltip mt-4 absolute right-[calc(34%-100px)]"
                      text={t("global.customerV2.details.active.tooltips.one")}
                      arrowPosition="top"
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}

      <ContentModal isModal={isModalTopUp} toggleModal={toggleModalTopUp}>
        <PackagePurchaseConfirmationModal
          data={cardDetails}
          topup={item}
          onClick={() => buyTopup(item)}
          toggleModal={toggleModalTopUp}
        />
      </ContentModal>

      <ContentModal
        isModal={isModalInfo}
        toggleModal={() => setIsModalInfo(false)}
      >
        <TopupTooltipModal
          closeModal={() => setIsModalInfo(false)}
          text={t("global.customerV2.details.active.tooltips.two")}
        />
      </ContentModal>

      {haveNextPackages ? (
        <ContentModal
          isModal={isPendingToupupModal}
          toggleModal={() => setIsPendingTopupModal(false)}
        >
          <TopupTooltipModal
            closeModal={() => setIsPendingTopupModal(false)}
            text={t("global.customerV2.details.active.tooltips.one")}
          />
        </ContentModal>
      ) : (
        <></>
      )}
    </>
  );
};
