import { Fragment, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { mdiDownload } from "@mdi/js";
import { AlertNotification } from "../../../../components/alert/AlertNotification";
import HButton from "../../../../components/common/hbutton/HButton";
import { ContentModal } from "../../../../components/modal/ContentModal";
import { InvoiceRequestModal } from "../modals/InvoiceRequestModal";
import { useCustomerStore } from "../../../../store/customerStore";
import { ArrowEast, UserSupport } from "../../../../components/svg";
import { useScreen, useTimeout, useDeliveryInvoice } from "../../../../hooks";
import { ICustomerStore, ISupportInvoice } from "../../../../interfaces";

export const SupportInvoice = ({
  onClick,
  isLoadingPdf,
  order,
  refreshOrder,
}: ISupportInvoice): JSX.Element => {
  const { t } = useTranslation();
  const screen = useScreen();
  const [showAlert, resetAlert] = useTimeout(4000);
  const { setHasSeenInvoiceRequest } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  const { message, isError, isSuccess, sendDeliverInvoice } =
    useDeliveryInvoice();
  const [openModal, setOpenModal] = useState(false);

  const { isInvoiceExists, orderName, orderEmail } = order;

  const handleToggleModal = () => {
    if (!isInvoiceExists) {
      if (screen < 1024) {
        setHasSeenInvoiceRequest(true);
      }
      setOpenModal(!openModal);

      return;
    }
    sendDeliverInvoice(orderName);
  };

  const backgroundStyles = isError
    ? "bg-semantic-error-100"
    : "bg-semantic-success-100";

  return (
    <div
      data-testid="support-invoice"
      className="flex flex-col gap-6 mt:0 md:mt-6 justify-center items-center"
    >
      <HButton
        type="button"
        name="button download receipt invoice"
        onClick={onClick}
        containerStyles="h-12 min-w-[220px] w-auto mx-auto rounded-xl bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold transition-all duration-300"
        className="font-medium text-base text-white flex flex-row-reverse items-center justify-around px-5 py-3 gap-2"
        icon={
          isLoadingPdf ? (
            <svg
              data-testid="loading-icon"
              className="animate-spin -ml-1 mr-4 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            mdiDownload
          )
        }
        iconColor="white"
        text={t("global.customerV2.invoice.details.textButtonReceipt")}
        disabled={isLoadingPdf}
      />

      <div className="flex flex-col justify-center items-center gap-3">
        {order.isInvoiceExists ? (
          <Fragment>
            <h2 className="text-neutral-charcoal text-base font-normal text-center">
              <Trans
                i18nKey={
                  "global.customerV2.invoice.requestForm.sent.description"
                }
                components={{
                  span: <span className="font-medium" />,
                }}
                values={{ email: orderEmail }}
              />
            </h2>

            <HButton
              name="button resend request invoice"
              type="button"
              containerStyles="border border-neutral-300 rounded-xl min-w-[130px] bg-white hover:bg-neutral-200"
              className="py-3 px-5 text-neutral-charcoal font-medium flex items-center justify-center flex-row-reverse gap-2"
              text={t(
                "global.customerV2.invoice.requestForm.sent.resend.again.cta"
              )}
              icon={<ArrowEast />}
              onClick={handleToggleModal}
            />
          </Fragment>
        ) : (
          <Fragment>
            <div className="flex flex-row items-center gap-2 justify-center">
              <UserSupport />

              <h2 className="text-neutral-charcoal text-base font-bold">
                {t("global.customerV2.invoice.details.supportInvoice.title")}
              </h2>
            </div>

            <p className="text-neutral-charcoal text-base font-normal text-center">
              {t(
                "global.customerV2.invoice.details.supportInvoice.description"
              )}
            </p>

            <HButton
              name="button request invoice"
              type="button"
              containerStyles="border border-neutral-300 rounded-xl min-w-[130px] bg-white hover:bg-neutral-200"
              className="py-3 px-5 text-neutral-charcoal font-medium flex items-center justify-center flex-row-reverse gap-2"
              text={t(
                "global.customerV2.invoice.details.button.requestInvoice"
              )}
              icon={<ArrowEast />}
              onClick={handleToggleModal}
            />
          </Fragment>
        )}
      </div>
      <ContentModal
        direction="RIGHT"
        isModal={openModal}
        toggleModal={() => {}}
      >
        <InvoiceRequestModal
          refreshOrder={refreshOrder}
          closeModal={handleToggleModal}
        />
      </ContentModal>

      {(isSuccess || isError) && message && (
        <AlertNotification
          alertType={isError ? "error" : "success"}
          resetAlert={resetAlert}
          message={message}
          isVisible={showAlert}
          customClassName={`${backgroundStyles} mt-20 fixed p-5 rounded-xl z-10 mx-2 transition-all 
          duration-300 animate-slideDown flex self-center w-[90%] md:w-[62%]`}
        />
      )}
    </div>
  );
};
