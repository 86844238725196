import { useNavigate } from "react-router-dom";
import { mdiBookOpenPageVariantOutline, mdiQrcodeScan } from "@mdi/js";
import HButton from "../../../../components/common/hbutton/HButton";
import { useCustomerStore } from "../../../../store/customerStore";
import { IOrderEsim, ICustomerStore } from "../../../../interfaces";
import { CallDown, ConnectionWifi } from "../../../../components/svg";
import iosPending from "../../../../assets/details/IosPending.svg";
import pendingTablet from "../../../../assets/details/pending_tablet.svg";
import PhoneIos from "../../../../assets/details/PhoneIos.svg";
import { QrImage } from "../../../../components/qrCode/QrImage";
import { InstallCode } from "../../../../components/installCode/InstallCode";
import Icon from "@mdi/react";
import { ActiveEsimSuggestion } from "./ActiveEsimSuggestion";
import { suggestion } from "./tabsOptions";
import { t } from "i18next";
import { Trans } from "react-i18next";

export const InstructionsPendingLite = ({
  cardDetails,
  isIos,
}: {
  cardDetails: IOrderEsim;
  isIos: boolean;
}): JSX.Element => {
  const navigate = useNavigate();

  const { customerId } = useCustomerStore((store: ICustomerStore) => store);

  const hanldeNavigateHelpCenter = () =>
    navigate("/helpCenter", {
      state: { prevRoute: "details", data: cardDetails, id: cardDetails.id },
    });
  return (
    <section data-testid="instructions-pending" className="flex flex-col gap-6">
      {isIos && (
        <div className="relative flex flex-row justify-center items-center gap-4">
          <img
            className="sm:hidden lg:inline-block"
            src={iosPending}
            alt="ios_banner"
          />
          <img
            className="w-full lg:hidden sm:inline-block"
            src={pendingTablet}
            alt="ios_banner"
          />

          <p className="text-center absolute lg:left-[250px] max-w-[500px]">
            <Trans
              i18nKey={"global.OneButInst_Automatic_warning_goToMobile"}
              components={{
                strong: <strong />,
              }}
            />
          </p>
        </div>
      )}

      <div className="border border-neutral-300 rounded-xl px-4 pb-4 pt-6 md:p-[30px] flex flex-col gap-6">
        <div className="flex flex-row gap-[10px] justify-center items-center">
          <CallDown />
          <h3
            data-testid="install-before"
            className="font-bold text-base md:text-xl text-neutral-charcoal"
          >
            1.{" "}
            {t("global.customerV2.details.pending.textInstallBeforeYourTrip")}
          </h3>
        </div>

        <div className="flex flex-col lg:flex-row gap-6 justify-center">
          <div className="flex flex-col p-[30px] border-[#E4E6EA] border bg-white w-full lg:w-[466px] gap-6 rounded-xl">
            <article className="flex w-full justify-center items-center gap-2">
              <Icon path={mdiQrcodeScan} size={1} />
              <h4
                data-testid="activation-code-title"
                className="text-center font-bold text-base text-neutral-charcoal"
              >
                {t(
                  "global.login.helpCenter.sectionInstructions.tabsCard.second"
                )}
              </h4>
            </article>

            {isIos && (
              <p className="text-center text-[16px] font-normal">
                <Trans
                  i18nKey={"global.InstallOption_QRCode_longPress"}
                  components={{
                    strong: <strong />,
                  }}
                />
              </p>
            )}
            {!isIos && (
              <p className="text-center text-[16px] font-normal">
                <Trans
                  i18nKey={"global.InstallOption_QRCode_android"}
                  components={{
                    strong: <strong />,
                  }}
                />
              </p>
            )}

            <QrImage customerId={customerId} cardId={cardDetails.id || ""} />
            {isIos && (
              <p className="text-center text-[16px] font-normal">
                <Trans
                  i18nKey={"global.InstallOption_QRCode_scanQR"}
                  components={{
                    strong: <strong />,
                  }}
                />
              </p>
            )}
          </div>
          <div className="flex flex-col p-4 md:p-[30px] border-[#E4E6EA] border bg-white w-full lg:w-[466px] gap-4 md:gap-6 self-stretch rounded-xl">
            <article className="flex items-center gap-2 w-full justify-center">
              <img src={PhoneIos} alt="phone-ios" />
              <h4
                data-testid="installation-code-title"
                className="text-center font-bold text-base text-neutral-charcoal"
              >
                {t(
                  "global.customerV2.details.pending.sectionInstructions.installationCode.title"
                )}
              </h4>
            </article>

            <p className="text-center text-[16px] ">
              <Trans
                i18nKey={"global.InstallOption_manualCodes_description"}
                components={{
                  strong: <strong />,
                }}
              />
            </p>

            <div className="flex flex-col bg-[#F8F8FB] p-[16px] rounded-xl w-full gap-[2px] justify-between">
              <InstallCode
                bgContainerCard="bg-white"
                customerIdCard={cardDetails.customer_id || ""}
                iccidCard={cardDetails.iccid}
              />
            </div>
          </div>
        </div>
        <HButton
          type="button"
          name="button see installation guide"
          containerStyles="min-w-[215px] mx-auto hover:bg-red-300 rounded-xl  bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold transition-all duration-300"
          text={t(
            "global.customerV2.details.pending.buttons.buttonInstallationGuides"
          )}
          className="h-12 flex items-center justify-center px-5 py-3 text-white gap-2 border-none font-normal text-base"
          icon={mdiBookOpenPageVariantOutline}
          iconColor="white"
          onClick={hanldeNavigateHelpCenter}
        />
      </div>

      <div className="border border-neutral-300 rounded-xl px-4 pb-4 pt-6 md:p-[30px] flex flex-col gap-6">
        <div className="flex flex-row gap-[10px] justify-center items-center">
          <ConnectionWifi />
          <h3
            data-testid="activation-destination-title"
            className="font-bold text-base md:text-xl text-neutral-charcoal"
          >
            {t(
              "global.customerV2.details.pending.sectionActivateDestination.title"
            )}
          </h3>
        </div>
        <article className="w-full flex justify-center">
          <p
            data-testid="activation-destination-description"
            className="text-center text-base font-normal text-neutral-charcoal md:max-w-[820px]"
          >
            {t("global.customerV2.details.pending.sectionActivate.description")}
          </p>
        </article>

        <ActiveEsimSuggestion suggestions={suggestion} />

        <HButton
          type="button"
          name="button see activation guide"
          containerStyles="min-w-[215px] mx-auto hover:bg-red-300 rounded-xl  bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold transition-all duration-300"
          text={t(
            "global.customerV2.details.pending.buttons.buttonActivationGuides"
          )}
          className="h-12 flex items-center justify-center px-5 py-3 text-white gap-2 border-none font-normal text-base"
          icon={mdiBookOpenPageVariantOutline}
          iconColor="white"
          onClick={hanldeNavigateHelpCenter}
        />
      </div>
    </section>
  );
};
