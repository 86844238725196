import { useState, useEffect, useRef } from "react";

export const useTimeout = (hideTime: number): [boolean, () => void] => {
  const [showAlert, setShowAlert] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShowAlert(false);
  };

  const startTimeout = () => {
    timeoutRef.current = setTimeout(() => {
      setShowAlert(false);
    }, hideTime);
  };

  const handleClick = () => {
    resetTimeout();
    setShowAlert(true);
    startTimeout();
  };

  useEffect(() => {
    return () => {
      resetTimeout();
    };
  }, []);

  return [showAlert, handleClick];
};
