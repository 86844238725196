import { SVGProps } from "react";

const ChatSvg = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    data-testid="chat-svg"
    xmlns="http://www.w3.org/2000/svg"
    width={353}
    height={211}
    fill="none"
    {...props}>
    <path
      fill="#292B2E"
      stroke="#292B2E"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M79.473 45.816h239.036a6 6 0 0 1 6 6v79.326a6 6 0 0 1-6 6h-33.856v26.999h-8.012l-20.273-26.999H79.473V45.816Z"
    />
    <path
      fill="#fff"
      d="M71.304 51.816a6 6 0 0 1 6-6H310.34a6 6 0 0 1 6 6v79.326a6 6 0 0 1-6 6h-33.856v26.999l-28.285-26.999H77.304a6 6 0 0 1-6-6V51.816Z"
    />
    <path
      stroke="#292B2E"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M71.304 51.816a6 6 0 0 1 6-6H310.34a6 6 0 0 1 6 6v79.326a6 6 0 0 1-6 6h-33.856v26.999l-28.285-26.999H77.304a6 6 0 0 1-6-6V51.816Z"
    />
    <path
      stroke="#292B2E"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M70.932 91.117H42.4a6 6 0 0 0-6 6v53.334a6 6 0 0 0 6 6h33.856v26.999l28.285-26.999h98.099a6 6 0 0 0 6-6v-13.434"
    />
    <path
      fill="#fff"
      d="M123.211 7.285a6 6 0 0 0-6-6H45.177a6 6 0 0 0-6 6v8.724a6 6 0 0 0 6 6h7.166v8.981l9.344-8.98h55.524a6 6 0 0 0 6-6V7.284Z"
    />
    <path
      stroke="#292B2E"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M123.211 7.285a6 6 0 0 0-6-6H45.177a6 6 0 0 0-6 6v8.724a6 6 0 0 0 6 6h7.166v8.981l9.344-8.98h55.524a6 6 0 0 0 6-6V7.284Z"
    />
    <path
      fill="#292B2E"
      d="M106.399 10.63h3.828l1.178-3.64 1.185 3.64 3.828-.007-3.096 2.252 1.186 3.64-3.096-2.253-3.096 2.252 1.179-3.64-3.096-2.244ZM91.26 10.63h3.827l1.178-3.64 1.186 3.64 3.827-.007-3.095 2.252 1.185 3.64-3.096-2.253-3.096 2.252 1.179-3.64-3.096-2.244ZM76.12 10.63h3.827l1.179-3.64 1.185 3.64 3.828-.007-3.096 2.252 1.185 3.64-3.095-2.253-3.096 2.252 1.178-3.64-3.096-2.244ZM60.98 10.63h3.828l1.178-3.64 1.185 3.64L71 10.623l-3.096 2.252 1.186 3.64-3.096-2.253-3.096 2.252 1.179-3.64-3.096-2.244ZM45.84 10.63h3.828l1.178-3.64 1.186 3.64 3.827-.007-3.095 2.252 1.185 3.64-3.096-2.253-3.095 2.252 1.178-3.64-3.096-2.244Z"
    />
    <path
      fill="#fff"
      d="M168.861 186.373a6 6 0 0 1 6-6h72.034a6 6 0 0 1 6 6v8.724a6 6 0 0 1-6 6h-7.166v8.981l-9.344-8.981h-55.524a6 6 0 0 1-6-6v-8.724Z"
    />
    <path
      stroke="#292B2E"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M168.861 186.373a6 6 0 0 1 6-6h72.034a6 6 0 0 1 6 6v8.724a6 6 0 0 1-6 6h-7.166v8.981l-9.344-8.981h-55.524a6 6 0 0 1-6-6v-8.724Z"
    />
    <path
      fill="#292B2E"
      d="M185.672 189.718h-3.827l-1.179-3.64-1.185 3.64-3.828-.007 3.096 2.252-1.185 3.639 3.095-2.252 3.096 2.252-1.178-3.639 3.095-2.245ZM200.812 189.718h-3.828l-1.178-3.64-1.185 3.64-3.828-.007 3.096 2.252-1.186 3.639 3.096-2.252 3.096 2.252-1.179-3.639 3.096-2.245ZM215.952 189.718h-3.828l-1.178-3.64-1.186 3.64-3.827-.007 3.095 2.252-1.185 3.639 3.096-2.252 3.095 2.252-1.178-3.639 3.096-2.245ZM231.092 189.718h-3.828l-1.178-3.64-1.185 3.64-3.828-.007 3.095 2.252-1.185 3.639 3.096-2.252 3.096 2.252-1.179-3.639 3.096-2.245ZM246.232 189.718h-3.828l-1.179-3.64-1.185 3.64-3.828-.007 3.096 2.252-1.185 3.639 3.095-2.252 3.096 2.252-1.178-3.639 3.096-2.245Z"
    />
    <path
      fill="#FFE3D3"
      stroke="#292B2E"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M103.416 88.703c7.072 0 12.804-5.777 12.804-12.903 0-7.127-5.732-12.903-12.804-12.903-7.071 0-12.804 5.776-12.804 12.903 0 7.126 5.733 12.903 12.804 12.903Z"
    />
    <path
      stroke="#292B2E"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M100.571 73.453a3.083 3.083 0 0 0-1.006-.771 2.78 2.78 0 0 0-1.192-.272c-.41 0-.815.093-1.193.272a3.09 3.09 0 0 0-1.006.771M110.659 73.453a3.089 3.089 0 0 0-1.006-.771 2.78 2.78 0 0 0-1.192-.272c-.41 0-.815.093-1.193.272-.377.179-.72.441-1.006.771"
    />
    <path
      stroke="#292B2E"
      d="M90.612 103.242h20.051M118.832 103.242h92.086M90.612 115.867h63.124M162.647 115.867h63.124M233.94 115.867h63.123"
    />
    <circle
      cx={320.358}
      cy={85.979}
      r={25.621}
      fill="#fff"
      stroke="#292B2E"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="rotate(17.38 320.358 85.979)"
    />
    <path
      fill="#292B2E"
      d="M319.781 95.875c.512 0 .941-.178 1.288-.534a1.79 1.79 0 0 0 .518-1.292 1.79 1.79 0 0 0-.518-1.292 1.725 1.725 0 0 0-1.288-.535c-.541 0-.985.179-1.33.535-.347.356-.52.787-.52 1.292 0 .504.173.935.52 1.291.345.357.789.535 1.33.535Zm1.31-7.572c.06-.505.218-.95.474-1.337.256-.386.714-.89 1.376-1.514.993-.98 1.678-1.797 2.055-2.45.376-.653.564-1.396.564-2.227 0-1.455-.519-2.636-1.557-3.542-1.039-.905-2.341-1.358-3.906-1.358-1.203 0-2.272.252-3.205.757-.933.505-1.64 1.158-2.121 1.96a1.19 1.19 0 0 0-.159 1.025c.106.356.324.594.655.712.301.149.609.156.925.022.316-.133.565-.334.745-.6a3.088 3.088 0 0 1 1.243-1.114c.526-.268 1.12-.401 1.782-.401.993 0 1.753.252 2.281.757.526.505.789 1.129.789 1.87 0 .565-.173 1.13-.519 1.694-.346.564-.836 1.128-1.468 1.692-.812.713-1.421 1.41-1.827 2.094-.407.683-.61 1.336-.61 1.96 0 .326.12.6.361.823.241.223.526.335.858.335.331 0 .609-.112.836-.335.225-.222.367-.497.428-.823Z"
    />
    <circle
      cx={21.919}
      cy={129.014}
      r={17.452}
      fill="#fff"
      stroke="#292B2E"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="rotate(-14.932 21.919 129.014)"
    />
    <path
      fill="#292B2E"
      stroke="#292B2E"
      strokeLinecap="square"
      strokeMiterlimit={10}
      d="M24.667 125.209a2.362 2.362 0 0 0-.992-.323 2.316 2.316 0 0 0-1.89.718 2.392 2.392 0 0 0-.533.904 2.383 2.383 0 0 0-.856-.501c-.316-.105-.648-.14-.977-.105a2.307 2.307 0 0 0-1.65.978 2.403 2.403 0 0 0-.39.915 2.474 2.474 0 0 0 1.147 2.597l4.473 2.668 2.55-4.541c.154-.274.253-.577.292-.891a2.476 2.476 0 0 0-1.174-2.419Z"
    />
  </svg>
);
export default ChatSvg;
