import { ILoadingInfoItemSkeleton } from "../../../../interfaces";

export const LoadingInfoItemSkeleton = ({
  label,
}: ILoadingInfoItemSkeleton): JSX.Element => (
  <div
    data-testid="loading-info-item-skeleton"
    className="flex flex-row justify-between">
    <h5 className="text-sm font-normal text-neutral-600">{label}</h5>
    <p
      className={`w-[218px] h-6 bg-neutral-300 animate animate-pulse rounded-lg`}
    />
  </div>
);
