import { useTranslation } from "react-i18next";
import { ContainerCardInvoice } from "./ContainerCardInvoice";
import { IInvoiceDetailCard } from "../../../../interfaces";
import { DropdownDetailPay } from "../DropdownDetailPay";
import { Divider } from "../../../../components/common/divider/Divider";

export const InvoiceDetailCard = ({
  order,
}: IInvoiceDetailCard): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ContainerCardInvoice>
      <div data-testid="invoice-detail-card" className="flex flex-col gap-6">
        <div className="flex flex-row justify-between items-center">
          <h2 className="text-neutral-charcoal text-base font-bold">
            {t("global.customerV2.invoice.details.products")}
          </h2>

          <p className="text-neutral-charcoal text-base font-bold">
            {t("global.customerV2.invoice.details.amount", {
              currency: order.currencyCode,
            })}
          </p>
        </div>

        <Divider className="bg-neutral-300 h-[1px] w-full" />

        <div className="flex flex-col gap-2">
          {order.lineItems &&
            order.lineItems.map((item, index) => {
              return (
                <DropdownDetailPay
                  key={index}
                  data={item}
                  currencySymbol={order.currencySymbol}
                />
              );
            })}
        </div>

        <Divider className="bg-neutral-300 h-[1px] w-full" />

        <div className="flex flex-col gap-4 md:gap-6">
          <div className="flex flex-row justify-between items-center">
            <p className="text-neutral-charcoal text-sm font-normal">
              {t(
                "global.customerV2.invoice.details.invoiceDetailCard.textFieldSubtotal"
              )}
            </p>
            <p className="text-neutral-charcoal text-base font-normal">
              {order.currencySymbol}
              {order.subTotal} {order.currencyCode}
            </p>
          </div>

          <div className="flex flex-row justify-between items-center">
            <p className="text-neutral-charcoal text-sm font-normal">
              {t(
                "global.customerV2.invoice.details.invoiceDetailCard.textFieldDiscount"
              )}
            </p>
            <p className="text-neutral-charcoal text-base font-normal">
              -{order.currencySymbol}
              {order.totalDiscount} {order.currencyCode}
            </p>
          </div>

          <div className="flex flex-row justify-between items-center">
            <p className="text-neutral-charcoal text-sm font-normal">
              {t(
                "global.customerV2.invoice.details.invoiceDetailCard.textFieldTaxes"
              )}
            </p>
            <p className="text-neutral-charcoal text-base font-normal">
              {order.currencySymbol}
              {order.totalTax} {order.currencyCode}
            </p>
          </div>

          <div className="flex flex-row justify-between items-center">
            <p className="text-neutral-charcoal text-base font-bold">Total</p>
            <p className="text-neutral-charcoal text-base font-bold">
              {order.currencySymbol}
              {order.totalPaid} {order.currencyCode}
            </p>
          </div>
        </div>
      </div>
    </ContainerCardInvoice>
  );
};
