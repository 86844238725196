import { useState } from "react";
import { deleteAccount } from "../services";
import { useCustomerStore } from "../store/customerStore";
import { ICustomerStore } from "../interfaces";

export const useDeleteAccount = (customerId: string) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { language } = useCustomerStore((store: ICustomerStore) => store);

  const handleDeleteAccount = async (): Promise<boolean> => {
    setLoading(true);
    const {
      data: result,
      error,
      status,
    } = await deleteAccount(customerId, language);
    setData(result);
    setError(error);
    setLoading(false);

    return status === 200;
  };

  const refreshDeleteAccount = () => {
    handleDeleteAccount();
  };

  return { handleDeleteAccount, loading, error, data, refreshDeleteAccount };
};
