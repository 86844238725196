import { useCustomerStore } from "../../store/customerStore";
import { IPendingProps, ICustomerStore } from "../../interfaces";
import { PendingDesktop } from "./components/pending/PendingDesktop";
import { useEffect, useState } from "react";
import { PendingMobile } from "./components/pending/PendingMobile";

export type DeviceType = "Iphone" | "Android" | "Desktop";
const PendingLite = ({ cardDetails }: IPendingProps): JSX.Element => {
  const { language } = useCustomerStore((store: ICustomerStore) => store);
  const [device, setDevice] = useState<DeviceType>("Desktop");
  const [currentWidth, setCurrentWidth] = useState<number>(window.innerWidth);

  const getDevice = () => {
    setCurrentWidth(window.innerWidth);
    const userAgent = navigator.userAgent.toLowerCase();
    if (/mobile/i.test(userAgent) && currentWidth < 768) {
      if (/iphone/i.test(userAgent)) {
        setDevice("Iphone");
        return;
      }
      setDevice("Android");
      return;
    }
    setDevice("Desktop");
  };

  useEffect(() => {
    getDevice();
    window.addEventListener("resize", getDevice);

    return () => window.removeEventListener("resize", getDevice);
  }, [getDevice]);
  return (
    <div data-testid="pending-page" className="flex flex-col items-center">
      <article className="mb-[48px] w-full flex flex-col items-center gap-2">
        {cardDetails.flagUrl && (
          <img
            draggable={false}
            className={`w-9 h-9 md:w-6 md:h-6 object-contain `}
            src={cardDetails.flagUrl}
            alt="country_image"
          />
        )}
        <h1 className="mt-[12px] text-[20px] md:text-[28px] font-medium">
          {cardDetails.destination[language]}
        </h1>
        <h4 className="text-[16px] font-normal">
          {cardDetails.boundle[language]}
        </h4>
      </article>
      <hr className="w-full mb-[48px]" />
      {device === "Desktop" ? (
        <PendingDesktop cardDetails={cardDetails} />
      ) : (
        <PendingMobile
          mobileDevice={device}
          cardDetails={cardDetails}
          defaultOpion={device === "Iphone" ? "automatic" : "qr"}
        />
      )}
    </div>
  );
};

export default PendingLite;
