import { mdiMinus, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { ButtonCounter } from "./ButtonCounter";

import { IHolaPointsCounterProps } from "../../../../interfaces";

export const HolaPointsCounter = ({
  count,
  handleAdd,
  handleLess,
}: IHolaPointsCounterProps): JSX.Element => {
  return (
    <div
      data-testid="container-point-counter"
      className="w-[250px] h-[48px] flex justify-between items-center">
      <ButtonCounter type="less" handleClick={handleLess}>
        <Icon path={mdiMinus} size={1} />
      </ButtonCounter>

      <p
        data-testid="count"
        className="w-full text-[20px] font-bold text-center">
        {count}
      </p>

      <ButtonCounter type="add" handleClick={handleAdd}>
        <Icon path={mdiPlus} size={1} />
      </ButtonCounter>
    </div>
  );
};
