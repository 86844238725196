import { makeApiCall } from "../apiCall";
import { IPostRequestOrder } from "../../interfaces";
import { API_URLS } from "../../constants";

export const postDeliverInvoice = async (
  orderName: string
): Promise<IPostRequestOrder> => {
  const { URL_DELIVER_INVOICE } = API_URLS;

  const body = {
    external_order_id: orderName,
  };

  try {
    await makeApiCall<{ message: string; email?: string }>(
      "POST",
      URL_DELIVER_INVOICE,
      body
    );
    return { message: "global.customerV2.invoice.requestForm.sent.resend.success", isError: false };
  } catch (error) {
    return {
      message:
        error.response?.data?.message ||
        "global.customerV2.errorScreen.internal",
      isError: true,
    };
  }
};
