import HButton from "../common/hbutton/HButton";
import { IUniversalPagination } from "../../interfaces";
import { usePagination } from "../../hooks";

const style =
  "bg-white rounded-2xl flex justify-center items-center p-3 w-12 h-12 text-base font-medium text-neutral-charcoal";

export const UniversalPagination = ({
  setInitialLimit,
  limitList,
  numPagination,
  setCurrentPos,
}: IUniversalPagination): JSX.Element => {
  const {
    currentPosition,
    pageNumbers,
    initialPosition,
    endPage,
    handleGoPrevious,
    handleChangePage,
    handleGoNext,
  } = usePagination({
    numPagination,
    limitList,
    setInitialLimit,
    setCurrentPos,
  });

  const isPreviousEnabled = currentPosition > 0;
  const isNextEnabled = endPage < numPagination;

  return (
    <div
      data-testid="pagination-history-coupon"
      className="flex flex-row gap-[15px] justify-center items-center"
    >
      {/* Arrow to go back */}
      {isPreviousEnabled && (
        <HButton
          type="button"
          name="button previous pagination"
          className={style}
          onClick={handleGoPrevious}
          text="&lt;"
        />
      )}
      {/* Pages buttons */}
      {pageNumbers.map((pageNumber, index) => {
        return (
          <button
            data-testid="button-page-number"
            key={pageNumber}
            className={`${style} ${
              initialPosition === index ? "border-neutral-charcoal border" : ""
            }`}
            onClick={() => handleChangePage(pageNumber, index)}
          >
            {pageNumber}
          </button>
        );
      })}
      {/* Arrow to go next */}
      {isNextEnabled && (
        <HButton
          type="button"
          name="button next pagination"
          className={style}
          onClick={handleGoNext}
          text="&gt;"
        />
      )}
    </div>
  );
};
