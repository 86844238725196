import { ILinkText } from "../../interfaces";

export const LinkText = ({
  to,
  children,
  className,
}: ILinkText): JSX.Element => {
  const classNameStyle = className ? className : "text-blue-500 underline";

  return (
    <a
      data-testid="link-text"
      className={classNameStyle}
      href={to || "#"}
      target="_blank"
      rel="noreferrer">
      {children}
    </a>
  );
};
