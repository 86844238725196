import { mdiAndroid, mdiApple } from "@mdi/js";
import { TabsControl } from "../../../../components/tabsBar/TabsControl";
import { t } from "i18next";
import { useState } from "react";
import { InstructionsPendingLite } from "./IntructionPendingLite";
import { IOrderEsim } from "../../../../interfaces";
import { InfoQnA } from "../InfoQnA";

interface PendingDesktopProps {
  cardDetails: IOrderEsim;
}
export const PendingDesktop = ({ cardDetails }: PendingDesktopProps) => {
  const [tabOption, setTabOption] = useState<number>(0);
  const handleSetTabOption = (index: number) => setTabOption(index);
  return (
    <>
      <div className="gap-[20px] w-full flex flex-col items-center">
        <h1 className="text-[28px] text-center md:max-w-[550px] font-medium">
          {t("global.OneButInst_ChooseMethod_Title")}
        </h1>
        <TabsControl
          tabsData={[
            {
              id: "1",
              title: t(
                "global.login.helpCenter.sectionInstructions.tabsGlobal.first"
              ),
              icon: mdiApple,
            },
            {
              id: "2",
              title: t(
                "global.login.helpCenter.sectionInstructions.tabsGlobal.second"
              ),
              icon: mdiAndroid,
            },
          ]}
          handleTabClick={handleSetTabOption}
          activeTab={tabOption}
        />
        <InstructionsPendingLite
          cardDetails={cardDetails}
          isIos={tabOption === 0}
        />
      </div>
      <InfoQnA order={cardDetails.order_name} iccid={cardDetails.iccid} />
    </>
  );
};
