import { ISvg } from "../../../interfaces/components/svg/svgInterface";
import { THEME } from "../../../styles/theme";

const Heart = ({
  className,
  color = THEME.colors.black,
}: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="heart"
      width="57"
      height="57"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(className && { className })}
    >
      <path
        d="M7.27521 13.4175L6.2203 12.4523C2.47357 9.03761 0 6.7782 0 4.02159C0 1.76219 1.7606 0 4.00136 0C5.26725 0 6.48221 0.59227 7.27521 1.52089C8.0682 0.59227 9.28316 0 10.5491 0C12.7898 0 14.5504 1.76219 14.5504 4.02159C14.5504 6.7782 12.0768 9.03761 8.33011 12.4523L7.27521 13.4175Z"
        fill={color}
      />
    </svg>
  );
};

export default Heart;
