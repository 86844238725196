import { useEffect, useState } from "react";
import { getCountCardsByCustomerId } from "../services";
import { ICountCards, IUseGetCountCustomerCards } from "../interfaces";

export const useGetCountCustomerCards = (
  customerId: string
): IUseGetCountCustomerCards => {
  const [data, setData] = useState({} as ICountCards);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const fetchCountCards = async () => {
    setLoading(true);
    const { data: result, error } = await getCountCardsByCustomerId(customerId);
    setData(result);
    setError(error);
    setLoading(false);
  };

  useEffect(() => {
    fetchCountCards();
  }, [customerId]);

  const refreshCountCards = () => {
    fetchCountCards();
  };

  return { data, loading, error, refreshCountCards };
};
