import { IChildren } from "../../interfaces";

export const LoginCard = ({ children }: IChildren) => {
  return (
    <div
      data-testid="container-login"
      className="w-4/5 max-w-lg px-6 py-12 flex flex-col justify-start items-center border rounded-lg border-gray-300 gap-6 ">
      {children}
    </div>
  );
};
