import React, { SVGProps } from "react";

const UserReferralIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M12.672 11.772a3.21 3.21 0 0 0 .752-1.322 5.514 5.514 0 0 0 .218-1.54c0-.524-.073-1.037-.218-1.539a3.21 3.21 0 0 0-.752-1.322c.733.067 1.34.374 1.819.921.479.547.718 1.194.718 1.94 0 .747-.24 1.393-.718 1.94a2.652 2.652 0 0 1-1.82.922Zm4.558 7.064a.918.918 0 0 0 .215-.337 1.26 1.26 0 0 0 .077-.452v-.853c0-.476-.085-.928-.257-1.358a2.448 2.448 0 0 0-.814-1.064 6.542 6.542 0 0 1 1.817.876c.548.38.822.896.822 1.546v.858a.758.758 0 0 1-.226.559.758.758 0 0 1-.558.225H17.23Zm2.344-5.933a.468.468 0 0 1-.345-.14.47.47 0 0 1-.14-.345v-1.455h-1.455a.469.469 0 0 1-.345-.14.47.47 0 0 1-.14-.346c0-.138.047-.253.14-.345a.47.47 0 0 1 .345-.14h1.456V8.538c0-.137.046-.252.14-.345a.47.47 0 0 1 .345-.14c.138 0 .253.047.346.14a.47.47 0 0 1 .139.345v1.456h1.455c.137 0 .253.046.345.14a.47.47 0 0 1 .14.345.468.468 0 0 1-.14.346.47.47 0 0 1-.345.139H20.06v1.455a.468.468 0 0 1-.14.346.47.47 0 0 1-.346.139ZM8.791 11.821c-.8 0-1.486-.285-2.055-.855A2.803 2.803 0 0 1 5.88 8.91c0-.8.285-1.485.855-2.055A2.803 2.803 0 0 1 8.79 6c.8 0 1.486.285 2.056.855.57.57.854 1.255.854 2.055 0 .8-.285 1.486-.854 2.056-.57.57-1.256.855-2.056.855Zm-6.007 7.015a.758.758 0 0 1-.559-.225.758.758 0 0 1-.225-.559v-.813c0-.4.117-.775.35-1.124.233-.35.547-.62.941-.813.915-.439 1.831-.768 2.748-.988.917-.22 1.834-.329 2.752-.329.918 0 1.835.11 2.752.33.917.219 1.833.548 2.748.987.394.193.708.464.941.813.234.349.35.723.35 1.124v.813a.758.758 0 0 1-.225.559.758.758 0 0 1-.558.225H2.784ZM8.79 10.85c.534 0 .99-.19 1.37-.57.38-.38.57-.837.57-1.37 0-.534-.19-.99-.57-1.37-.38-.38-.836-.57-1.37-.57-.534 0-.99.19-1.37.57-.38.38-.57.836-.57 1.37 0 .533.19.99.57 1.37.38.38.836.57 1.37.57Zm-5.82 7.015h11.64v-.627c0-.215-.068-.418-.207-.607a1.673 1.673 0 0 0-.576-.479 12.317 12.317 0 0 0-2.47-.889 10.85 10.85 0 0 0-5.134 0c-.848.206-1.672.502-2.47.889-.245.13-.437.29-.576.48a1.006 1.006 0 0 0-.208.606v.627Z"
    />
  </svg>
);

export default UserReferralIcon;
