import { API_URLS, initialUserProfile } from "../../../constants";
import { makeApiCall } from "../../apiCall";
import { IUserProfile } from "../../../interfaces";

export const getUserProfile = async (id: string) => {
  const { URL_GET_PROFILE_USER } = API_URLS;
  try {
    const { data } = await makeApiCall<IUserProfile>(
      "GET",
      URL_GET_PROFILE_USER(id)
    );

    return { data, loading: false };
  } catch (error) {
    return { data: initialUserProfile, loading: false };
  }
};
