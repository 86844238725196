import { useTranslation } from "react-i18next";
import HButton from "../../../components/common/hbutton/HButton";
import { TModal } from "../../../interfaces";

export const UnsavedChangesModal = ({
  closeModal,
  handleSuccess,
}: TModal): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="unsaved-modal"
      className="relative bg-white w-full md:w-[600px] mx-4 h-auto md:h-[202px] rounded-xl shadow-[0_6px_24px_rgba(0,0,0,0.1)] p-[30px] flex flex-col 
  items-center justify-center gap-7 md:gap-[26px]">
      <h2 className="font-medium text-base md:text-[22px] leading-5 md:leading-7 text-neutral-charcoal">
        {t("global.account.UnsavedChangesModal.title")}
      </h2>
      <p className="font-normal text-center md:text-start text-sm md:text-base leading-5 md:leading-[22px] text-neutral-charcoal ">
        {t("global.account.UnsavedChangesModal.description")}
      </p>

      <div className="flex flex-col-reverse md:flex-row gap-2 w-full">
        <HButton
          type="button"
          name="button cancel changes of account"
          containerStyles="w-full"
          className="py-3 px-7 bg-white rounded-xl text-neutral-charcoal text-base font-medium hover:bg-neutral-200"
          text={t("global.account.UnsavedChangesModal.btnCancel")}
          onClick={closeModal}
        />

        <HButton
          type="button"
          name="button save changes of account"
          onClick={handleSuccess}
          containerStyles="w-full"
          className="py-3 px-7 bg-magenta border-none text-white rounded-xl text-base font-medium hover:bg-magenta-900"
          text={t("global.account.UnsavedChangesModal.btnSaveChanges")}
        />
      </div>
    </div>
  );
};
