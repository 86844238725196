import { LANGUAGES_KEYS } from "../i18n";
import { IPreferencesStore } from "../interfaces/store/preferenceStoreInterface";
import { getLanguage } from "../utils";

export const createPreferencesSlice = (set): IPreferencesStore => ({
  language: getLanguage(LANGUAGES_KEYS),
  setLanguage: (language) => {
    set({ language });
    sessionStorage.setItem("language", language);
  },
  currency: "EUR - €",
  setCurrency: (currency) => set({ currency }),
});
