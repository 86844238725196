import { Fragment, useState } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { useTranslation } from "react-i18next";
import HButton from "../common/hbutton/HButton";
import { useScreen } from "../../hooks";
import { HeaderItemProfileProps } from "../../interfaces";
import Avatar from "../../assets/account/avatar.svg";

export const HeaderItemProfile = ({
  name,
  img,
  reverseContent,
  subMenu,
}: HeaderItemProfileProps): JSX.Element => {
  const screen = useScreen();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const { t } = useTranslation();
  return (
    <div
      style={{
        flexDirection: `${reverseContent ? "row-reverse" : "row"}`,
      }}
      className="relative flex justify-between gap-2 items-center"
    >
      <p
        data-testid="profile-name"
        style={{
          minWidth: name?.length === 0 ? "36px" : "auto",
        }}
        className="whitespace-nowrap truncate md:overflow-visible md:text-clip md:whitespace-wrap font-medium"
      >
        {name?.length > 30 ? `${name.slice(0, 30)}...` : name}
      </p>
      <div
        onClick={() => screen >= 1024 && setIsShowMenu(!isShowMenu)}
        className="w-[46px] h-[46px]  lg:h-auto rounded-full relative cursor-pointer"
      >
        <img
          draggable="false"
          className="object-fill"
          src={img || Avatar}
          alt="avatar"
        />
      </div>

      <HButton
        type="button"
        name="button show menu"
        icon={isShowMenu ? mdiChevronUp : mdiChevronDown}
        onClick={() => setIsShowMenu(!isShowMenu)}
        className="cursor-pointer hidden lg:block"
      />

      {isShowMenu && subMenu && (
        <div
          data-testid="submenu-profile"
          className="absolute top-[110%] -right-6 min-w-[205px] bg-white rounded-xl shadow-card z-20"
        >
          {subMenu.map((item, idx) => (
            <div key={idx}>
              {item.customSubMenu ? (
                <div
                  data-testid="custom-submenu"
                  onClick={() => {
                    setIsShowMenu(!isShowMenu);
                    item.handleClick();
                  }}
                >
                  {item.customSubMenu}
                </div>
              ) : (
                <Fragment>
                  <div
                    data-testid="submenu"
                    onClick={() => {
                      setIsShowMenu(!isShowMenu);
                      item.handleClick();
                    }}
                    className="flex items-center p-4 gap-2 cursor-pointer font-medium"
                  >
                    {typeof item.icon !== "string" ? (
                      item.icon
                    ) : (
                      <Icon data-testid="icon-menu" path={item.icon} size={1} />
                    )}

                    <p className="text-sm whitespace-nowrap">{item.label}</p>
                    {item.isNewElement && (
                      <div
                        style={{
                          background: "var(--semantic-info-100, #EBF3FF)",
                        }}
                        className="mr-[26px] flex justify-center items-center py-[2px] px-2 rounded-[80px]"
                      >
                        <p
                          style={{
                            color: "var(--semantic-info-500, #2065CE)",
                          }}
                          className="text-[10px] font-bold"
                        >
                          {t("global.customerV2.header.chip")}
                        </p>
                      </div>
                    )}
                  </div>
                  {item.addDivider && <hr />}
                </Fragment>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
