import { useSearchParams } from "react-router-dom";

import { ICustomerStore } from "../interfaces";
import { getCurrencyData } from "../utils/getCurrencyData";
import { useCustomerStore } from "../store/customerStore";

export const useSetCurrencyFromUrl = () => {
  const { setCurrency } = useCustomerStore((store: ICustomerStore) => store);

  let [searchParams] = useSearchParams();

  const handleCurrency = () => {
    const currencyQuery = searchParams.get("currency");
    if (!currencyQuery) {
      return;
    }

    const currency = getCurrencyData(currencyQuery, true);

    if (currency) {
      setCurrency(currency.id);
      return;
    }
    setCurrency("EUR - €");
  };

  return handleCurrency;
};
