import { IButtonCounterProps } from "../../../../interfaces";

export const ButtonCounter = ({
  children,
  handleClick,
  type,
}: IButtonCounterProps): JSX.Element => {
  return (
    <button
      aria-label={`button ${type}`}
      onClick={handleClick}
      className="p-[12px] w-[48px] h-[48px] rounded-[16px] hover:bg-neutral-200 flex justify-center items-center cursor-pointer border border-[#E4E6EA]">
      {children}
    </button>
  );
};
