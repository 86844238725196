import { Trans } from "react-i18next";

const styles = "mt-6 font-normal text-sm md:text-xl lg:text-[28px] text-center";

const translationComponent = {
  p: <span className="font-bold" />,
};

export const splashTextDefault = [
  {
    index: (
      <h2 className={`${styles} w-72 animate-fadeOut`}>
        <Trans
          i18nKey="global.splash.firstLoaderText"
          components={translationComponent}
        />
      </h2>
    ),
  },
  {
    index: (
      <h2 className={`${styles} w-80 animate-fadeOutIn`}>
        <Trans
          i18nKey="global.splash.secondLoaderText"
          components={translationComponent}
        />
      </h2>
    ),
  },
  {
    index: (
      <h2 className={`${styles} w-96 animate-fadeOutIn2`}>
        <Trans
          i18nKey="global.splash.threeLoaderText"
          components={translationComponent}
        />
      </h2>
    ),
  },
];
