import { API_URLS } from "../../constants";
import { ImakeActivationTopUpParamsApi } from "../../interfaces";
import { makeApiCall } from "../apiCall";

export default async function makeActivationTopUp({
  customerCardId,
  isValidate,
}: ImakeActivationTopUpParamsApi) {
  const { URL_ACTIVATE_TOPUP } = API_URLS;
  const response = await makeApiCall<any>(
    "POST",
    URL_ACTIVATE_TOPUP(customerCardId),
    { validate: isValidate }
  );
  return response;
}
