import React from "react";
import { useTranslation } from "react-i18next";
import { IFinishedStatusProps } from "../../../interfaces";

export const FinishedStatus = ({
  state,
  flagImg,
}: IFinishedStatusProps): JSX.Element => {
  const { t } = useTranslation();

  return flagImg ? (
    <div className="relative">
      <div className="rounded-3xl bg-white h-6 absolute w-10 -left-[50px] z-10" />
      <div
        className={`rounded-l-none rounded-r-3xl bg-neutral-300 h-6 px-3 w-auto relative right-4 flex items-center justify-center`}
      >
        <h2 className="font-normal text-xs text-neutral-charcoal">
          {t(`global.common.states.${state}.card_status`)}
        </h2>
      </div>
    </div>
  ) : (
    <div
      className={`rounded-3xl bg-neutral-300 h-6 px-3 w-auto flex items-center justify-center`}
    >
      <h2 className="font-normal text-xs text-neutral-charcoal">
        {t(`global.common.states.${state}.card_status`)}
      </h2>
    </div>
  );
};
