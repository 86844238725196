import { THEME } from "../styles/theme";

const TABS = {
  MY_SIMS: "MY_SIMS",
  ACTIVATION_REQUIRED: "ACTIVATION_REQUIRED",
  EXPIRED: "EXPIRED",
  ANDROID: "ANDROID",
  IOS: "IOS",
};

const STATUS = {
  ACTIVATION_DATE: {
    circle_color: THEME.colors.yellow,
    tabs: [TABS.MY_SIMS, TABS.ACTIVATION_REQUIRED],
    wave_color: "gray",
  },
  ACTIVE: {
    circle_color: THEME.colors["ntn-success"],
    tabs: [TABS.MY_SIMS],
    wave_color: "green",
  },
  FINISHED: {
    circle_color: THEME.colors.neutroGris10,
    tabs: [TABS.EXPIRED],
    wave_color: "gray",
  },
};

const STATUS_ESIM = [
  {
    id: 0,
    status: "Pending",
  },
  {
    id: 1,
    status: "Activated",
  },
  {
    id: 2,
    status: "Expired",
  },
];

export { STATUS, TABS, STATUS_ESIM };
