import Icon from "@mdi/react";
import {
  mdiCartOutline,
  mdiClose,
  mdiExportVariant,
  mdiTagOutline,
} from "@mdi/js";
import { t } from "i18next";
import HButton from "../../../../components/common/hbutton/HButton";
import referralBenefit from "../../../../assets/referrals/referralBenefits.svg";

export const FriendDiscountModal = ({ closeModal }): JSX.Element => {
  return (
    <div data-testid="friend-discount-modal" className="h-full overflow-y-auto">
      <div className="my-[40px] relative bg-white mx-[30px] pt-6 pb-4 px-4 md:p-[30px] lg:p-[50px]   md:w-[45rem] lg:w-[800px]  rounded-xl">
        <button
          name="button close friend discount modal"
          aria-label="button close friend discount modal"
          onClick={closeModal}
          className="absolute top-4 right-4 border border-neutral-300 hover:bg-neutral-200 rounded-full w-8 h-8 flex 
    justify-center items-center transition-all duration-300">
          <Icon path={mdiClose} size={0.8} />
        </button>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col items-center gap-3">
            <img
              className="h-44 md:h-auto"
              src={referralBenefit}
              draggable={false}
              alt="BENEFITS"
            />
            <h1 className="text-neutral-charcoal text-xl md:text-[22px] font-bold md:font-medium text-center">
              {t("global.customerV2.referrals.modals.friendDiscount.title")}
            </h1>
          </div>

          <div className="flex flex-col gap-3">
            <h2 className="text-neutral-charcoal text-base font-bold text-center">
              {t("global.customerV2.referrals.modals.friendDiscount.subtitle")}
            </h2>

            <div className="flex flex-col gap-[5px]">
              <div className="p-[15px] w-full bg-brand-baby-blue-200 rounded-xl">
                <div className="flex flex-row gap-[10px] items-center">
                  <Icon
                    path={mdiExportVariant}
                    size={0.8}
                    className="text-neutral-charcoal"
                  />

                  <div className="flex flex-col">
                    <p className="text-neutral-charcoal font-normal text-sm">
                      {t(
                        "global.customerV2.referrals.modals.friendDiscount.list.list_1.paragraph_1"
                      )}
                    </p>
                    <p className="text-neutral-charcoal font-bold text-sm">
                      {t(
                        "global.customerV2.referrals.modals.friendDiscount.list.list_1.paragraph_2"
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="p-[15px] w-full bg-brand-baby-blue-200 rounded-xl">
                <div className="flex flex-row gap-[10px] items-center">
                  <Icon
                    path={mdiCartOutline}
                    size={1.1}
                    className="text-neutral-charcoal"
                  />

                  <div className="flex flex-col">
                    <p className="text-neutral-charcoal font-normal text-sm">
                      {t(
                        "global.customerV2.referrals.modals.friendDiscount.list.list_2.paragraph_1"
                      )}
                    </p>
                    <p className="text-neutral-charcoal font-normal text-xs">
                      {t(
                        "global.customerV2.referrals.modals.friendDiscount.list.list_2.paragraph_2"
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="p-[15px] w-full bg-brand-baby-blue-200 rounded-xl">
                <div className="flex flex-row gap-[10px] items-center">
                  <Icon
                    path={mdiTagOutline}
                    size={0.8}
                    className="text-neutral-charcoal"
                  />

                  <div className="flex flex-col">
                    <p className="text-neutral-charcoal font-normal text-sm">
                      {t(
                        "global.customerV2.referrals.modals.friendDiscount.list.list_3.paragraph_1"
                      )}
                    </p>
                    <p className="text-neutral-charcoal font-normal text-xs">
                      {t(
                        "global.customerV2.referrals.modals.friendDiscount.list.list_3.paragraph_2"
                      )}
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="p-[15px] w-full bg-brand-baby-blue-200 rounded-xl">
                <div className="flex flex-row gap-[10px] items-center">
                  <img src={holaCoin} draggable={false} alt="HolaCoins" />

                  <p className="text-neutral-charcoal font-normal text-sm">
                    {t(
                      "global.customerV2.referrals.modals.friendDiscount.list.list_4.paragraph_1"
                    )}
                  </p>
                </div>
              </div> */}
            </div>
          </div>

          <HButton
            type="button"
            name="button close friend disconunt modal"
            onClick={closeModal}
            containerStyles="flex justify-center items-center"
            className="px-5 py-3 bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold
           text-white hover:bg-gradient-to-l rounded-xl w-full md:w-[200px]"
            text={t(
              "global.customerV2.referrals.modals.friendDiscount.textButton"
            )}
          />
        </div>
      </div>
    </div>
  );
};
