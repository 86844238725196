import React from "react";
import { IPropsErrorBoundaries, IStateErrorBoundaries } from "../../interfaces";

export class ErrorBoundary extends React.Component<
  IPropsErrorBoundaries,
  IStateErrorBoundaries
> {
  constructor(props: IPropsErrorBoundaries) {
    super(props);
    this.state = { hasError: false, resetCondition: props.resetCondition };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  static getDerivedStateFromProps(
    props: IPropsErrorBoundaries,
    state: IStateErrorBoundaries
  ) {
    if (props.resetCondition !== state.resetCondition) {
      return { hasError: false, resetCondition: props.resetCondition };
    }
    return null;
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError || this.props.error) {
      return this.props.fallBackComponent;
    }

    return this.props.children;
  }
}
