import { useEffect, useState } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp, mdiInformationOutline } from "@mdi/js";

import { HistoryItem } from "./HistoryItem";

import { IHistoryPlansProps } from "../../../../interfaces";
import { useTranslation } from "react-i18next";

export const HistoryPlans = ({
  isUnlimited,
  items,
  remainingDays,
}: IHistoryPlansProps): JSX.Element => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [animation, setAnimation] = useState("");

  const handleSetMoreItems = () => {
    setShowMore(!showMore);
    setAnimation("animate-fadeOutIn");
  };

  useEffect(() => {
    const timeIntervalAnimation = setTimeout(() => {
      setAnimation("");
    }, 800);

    return () => clearTimeout(timeIntervalAnimation);
  }, [showMore, setAnimation]);

  return (
    <div className=" relative w-full mt-[24px] pb-[30px]">
      <div className="flex items-center gap-3">
        <h3 className="text-[16px] font-bold">
          {t("global.customerV2.details.active.textPlans")}
        </h3>
        <Icon path={mdiInformationOutline} size={0.7} />
      </div>
      <div
        className={`${animation} relative transition-all duration-[200] w-full mt-[24px] mb-[10px]  p-[16px] flex flex-col items-center max-h-[520px] md:max-h-[410px] overflow-x-auto `}>
        {!showMore
          ? items
              .slice(0, 3)
              .map((historyItem, index) => (
                <HistoryItem
                  {...historyItem}
                  key={index}
                  planType={isUnlimited ? "unlimited" : "limited"}
                  daysRestant={remainingDays}
                />
              ))
          : items.map((historyItem, index) => (
              <HistoryItem
                {...historyItem}
                key={index}
                planType={isUnlimited ? "unlimited" : "limited"}
                daysRestant={remainingDays}
              />
            ))}
      </div>

      {items.length > 3 && (
        <div
          data-testid="button-setMoreItems"
          onClick={handleSetMoreItems}
          className="absolute bottom-0 left-0 right-0 mx-auto flex gap-[8px] max-w-[200px] justify-center items-end cursor-pointer">
          <p className="text-[16px] font-medium select-none">
            {showMore
              ? `${t(
                  "global.customerV2.details.active.buttons.buttonShowLess"
                )}`
              : `${t(
                  "global.customerV2.details.active.buttons.buttonShowMore"
                )}`}
          </p>
          <Icon path={showMore ? mdiChevronUp : mdiChevronDown} size={1} />
        </div>
      )}
    </div>
  );
};
