import { useTranslation } from "react-i18next";
import { getCurrencyData } from "../../utils/getCurrencyData";
import {
  BASE_CLASS_IS,
  SELECTED_CLASS_IS,
  UNSELECTED_CLASS_IS,
} from "../../constants";
import { IItemSelectorProps } from "../../interfaces";

export const ItemSelector = ({
  id,
  current,
  onClick,
  label,
  type,
}: IItemSelectorProps): JSX.Element => {
  const { t } = useTranslation();

  const getLabel = (): string => {
    if (type === "LANGUAGE") {
      return label;
    }
    const currency = getCurrencyData(id).currency.toLowerCase();
    return t(`global.customerV2.currency.${currency}`);
  };

  const getDescription = (): string => {
    if (type === "LANGUAGE") {
      return t(`global.header.languages.${id}`);
    }
    return id;
  };

  const handleClick = () => onClick(id);

  const CONTAINER_CLASSNAME: string = `${BASE_CLASS_IS} ${
    current === id ? SELECTED_CLASS_IS : UNSELECTED_CLASS_IS
  }`;

  return (
    <button
      onClick={handleClick}
      className={CONTAINER_CLASSNAME}
      data-testid="btn_language"
    >
      <p className="font-medium text-lg">{getLabel()}</p>
      <p data-testid="language_paragraph" className="text-base text-gray-400">
        {getDescription()}
      </p>
    </button>
  );
};
