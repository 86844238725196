import { ISvg } from "../../../interfaces/components/svg/svgInterface";
import { THEME } from "../../../styles/theme";

const Chip = ({ className, color = THEME.colors.black }: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="chip"
      width="57"
      height="57"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(className && { className })}
    >
      <path
        d="M12 6H6V12H12V6ZM10 10H8V8H10V10ZM18 8V6H16V4C16 2.9 15.1 2 14 2H12V0H10V2H8V0H6V2H4C2.9 2 2 2.9 2 4V6H0V8H2V10H0V12H2V14C2 15.1 2.9 16 4 16H6V18H8V16H10V18H12V16H14C15.1 16 16 15.1 16 14V12H18V10H16V8H18ZM14 14H4V4H14V14Z"
        fill={color}
      />
    </svg>
  );
};

export default Chip;
