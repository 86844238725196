import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { InstallCodeCard } from "./InstallCodeCard";
import { useInstallCodes } from "../../hooks/useInstallCodes";
import { INITIAL_STATE_INSTALLCODE } from "../../constants";
import { IInstallCodeProps } from "../../interfaces";

export const InstallCode = ({
  customerIdCard,
  iccidCard,
  bgContainerCard,
}: IInstallCodeProps): JSX.Element => {
  const { t } = useTranslation();

  const { SMDP, activationCode, loader } = useInstallCodes(
    INITIAL_STATE_INSTALLCODE,
    customerIdCard,
    iccidCard
  );

  return (
    <Fragment>
      <InstallCodeCard
        title={t(
          "global.customerV2.details.pending.sectionInstructions.installationCode.cards.titleSMDPAdresse"
        )}
        text={SMDP}
        bgContainer={bgContainerCard}
        orientation="UP"
        isLoading={loader}
      />
      <InstallCodeCard
        title={t(
          "global.customerV2.details.pending.sectionInstructions.installationCode.cards.titleActivationCode"
        )}
        text={activationCode}
        bgContainer={bgContainerCard}
        orientation="DOWN"
        isLoading={loader}
      />
    </Fragment>
  );
};
