import { t } from "i18next";
import { IEsimCounterProps } from "../../../interfaces";

export const EsimCounter = ({
  cardType,
  count,
}: IEsimCounterProps): JSX.Element => {
  const backgrounds = {
    active: "bg-[#EBF3FF]",
    pending: "bg-[#FFF8EC]",
  };
  return (
    <div
      data-testid="esim-counter-delete-account"
      className="w-full lg:w-[210px] h-auto lg:h-[56px] p-[16px] rounded-[12px] bg-[#fff] flex flex-col sm:flex-row justify-between items-center"
    >
      <h4
        data-testid="esim-counter-count-value"
        className="text-[20px] font-medium"
      >
        {count}
      </h4>
      <article
        className={`${backgrounds[cardType]} h-[24px] py-[4px] px-[12px] flex justify-center items-center rounded-[24px]`}
      >
        <p className="text-[12px font-normal text-[#002968]">
          {cardType === "active"
            ? t("global.customerV2.deleteAccount.esimCounter.activeLabel")
            : t("global.customerV2.deleteAccount.esimCounter.pendingLabel")}
        </p>
      </article>
    </div>
  );
};
