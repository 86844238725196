import { useEffect, useState } from "react";
import { AddDatta } from "./components/active/AddData";

import { CardState } from "./components/active/CardState";
import { InfoActive } from "./components/active/InfoActive";

import { useGetQueuedBundles } from "../../hooks";
import { IActiveProps } from "../../interfaces";
import { isProviderActive } from "../../utils/validateProviders";

export const Active = ({
  topups,
  cardDetails,
  loadingTopUps,
}: IActiveProps): JSX.Element => {
  const { queuedBundles } = useGetQueuedBundles(cardDetails.id);
  const [haveNextPackages, setHaveNextPackages] = useState<boolean>(false);

  const getBundles = () => {
    setHaveNextPackages(queuedBundles.length > 0);
  };

  useEffect(() => {
    if (isProviderActive(cardDetails.provider_name)) {
      getBundles();
      return;
    }
  }, []);

  return (
    <div className="relative w-full h-full flex flex-col items-center">
      <div className="flex flex-wrap justify-center gap-[24px]">
        {<CardState cardDetails={cardDetails} />}
        {isProviderActive(cardDetails.provider_name) && (
          <AddDatta
            loadingTopUps={loadingTopUps}
            topups={topups}
            cardDetails={cardDetails}
            haveNextPackages={haveNextPackages}
          />
        )}
      </div>
      <InfoActive
        iccid={cardDetails.iccid}
        order={cardDetails.order_name}
        customer_id={cardDetails.customer_id as string}
      />
    </div>
  );
};
