import { LinkText } from "../linkText/LinkText";
import { t } from "i18next";

interface ITermsAndPoliticsTextLink {
  isUnderline?: boolean;
}

export const TermsAndPoliticsTextLink = ({
  isUnderline,
}: ITermsAndPoliticsTextLink): JSX.Element => {
  const isTextUnderline = !isUnderline ? "underline" : "no-underline";
  return (
    <p
      data-testid="terms-and-politics-text"
      className="text-neutral-350 text-[10px] font-bold">
      <LinkText
        to={t("global.login.linkTerms")}
        className={`${isTextUnderline} cursor-pointer`}>
        {t("global.login.verficationCode.textTerms")}
      </LinkText>
      ·{" "}
      <LinkText
        to={t("global.login.linkPolitics")}
        className={`${isTextUnderline} cursor-pointer`}>
        {t("global.login.verficationCode.textPolitics")}
      </LinkText>
    </p>
  );
};
