import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import makeActivationTopUp from '../services/topup/activation';

export const useActivationTopUp = () => {
  const [customerCardId, setCustomerCardId] = useState('');
  const [isValidate, setIsValidate] = useState(true);

  const onChangeCustomerId = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCustomerCardId(e.target.value);

  const onChangeIsValidate = (e: React.ChangeEvent<HTMLInputElement>) =>
    setIsValidate(e.target.checked);

  const makeActivation = useCallback(async () => {
    try {
      const response = await makeActivationTopUp({
        customerCardId,
        isValidate,
      });

      if (response.data.message) alert(response.data.message);
    } catch (error) {
      const { response } = error as AxiosError;
      const data: any = response?.data;
      alert(data.message || '');
      setCustomerCardId('');
    }
  }, [customerCardId, isValidate]);

  return {
    customerCardId,
    isValidate,
    onChangeCustomerId,
    onChangeIsValidate,
    makeActivation,
  };
};
