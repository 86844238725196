import { useCustomNavigate } from "../../../hooks";
import { HolaCoinIcon } from "../../svg";

interface CoinsInfoProps {
  coins: number;
}

export const CoinsInfo = ({ coins }: CoinsInfoProps) => {
  const navigate = useCustomNavigate();
  return (
    <div
      onClick={() => navigate("/referralBoard")}
      className=" gap-[2px] cursor-pointer flex justify-center w-full items-center"
    >
      <HolaCoinIcon className="w-[24px] h-[24px]" />
      <p className="text-[16px] lg:text-[14px] font-bold lg:font-medium">
        {coins > 99 ? "99+" : coins}
      </p>
    </div>
  );
};
