import { Active } from './Active';
import PendingLite from './PendingLite';
import { LayoutDetail } from './LayoutDetail';
import './style.css';
import { useDetails } from '../../hooks';

const Details = () => {
  const { data, loadingTopups, topups, loading } = useDetails();

  return (
    <LayoutDetail loading={loading}>
      <>
        {data.status === 'ACTIVE' && (
          <Active
            loadingTopUps={loadingTopups}
            topups={topups}
            cardDetails={data}
          />
        )}
        {data.status === 'ACTIVATION_QR' && <PendingLite cardDetails={data} />}
      </>
    </LayoutDetail>
  );
};

export default Details;
