import { makeApiCall } from "../apiCall";
import { esimAdapter } from "../../adapters";
import { API_URLS, INITIAL_RESPONSE_BY_CUSTOMER_CARD } from "../../constants";

interface IGetByCustomerIccid {
  iccid: string;
  isIncludeProvider?: boolean;
}

export const getByCustomerIccid = async (
  { iccid, isIncludeProvider }: IGetByCustomerIccid,
  signal?: AbortSignal
) => {
  const { URL_GET_ICCID_CUSTOMER } = API_URLS;

  try {
    const { status, data } = await makeApiCall<any>(
      "GET",
      URL_GET_ICCID_CUSTOMER(iccid, isIncludeProvider),
      null,
      signal
    );

    if (status === 200) {
      return {
        status,
        data: esimAdapter(data),
        error: null,
      };
    }

    return {
      status,
      data: INITIAL_RESPONSE_BY_CUSTOMER_CARD,
      error: "Bad Request",
    };
  } catch (error) {
    console.error("Error al obtener la información:", error);
    return {
      status: 500,
      data: INITIAL_RESPONSE_BY_CUSTOMER_CARD,
      error,
    };
  }
};
