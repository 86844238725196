import { useState } from "react";
import { LayoutPageWithMapBackground } from "../../components/layouts/LayoutPageWithMapBackground";
import { ContentModal } from "../../components/modal/ContentModal";
import { CardsAdvice } from "./components/CardsAdvice";
import { CardsAdviceSkeleton } from "./components/CardsAdviceSkeleton";
import { InformationCard } from "./components/InformationCard";
import { ModalConfirmation } from "./components/ModalConfirmation";
import { useGetCountCustomerCards } from "../../hooks/useGetCountCustomerCards";
import { ICustomerStore } from "../../interfaces";
import { useCustomerStore } from "../../store/customerStore";
import { ErrorBoundary } from "../../components/errors/ErrorBoundaries";
import { NotificationInternalError } from "../../components/alert/NotificationInternalError";
import { DeletedAccountNotification } from "./components/DeletedAccountNotification";
import { t } from "i18next";

export const DeleteAccountPage = () => {
  const [showModal, setShowModal] = useState(false);

  const { customerId } = useCustomerStore((store: ICustomerStore) => store);

  const {
    data: { active, pending },
    loading,
  } = useGetCountCustomerCards(customerId);

  const [error, setError] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const showAdviceCard = active > 0 || pending > 0;

  const handleReloadPage = () => window.location.reload();

  return (
    <LayoutPageWithMapBackground
      title={t("global.customerV2.deleteAccount.btnDelete")}
      goBack
      btnLabel={t("global.customerV2.deleteAccount.btnBack")}>
      <ErrorBoundary
        error={error}
        fallBackComponent={
          isDeleted ? (
            <DeletedAccountNotification />
          ) : (
            <NotificationInternalError
              title="global.customerV2.alerts.alertConnectionInternalError.title"
              description="global.customerV2.alerts.alertConnectionInternalError.description"
              textButton="global.customerV2.alerts.alertConnectionInternalError.textButton"
              onClick={handleReloadPage}
            />
          )
        }>
        <div className="w-full flex flex-col gap-6">
          <h1 className="text-center  text-[20px] lg:text-[28px] font-bold lg:font-medium">
            {t("global.customerV2.deleteAccount.subTitle")}
          </h1>
          <div className="flex flex-col lg:flex-row w-full  gap-6">
            {loading && <CardsAdviceSkeleton />}
            {!loading && showAdviceCard && (
              <CardsAdvice active={active} pending={pending} />
            )}
            <InformationCard handleModal={setShowModal} />
          </div>
        </div>
      </ErrorBoundary>

      <ContentModal isModal={showModal} toggleModal={() => setShowModal(false)}>
        <ModalConfirmation
          handleConfirm={setIsDeleted}
          handleModal={setShowModal}
          setError={setError}
        />
      </ContentModal>
    </LayoutPageWithMapBackground>
  );
};
