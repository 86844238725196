const styleUnitMeasure = {
  height: {
    "520": "520px",
  },
  width: {
    "520": "520px",
    "456": "456px",
  },
};
export default styleUnitMeasure;
