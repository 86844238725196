import { SVGProps } from "react";

const ConnectionBadPhone = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={54}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M23.508 34.937c-.38-.381-.571-.826-.571-1.334 0-.508.19-.952.571-1.333l5.27-5.27-5.27-5.27c-.338-.338-.518-.773-.538-1.303a1.75 1.75 0 0 1 .538-1.363c.381-.381.826-.572 1.333-.572.508 0 .953.19 1.334.572l5.27 5.27 5.27-5.27c.338-.339.772-.52 1.302-.541.529-.02.983.16 1.364.54.381.382.572.826.572 1.334 0 .508-.19.952-.572 1.333L34.111 27l5.27 5.27c.339.339.52.772.541 1.3.02.53-.16.986-.54 1.367-.382.38-.826.571-1.334.571-.508 0-.953-.19-1.334-.571l-5.27-5.27-5.27 5.27c-.338.338-.771.518-1.3.538a1.755 1.755 0 0 1-1.366-.538Zm6.032 11.746H4.143v2.412c0 .212.075.392.224.541a.732.732 0 0 0 .538.221h23.873a.74.74 0 0 0 .54-.22.74.74 0 0 0 .222-.542v-2.412Zm0-39.365V4.905a.741.741 0 0 0-.221-.541.741.741 0 0 0-.541-.221H4.905a.733.733 0 0 0-.538.221.737.737 0 0 0-.224.54v2.414H29.54ZM4.905 53.667c-1.27 0-2.35-.445-3.238-1.334-.89-.888-1.334-1.968-1.334-3.238V4.905c0-1.27.445-2.35 1.334-3.238C2.556.777 3.635.333 4.905.333h23.873c1.27 0 2.35.445 3.238 1.334.889.889 1.333 1.968 1.333 3.238v9.27h-3.81v-3.048H4.144v31.746H29.54v-3.047h3.81v9.27c0 1.27-.445 2.349-1.334 3.238-.889.888-1.968 1.333-3.238 1.333H4.905Z"
    />
  </svg>
);

export default ConnectionBadPhone;
