import axios, { AxiosResponse } from "axios";
import { Methods } from "../interfaces";
import { getToken } from "../utils";
import { CREDENTIALS_URL } from "../constants";

export const makeApiCall = async <T>(
  method: Methods,
  route: string,
  body?: any,
  signal?: AbortSignal
): Promise<AxiosResponse<T>> => {
  const publicUrls = ["/user/signIn", "/user/signUp", "/graphql", "/admin"];

  const token = getToken() ?? "";

  let headers: { "Content-Type": string } & {
    token?: string;
    hfc_api_key?: string;
  } = {
    "Content-Type": "application/json",
  };

  if (!publicUrls.some((element) => route.includes(element))) {
    headers = { ...headers, token };
  } else {
    headers = { ...headers, hfc_api_key: CREDENTIALS_URL.HFC_API_KEY };
  }

  const config = {
    headers,
    signal,
    data: body ? JSON.stringify(body) : undefined,
  };

  return await axios({ ...config, method, url: route });
};
