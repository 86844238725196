import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../header/Header";
import { FooterWave } from "../footer/FooterWave";
import { ContentModal } from "../modal/ContentModal";
import { NotificationInfoModal } from "../modal/NotificationInfoModal";
import { useScreen } from "../../hooks";
import { useCustomerStore } from "../../store/customerStore";
import { IChildren, ICustomerStore } from "../../interfaces";
import "./style.css";

const Layout = ({ children }: IChildren): JSX.Element => {
  const location = useLocation();
  const screen = useScreen();
  const {
    setCustomerId,
    setShowNotificationModal,
    showNotificationModal,
    customerId: customerIdZustand,
    hasSeenOnboardingTravelClub,
  } = useCustomerStore((store: ICustomerStore) => store);

  const customerId = localStorage.getItem("customerId") || customerIdZustand;

  useEffect(() => {
    setCustomerId(customerId);
  }, [customerId, setCustomerId]);

  const onCloseModal = () => setShowNotificationModal(false);

  return (
    <div
      data-testid="layout"
      className="h-full w-full min-h-screen flex flex-col items-center"
    >
      <Header />
      {children}

      {!(
        screen < 768 &&
        location.pathname === "/referralBoard" &&
        !hasSeenOnboardingTravelClub
      ) && <FooterWave />}

      <ContentModal isModal={showNotificationModal} toggleModal={onCloseModal}>
        <NotificationInfoModal onCloseModal={onCloseModal} />
      </ContentModal>
    </div>
  );
};

export default Layout;
