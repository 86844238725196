import { t } from "i18next";
import { IOrderEsim } from "../interfaces";

export const daysTotalLimited = (data: IOrderEsim, lang: string): string => {
  const splitBoundle = data?.boundle ? data?.boundle[lang]?.split(" ") : "";

  let unlimitedStr = t("global.details.isUnlimited");

  if (splitBoundle?.includes(unlimitedStr)) {
    return splitBoundle[0];
  } else {
    return splitBoundle[1];
  }
};
