export const SkeletonCardActivationCode = (): JSX.Element => {
  return (
    <div
      data-testid="skeleton-activation-code"
      className="animate-pulse flex flex-col gap-2">
      <div className="h-6 w-48 rounded-lg bg-gray-300 font-bold text-sm text-neutral-charcoal break-all max-w-[80%]" />
      <div className="h-6 w-72 rounded-lg bg-gray-300 text-sm font-normal text-neutral-charcoal" />
    </div>
  );
};
