import { useState, useEffect } from "react";
import { getUserOrders } from "../services";
import { IPropsOrders, IUseUserOrders } from "../interfaces";

export const useUserOrders = (
  customerId: string,
  initialPage: number = 1,
  initialPerPage: number = 9,
  initialSort: string = "ASC"
): IUseUserOrders => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<IPropsOrders>({
    count: 0,
    pages_count: 0,
    orders: [],
  });
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(initialPage);
  const [perPage, setPerPage] = useState(initialPerPage);
  const [sort, setSort] = useState(initialSort);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const fetchUserOrders = async () => {
    setLoading(true);

    const { data, isError } = await getUserOrders(
      {
        customerId,
        page,
        perPage,
        sort,
      },
      signal
    );

    if (isError) {
      setLoading(false);
      setIsError(isError);
      return;
    }

    setLoading(false);
    setOrders(data);
    setIsError(isError);
  };

  useEffect(() => {
    fetchUserOrders();

    return () => {
      abortController.abort();
    };
  }, [customerId, page, perPage, sort]);

  const updatePage = (newPage: number) => {
    setPage(newPage);
  };

  const updatePerPage = (newPerPage: number) => {
    setPerPage(newPerPage);
  };

  const updateSort = (newSort: string) => {
    setSort(newSort);
  };

  const refreshOrders = () => {
    fetchUserOrders();
  };

  return {
    loading,
    orders,
    isError,
    page,
    perPage,
    sort,
    updatePage,
    updatePerPage,
    updateSort,
    refreshOrders,
  };
};
