import { mdiChevronDown, mdiWeb } from "@mdi/js";
import Icon from "@mdi/react";
import { useCustomerStore } from "../../store/customerStore";
import { getCurrencyData } from "../../utils/getCurrencyData";
import { ICustomerStore, IMenuLanguage } from "../../interfaces";

export const MenuLanguage = ({
  onlyLanguage = false,
  displayArrow = false,
  customColor = "",
}: IMenuLanguage): JSX.Element => {
  const { language, toggleMenuLanguage, currency } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  const handleOpenSelectorLanguage = () => toggleMenuLanguage(true);

  const languageAndCurrencyLabel = `${language.toUpperCase()}${
    onlyLanguage ? "" : ` - ${getCurrencyData(currency).navbarLabel}`
  }`;

  return (
    <button
      type="button"
      aria-label="Toggle Language Options"
      name="Toggle Language Options"
      className={`transition-all duration-1000 flex ${
        onlyLanguage ? "" : "min-w-[160px]"
      } gap-3 items-center rounded-lg py-1 px-4`}
      onClick={handleOpenSelectorLanguage}
    >
      <Icon
        data-testid="icon_web"
        color={customColor ?? "#96969D"}
        path={mdiWeb}
        title="Web"
        size={0.8}
      />
      <p className="font-medium">{languageAndCurrencyLabel}</p>
      {displayArrow && (
        <Icon path={mdiChevronDown} size={0.8} color={customColor} />
      )}
    </button>
  );
};
