import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../common/loader/Loader";
import { useCustomerStore } from "../../store/customerStore";
import { ISplashLoading, ICustomerStore } from "../../interfaces";
import WelcomeHolaFly from "../../assets/backgrounds/loaderHolaFly.svg";
import { LayoutBgLinearGradient } from "../layouts/LayoutBgLinearGradient";
import { splashTextDefault } from "./default";

let count = 1;

export const SplashLoading = ({
  loader,
  splashText,
  limit = 3,
}: ISplashLoading): JSX.Element => {
  const splashInitial = splashText ? splashText : splashTextDefault;

  const { i18n } = useTranslation();
  const [index, setIndex] = useState(1);

  const lang = useCustomerStore((state: ICustomerStore) => state.language);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const timerTip = useCallback(() => {
    count = count + 1;

    setIndex(count);
    if (count > limit && !loader) {
      setIndex(count);
    } else if (count > limit && loader) {
      setIndex(1);
      count = 1;
    }
  }, [loader, limit]);

  useEffect(() => {
    const timeLine = setInterval(timerTip, 1500);

    return () => clearInterval(timeLine);
  }, [loader, timerTip]);

  return (
    <LayoutBgLinearGradient>
      <div
        data-testid="container_splash"
        className="relative flex flex-col justify-center items-center h-screen bg-transparent"
      >
        <img className="h-[35%]" src={WelcomeHolaFly} alt={"Not found"} />

        {splashInitial[index - 1]?.index}
        <Loader className="loader border-[5px] w-12 h-12 mt-7" />
      </div>
    </LayoutBgLinearGradient>
  );
};
