import { TVideoPlayer } from "../../interfaces";

export const VideoPlayer = ({ idVideo }: TVideoPlayer): JSX.Element => {
  return (
    <iframe
      data-testid="video-player"
      src={`https://drive.google.com/file/d/${idVideo}/preview`}
      className="w-full h-full rounded-lg"
      allow="autoplay"
    />
  );
};
