import { AxiosIsError } from "../../utils/axiosIsError";
import { makeApiCall } from "../apiCall";

export const postCreateDiscountCoupon = async (pointsToRedeem: number) => {
  try {
    return await makeApiCall(
      "POST",
      `${process.env.REACT_APP_API_URL}/api/travelClub/createDiscountCoupon`,
      { pointsToRedeem }
    );
  } catch (error) {
    if (AxiosIsError(error)) {
      throw new Error(
        error.response.data?.errors?.customer || error.response.data?.error
      );
    } else {
      throw new Error(error);
    }
  }
};
