import { SVGProps } from "react";

const HolaCoinOutlineIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M16.002 26.667c-1.476 0-2.862-.28-4.16-.84a10.775 10.775 0 0 1-3.388-2.28c-.96-.96-1.72-2.088-2.28-3.386a10.38 10.38 0 0 1-.841-4.16c0-1.474.28-2.861.84-4.16a10.775 10.775 0 0 1 2.28-3.387 10.77 10.77 0 0 1 3.386-2.28c1.297-.56 2.684-.84 4.159-.84 1.475 0 2.862.28 4.16.84 1.298.56 2.427 1.319 3.387 2.279s1.721 2.088 2.281 3.386c.56 1.297.84 2.684.84 4.16 0 1.474-.28 2.861-.84 4.16a10.774 10.774 0 0 1-2.28 3.387c-.959.96-2.088 1.72-3.385 2.28a10.38 10.38 0 0 1-4.16.84ZM16 24.983c2.489 0 4.608-.875 6.358-2.625s2.624-3.87 2.624-6.358c0-2.489-.875-4.608-2.624-6.358-1.75-1.75-3.87-2.624-6.358-2.624-2.49 0-4.608.874-6.358 2.624S7.017 13.512 7.017 16c0 2.489.875 4.608 2.625 6.358S13.51 24.983 16 24.983Z"
    />
    <circle cx={16} cy={16} r={5.587} stroke="#292B2E" strokeWidth={0.508} />
    <path
      fill="#292B2E"
      fillRule="evenodd"
      d="M15.607 15.515c1.146.3 1.97.159 2.501-.425v-2.923h.988v7.666h-.988V16.45c-.408.252-.886.38-1.44.38-.328 0-.681-.045-1.061-.133v3.137h-.988v-3.44c-.13-.05-.262-.102-.397-.159-.708-.299-1.617-.153-2.008.068l-.198-1.142c.567-.257 1.577-.412 2.463-.038l.14.058v-3.013h.988v3.348Z"
      clipRule="evenodd"
    />
  </svg>
);
export default HolaCoinOutlineIcon;
