import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { mdiArrowLeft } from "@mdi/js";

import Loader from "../../components/common/loader/Loader";
import HButton from "../../components/common/hbutton/HButton";

import { IDetailsLayoutProps, ICustomerStore } from "../../interfaces";

import Map from "../../map.svg";
import { useCustomerStore } from "../../store/customerStore";

export const LayoutDetail = ({
  loading,
  children,
}: IDetailsLayoutProps): JSX.Element => {
  const navigate = useNavigate();
  const { customerId } = useCustomerStore((store: ICustomerStore) => store);
  // const [closeAlert, setIsCloseAlert] = useState(true);

  // const isLowDataUsage =
  //   !cardDetails.consumedData?.isUnlimited &&
  //   Number(cardDetails.consumedData?.usedDataPercent) >= 90;

  // const renderAlertContain = {
  //   ACTIVATION_QR: <AlertDefaultInstructions />,
  //   ACTIVE: isLowDataUsage && <AlertLowDataUsage />,
  // };

  return (
    <>
      {loading ? (
        <div className="w-screen h-screen items-center px-6 lg:px-16 xl:w-2/3 xl:mx-auto flex justify-center content-center mt-auto">
          <Loader />
        </div>
      ) : (
        <main data-testid="layout-detail" className="mt-[71px] w-full">
          {/* {closeAlert && cardDetails.provider_name === "CHM" && (
            <AlertProviderCHM setIsCloseAlert={setIsCloseAlert} />
          )} */}
          <div className="md:w-full lg:max-w-[1440px] lg:mx-auto px-6 lg:px-28 justify-center ">
            <div className="relative xl:w-[1016px] my-0 mx-auto  md:mb-[50px] h-40 md:h-[198px] overflow-hidden w-full flex  justify-center items-center">
              <img
                draggable={false}
                className="h-auto absolute opacity-50 -z-1 object-cover top-0 right-0 left-0 ml-auto mr-auto"
                src={Map}
                alt="Map"
              />
              <div className="absolute left-0 flex items-center gap-4">
                <HButton
                  type="button"
                  name="button redirect to view main"
                  containerStyles="border rounded-xl border-[#E4E6EA]"
                  className="hover:bg-neutral-200 rounded-xl  transition-all duration-300 p-3 border-none text-center text-neutral-charcoal"
                  icon={mdiArrowLeft}
                  onClick={() => navigate(`/${customerId}`)}
                />
                <p className="hidden lg:flex items-center gap-3 text-sm text-black font-bold">
                  {t("global.customerV2.buttons.buttonBackToDetail")}
                </p>
              </div>

              <h1 className="text-[22px] lg:text-[34px] font-medium">
                {t("global.customerV2.details.title")}
              </h1>
            </div>

            <div className="xl:w-[1016px] my-0 mx-auto h-full">{children}</div>
          </div>
        </main>
      )}
    </>
  );
};
