import { BuildBox } from "./BuildBox";
import { IBuildBoxProps } from "../../../interfaces";

export const CardsAdviceSkeleton = () => {
  const boxSetting: IBuildBoxProps[] = [
    { height: 24, rounded: 12, width: 203, numberOfBoxes: 1 },
    { height: 56, rounded: 12, numberOfBoxes: 2 },
    { height: 24, rounded: 12, numberOfBoxes: 1 },
    { height: 24, width: 266, rounded: 12, numberOfBoxes: 1 },
    { height: 48, rounded: 12, numberOfBoxes: 1 },
  ];

  return (
    <div
      data-testid="card-advice-skeleton-delete-account"
      className="animate animate-pulse flex flex-col  gap-[24px] bg-[#F8F8FB] p-[30px] w-full lg:min-w-[496px] h-[330px]  rounded-[12px]">
      {boxSetting.map((box, idx) => (
        <div key={idx} className="flex w-full">
          <BuildBox {...box} />
        </div>
      ))}
    </div>
  );
};
