import { useEffect } from "react";
import ReactDOM from "react-dom";
import { IContentModal } from "../../interfaces";
import "./style.css";

export const ContentModal = ({
  children,
  isModal,
  toggleModal,
  direction
}: IContentModal): JSX.Element => {
  const portalDiv = document.getElementById("modal-root") as HTMLElement;

  useEffect(() => {
    isModal
      ? document.body.classList.add("modal-opened")
      : document.body.classList.remove("modal-opened");
  }, [isModal]);

  const OBJ_DIRECTION = () => {
    switch (direction) {
      case "CENTER":
        return "items-center justify-center";

      case "LEFT":
        return "justify-start";

      case "RIGHT":
        return "justify-end";

      default:
        return "items-center justify-center";
    }
  };

  return isModal ? (
    ReactDOM.createPortal(
      <div
        className="fixed top-0 left-0 flex
      justify-center items-center z-50 w-full h-full"
        style={{
          background: "rgba(0,0,0,0.8)",
        }}
      >
        <div
          data-testid="toggle_touch_out_of_modal"
          className="absolute w-full h-full"
          onClick={toggleModal}
        />
        <div className={`flex ${OBJ_DIRECTION()} w-full md:w-[inherit] h-full`}>
          {children}
        </div>
      </div>,
      portalDiv
    )
  ) : (
    <></>
  );
};
