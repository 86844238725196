import { useState } from "react";
import { postDeliverInvoice } from "../services";
import { IUseDeliveryInvoice } from "../interfaces";
import { t } from "i18next";

export const useDeliveryInvoice = ():IUseDeliveryInvoice => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const sendDeliverInvoice = async (orderName: string) => {
    clearStateInitial();
    const { message, isError } = await postDeliverInvoice(orderName);

    if (isError) {
      setMessage(t(message));
      setIsLoading(false);
      setIsError(isError);
      return;
    }

    setMessage(t(message));
    setIsLoading(false);
    setIsSuccess(true);
  };

  const clearStateInitial = () => {
    setMessage("");
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);
  };

  return {
    sendDeliverInvoice,
    isLoading,
    message,
    isError,
    isSuccess,
  };
};
