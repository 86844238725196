import { API_URLS } from "../../../constants";
import { makeApiCall } from "../../apiCall";

export const getCountCardsByCustomerId = async (customerId: string) => {
  const { URL_GET_COUNT_CARDS_CUSTOMER } = API_URLS;

  try {
    const { status, data } = await makeApiCall<any>(
      "GET",
      URL_GET_COUNT_CARDS_CUSTOMER(customerId)
    );

    if (status === 200) {
      return {
        status,
        data,
        error: null,
      };
    }

    return {
      status,
      data: {
        active: 0,
        pending: 0,
        expired: 0,
        total: 0,
      },
      error: "Bad Request",
    };
  } catch (error) {
    console.error("Error al obtener la información:", error);
    return {
      status: 500,
      data: {
        active: 0,
        pending: 0,
        expired: 0,
        total: 0,
      },
      error,
    };
  }
};
