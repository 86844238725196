import create from "zustand";

import { ICustomerStore } from "../interfaces/store";
import { createNotificationSlice } from "./notificationStore";
import { createPreferencesSlice } from "./preferenceStore";
import { createUserSlice } from "./userStore";
import { createEventSlice } from "./eventStore";

export const useCustomerStore = create<ICustomerStore>((set) => ({
  ...createNotificationSlice(set),
  ...createPreferencesSlice(set),
  ...createUserSlice(set),
  ...createEventSlice(set),
}));
