import { useEffect } from "react";
import { Routes, useLocation } from "react-router-dom";
import { useDataLayer } from "../hooks/useDataLayer";
import { useCustomerStore } from "../store/customerStore";
import { ICustomerStore } from "../interfaces/store";
import { RoutesPrivate } from "./Routes";
import { GenerateRoutes } from "../router/GenerateRoutes";

export const HolaflyRoutes = (): JSX.Element => {
  const { customerId: customerIdZustand } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  const customerId = localStorage.getItem("customerId") || customerIdZustand;
  const location = useLocation();
  const { setDataLayer } = useDataLayer();

  useEffect(() => {
    if (setDataLayer && customerId) {
      setDataLayer({ userId: customerId });
    }
  }, [location.pathname, setDataLayer, customerId]);

  return <Routes>{GenerateRoutes(RoutesPrivate())}</Routes>;
};
