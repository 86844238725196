import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { t } from "i18next";
import { useDeleteAccount } from "../../../hooks/useDeleteAccount";
import HButton from "../../../components/common/hbutton/HButton";
import { useCustomerStore } from "../../../store/customerStore";
import { IModalConfirmationProps, ICustomerStore } from "../../../interfaces";

export const ModalConfirmation = ({
  handleModal,
  setError,
  handleConfirm,
}: IModalConfirmationProps): JSX.Element => {
  const { customerId } = useCustomerStore((store: ICustomerStore) => store);

  const { handleDeleteAccount } = useDeleteAccount(customerId);

  const handleClickDelete = async () => {
    const response = await handleDeleteAccount();
    setError(true);
    handleModal(false);
    if (response) {
      handleConfirm(true);
    }
  };

  return (
    <div
      data-testid="modal-confirmation"
      className="mx-[16px] lg:mx-0 p-[50px] relative z-10 w-[600px] bg-white rounded-xl shadow-card"
    >
      <button
        onClick={() => handleModal(false)}
        aria-label="close modal confirmation delete account"
        className="absolute top-4 right-4 border border-neutral-300 hover:bg-neutral-200 rounded-full w-8 h-8 flex justify-center items-center transition-all duration-300"
      >
        <Icon path={mdiClose} size={0.8} />
      </button>

      <div className="flex flex-col w-full">
        <h3 className="text-center w-full text-[20px] lg:text-[22px] font-medium">
          {t("global.customerV2.deleteAccount.confirmationModal.title")}
        </h3>
        <p className="w-full text-center mt-[8px] text-[14px] font-normal">
          {t("global.customerV2.deleteAccount.confirmationModal.infoBody")}
        </p>

        <div className="mt-[24px] flex flex-col lg:flex-row w-full gap-[16px] lg:gap-[24px]">
          <HButton
            type="button"
            name="button confirm delete account"
            containerStyles="w-full"
            onClick={handleClickDelete}
            className="py-3 px-5 w-full flex justify-center items-center h-[48px] hover:from-magenta-bold hover:to-magenta-bold bg-gradient-to-r from-magenta to-magenta-900 text-white rounded-xl"
            text={t(
              "global.customerV2.deleteAccount.confirmationModal.confirmationBtn"
            )}
          />

          <HButton
            type="button"
            name="button denied delete account"
            containerStyles="w-full"
            testID="button-deny-delete-account"
            onClick={() => handleModal(false)}
            className="hover:bg-gray-50 border border-[#E4E6EA] py-3 px-5 w-full flex justify-center items-center h-[48px] bg-white rounded-xl"
            text={t(
              "global.customerV2.deleteAccount.confirmationModal.denyBtn"
            )}
          />
        </div>
      </div>
    </div>
  );
};
