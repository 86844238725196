import { useState, useEffect } from "react";
import axios from "axios";

interface InstallCodes {
  SMDP: string;
  activationCode: string;
  universalLink: string
  loader: boolean;
}

export const useInstallCodes = (
  initialState: InstallCodes,
  customerIdCard: string,
  iccidCard: string
) => {
  const [installCodes, setInstallCodes] = useState<InstallCodes>(initialState);

  useEffect(() => {
    if (iccidCard && customerIdCard) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/user/${customerIdCard}/manualActivation/${iccidCard}`
        )
        .then((response) =>
          setInstallCodes({
            SMDP: response.data.SMDP,
            activationCode: response.data.activationCode,
            universalLink: response.data.universalLink,
            loader: false,
          })
        )
        .catch((e) => setInstallCodes({ ...installCodes, loader: false }));
    }
  }, [iccidCard, customerIdCard]);

  return installCodes;
};
