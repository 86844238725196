import { API_URLS } from "../../constants";
import { getToken } from "../../utils";

export const postInvoicePdf = async (order_name: string) => {
  const { URL_GENERATE_PDF_INVOICE } = API_URLS;
  const token = getToken() ?? "";

  try {
    const response = await fetch(URL_GENERATE_PDF_INVOICE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        orderNumber: order_name,
        templateType: "invoice",
        templateLang: "en",
      }),
    });

    if (response.status === 201) {
      const blob = await response.blob();

      const fileURL = window.URL.createObjectURL(blob);

      const alink = document.createElement("a");
      alink.href = fileURL;
      alink.target = "_blank";
      alink.download = URL_GENERATE_PDF_INVOICE;
      alink.setAttribute("download", order_name);
      alink.click();

      return {
        isLoader: false,
        isError: false,
      };
    }

    return {
      isLoader: false,
      isError: true,
    };
  } catch (error) {
    new Error("Hubo un error al descargar el pdf");
    return {
      isLoader: false,
      isError: true,
    };
  }
};
