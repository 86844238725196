import { saveEncryptedLocalStorageData } from "../../utils";
import { IGetUserDetails, ReferralUser } from "../../interfaces";
import { API_URLS } from "../../constants";
import { makeApiCall } from "../apiCall";

export const getUserDetails = async (
  set: IGetUserDetails["set"],
  shouldGroupByMonth: boolean,
  typeFilter: string
) => {
  const { URL_GET_USER_DETAILS_TRAVELCLUB } = API_URLS;
  const sendData =
    typeFilter === "All"
      ? {
          filters: {
            date: {},
          },
          shouldGroupByMonth,
        }
      : {
          filters: {
            type: "redeemed coupon",
          },
          shouldGroupByMonth,
        };

  try {
    const { data } = await makeApiCall<ReferralUser>(
      "POST",
      URL_GET_USER_DETAILS_TRAVELCLUB,
      sendData
    );
    set({ referralUser: data });
    saveEncryptedLocalStorageData("referralUser", data);
  } catch (error) {
    set({ referralUser: null });
  }
};
