import { useTranslation } from "react-i18next";
import HButton from "../../../../components/common/hbutton/HButton";
import { ITravelClubSwitchProps } from "../../../../interfaces";

const styles = {
  default:
    "rounded-[12px] py-[12px] px-[20px]  flex items-center justify-center gap-[8px] hover:bg-neutral-300 font-medium",
  active: "border border-[#292B2E] bg-[#F8F8FB] hover:bg-[#F8F8FB]",
};

export const TravelClubSwitch = ({
  options,
  currentOption,
  setCurrentOption,
}: ITravelClubSwitchProps) => {
  const { t } = useTranslation();
  return (
    <div className="mt-[48px] cursor-pointer gap-1 flex items-center justify-center">
      {options.map((option, idx) => (
        <HButton
          type="button"
          name="button tab travel club"
          onClick={() => setCurrentOption(idx)}
          key={idx}
          className={`${styles.default} ${
            currentOption === idx && styles.active
          }`}
          text={t(option.label)}
          icon={<option.icon />}
        />
      ))}
    </div>
  );
};
