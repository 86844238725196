import { mdiApple, mdiGoogle } from "@mdi/js";
import AppleSignin from "react-apple-signin-auth";
import { useDataLayer, useSignInGoogle } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { signInApple } from "../../../services";
import HButton from "../../../components/common/hbutton/HButton";
import { t } from "i18next";
import { IProvidersAccessButtonsProps } from "../../../interfaces/pages/login/components/providersAccessButtons";

const buttonStylesDefault = {
  outline:
    "rounded-xl border-2 flex gap-2 items-center justify-center transition-all duration-300 px-5 py-3",
};

export default function ProvidersAccessButtons({
  setError,
}: IProvidersAccessButtonsProps) {
  const { setDataLayer } = useDataLayer();
  const navigate = useNavigate();

  const handlePushLoginEvent = (customerId: string) => {
    setDataLayer({
      event: "login",
      userId: customerId,
    });
  };

  const { handleAuthGoogle } = useSignInGoogle({
    pushEventLogin: handlePushLoginEvent,
    setError,
  });

  const onSuccessAppleSignIn = async (event: any) => {
    const { data, error } = await signInApple(event);

    if (error) {
      return setError(t("global.customerV2.new-login.error.invalidAppleID"));
    }
    navigate(`/${data.customerId}`, { replace: true });
    handlePushLoginEvent(data.customerId);
  };

  return (
    <div className="flex flex-row gap-2 justify-center">
      <AppleSignin
        authOptions={{
          clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
          scope: "email name",
          redirectURI: process.env.REACT_APP_APPLE_REDIRECT,
          state: "",
          nonce: "nonce",
          usePopup: true,
        }}
        onSuccess={onSuccessAppleSignIn}
        onError={(error) => console.error(error)}
        render={(props) => (
          <HButton
            {...props}
            name="button login apple"
            containerStyles={buttonStylesDefault.outline}
            className=""
            icon={mdiApple}
            isDefaultStyles
          />
        )}
      />

      <HButton
        type="button"
        name="button login google"
        className=""
        icon={mdiGoogle}
        containerStyles={buttonStylesDefault.outline}
        onClick={handleAuthGoogle}
      />
    </div>
  );
}
