import { ISvg } from "../../../interfaces/components/svg/svgInterface";
import { THEME } from "../../../styles/theme";

const HolaflyLogo = ({
  className,
  color = THEME.colors.black,
}: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="holafly_logo"
      width="87"
      height="24"
      viewBox="0 0 87 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(className && { className })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1459 15.0924C33.4732 15.8428 32.6325 16.2181 31.6236 16.2181C30.5977 16.2181 29.757 15.8472 29.1012 15.1053C28.4454 14.3638 28.1175 13.415 28.1175 12.2589C28.1175 11.1205 28.4454 10.1804 29.1012 9.43855C29.757 8.69699 30.5977 8.3259 31.6236 8.3259C32.6325 8.3259 33.4732 8.69699 34.1459 9.43855C34.8183 10.1804 35.1548 11.1205 35.1548 12.2589C35.1548 13.3976 34.8183 14.342 34.1459 15.0924ZM34.9791 6.35887C33.9866 5.75532 32.8685 5.45334 31.6244 5.45334C30.3967 5.45334 29.2825 5.75532 28.2823 6.35887C27.2816 6.96293 26.4996 7.78215 25.9365 8.81714C25.3728 9.85213 25.0915 10.9995 25.0915 12.2585C25.0915 13.5179 25.3728 14.6694 25.9365 15.713C26.4996 16.7568 27.2772 17.5804 28.2697 18.1841C29.2616 18.7877 30.3797 19.0897 31.6244 19.0897C32.8685 19.0897 33.9866 18.7877 34.9791 18.1841C35.971 17.5804 36.7529 16.7568 37.3248 15.713C37.8962 14.6694 38.1824 13.5179 38.1824 12.2585C38.1824 10.9995 37.8962 9.85213 37.3248 8.81714C36.7529 7.78215 35.971 6.96293 34.9791 6.35887Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.3637 18.544H43.6364V0.544006H40.3637V18.544Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.7275 18.544H72.0002V0.544006H68.7275V18.544Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.6999 5.45334V12.0859C83.6999 13.1439 83.435 13.9587 82.9052 14.531C82.3753 15.1031 81.6816 15.3893 80.8237 15.3893C80.0164 15.3893 79.3938 15.1552 78.9567 14.687C78.5191 14.2189 78.3006 13.43 78.3006 12.32V5.45334H75.273V12.8403C75.273 14.6784 75.6806 16.0486 76.4966 16.9499C77.3123 17.8518 78.4351 18.3024 79.865 18.3024C80.7563 18.3024 81.4963 18.1508 82.0852 17.8473C82.6738 17.5441 83.2035 17.0974 83.6747 16.5077L83.6999 16.5858V17.9643C83.6999 19.0393 83.435 19.8671 82.9052 20.4483C82.3753 21.0291 81.6476 21.3196 80.7227 21.3196C79.4548 21.3196 78.625 20.8369 78.2305 19.876L75.7285 21.2019C76.0659 21.8496 76.5151 22.405 77.077 22.8673C77.9936 23.6216 79.2089 23.9988 80.7227 23.9988C82.6063 23.9988 84.0783 23.4481 85.1381 22.347C86.1978 21.2457 86.7276 19.7761 86.7276 17.9383V5.45334H83.6999Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.8706 2.82657C65.241 2.82657 65.5698 2.93461 65.8583 3.14835L67.0909 0.952393C66.8501 0.737735 66.5409 0.536646 66.1612 0.349535C65.6868 0.116207 65.0554 -0.000610352 64.2677 -0.000610352C63.3028 -0.000610352 62.4948 0.341067 61.8434 1.02391C61.1922 1.70716 60.8664 2.64507 60.8664 3.83823V5.60192H58.9091V8.32534H60.8664V18.5448H63.7612V8.32534H66.6429V5.60192H63.7612V4.20134C63.7612 3.28496 64.1309 2.82657 64.8706 2.82657Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.6396 14.1982C53.6396 14.9402 53.3907 15.5224 52.8934 15.9449C52.3957 16.3677 51.7782 16.5788 51.0409 16.5788C50.3375 16.5788 49.7842 16.428 49.3814 16.1259C48.9781 15.8245 48.7769 15.4233 48.7769 14.9228C48.7769 14.3883 48.961 13.9784 49.33 13.6937C49.6987 13.4091 50.2861 13.2065 51.0925 13.0856L53.6396 12.6975V14.1982ZM55.2091 6.65656C54.1969 5.85435 52.9192 5.45334 51.3754 5.45334C49.8659 5.45334 48.6826 5.77679 47.8249 6.42369C46.9741 7.0651 46.3688 8.03403 46.0075 9.32855L48.7622 9.83123C48.8819 9.24143 49.1218 8.78597 49.4844 8.46771C49.8873 8.11444 50.4834 7.93744 51.2726 7.93744C52.8506 7.93744 53.6396 8.72246 53.6396 10.292V10.4473L50.8866 10.8354C48.9995 11.0941 47.6834 11.5987 46.9372 12.3491C46.1912 13.0994 45.818 14.0181 45.818 15.1048C45.818 15.8984 46.0151 16.5972 46.4099 17.2007C46.8042 17.8048 47.3361 18.2705 48.0049 18.598C48.6741 18.9255 49.4113 19.0897 50.2177 19.0897C51.8813 19.0897 53.0735 18.581 53.794 17.563L54.1284 18.7274H56.7271V10.2404C56.7271 8.65345 56.2211 7.45868 55.2091 6.65656Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7741 7.40655C17.1394 8.778 14.5982 9.11086 11.0656 8.40574V7.92437V0.544006H8.02273V7.61896C7.87965 7.5747 7.73608 7.52993 7.59044 7.48313C4.86106 6.60499 1.74784 6.96783 0 7.57206L0.610458 10.2523C1.81742 9.73505 4.61755 9.39196 6.79995 10.0938C7.21583 10.2278 7.62277 10.3506 8.02273 10.4652V18.544H11.0656V11.1779C12.2374 11.3843 13.3275 11.4888 14.336 11.4888C16.0428 11.4888 17.5177 11.191 18.7741 10.5989V10.8302V18.544H21.8182V0.544006H18.7741V7.40655Z"
        fill={color}
      />
    </svg>
  );
};

export default HolaflyLogo;
