import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  FacebookLight,
  InstagramLight,
  YoutubeLight,
  LinkedinLight,
  HolaflyLogo,
  footerWaveBottom,
  Heart,
  XTwitter
} from "../svg/index";

import { DropdownFooter } from "../dropdowns/DropdownFooter";

import AndroidButton from "../../assets/footer/AndroidButton.svg";
import AppStoreButton from "../../assets/footer/IOSButton.svg";
import { LIST_OPTS_FOOTER } from "../../constants";

const textStyleLi: string =
  "font-normal text-sm text-neutral-charcoal hover:underline";

export const FooterWave = (): JSX.Element => {
  const { t } = useTranslation();

  const [screenMobile, setScreenMobile] = useState<number>(window.screen.width);

  const handleOpenStoreApp = (isIos: boolean) => {
    const platform: string = isIos
      ? "downloadAppUrlIos"
      : "downloadAppUrlAndroid";
    window.open(
      t(`global.header.${platform}`),
      "_blank",
      "noopener,noreferrer"
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenMobile(window.screen.width);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer data-testid="footer" className="relative w-full bottom-0 ">
      <img
        draggable={false}
        src={footerWaveBottom}
        alt="footer_wave"
        className="w-full relative md:top-1"
      />
      <div className="bg-neutral-200 w-full">
        <div className="w-full max-w-[1440px] m-auto bg-neutral-200 h-full  md:gap-8 lg:gap-3 py-12 lg:px-28 flex flex-wrap items-center md:items-start  justify-between">
          <div className="flex flex-col items-center lg:items-start justify-center lg:justify-start lg:w-auto w-full gap-4 md:gap-0">
            <HolaflyLogo className="h-12 w-28" color="#E6485C" />

            <div className="flex gap-[20px] my-[21px]">
              <img
                onClick={() => handleOpenStoreApp(true)}
                className="w-[140px] cursor-pointer"
                src={AppStoreButton}
                alt="Ios button"
                draggable={false}
              />
              <img
                onClick={() => handleOpenStoreApp(false)}
                className="w-[140px] cursor-pointer"
                src={AndroidButton}
                alt="Android button"
                draggable={false}
              />
            </div>

            <div className="flex flex-row items-center gap-1">
              <a
                target="_blank"
                rel="noreferrer"
                href={t("global.footer.facebookLink")}
              >
                <FacebookLight className="p-2 w-10 h-10 " />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={t("global.footer.instagramLink")}
              >
                <InstagramLight className="p-2 w-10 h-10 " />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={t("global.footer.youtube")}
              >
                <YoutubeLight className="p-2 w-10 h-10 " />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href={t("global.footer.twitterLink")}
              >
                <XTwitter className="p-2 w-10 h-10 " color="#000"/>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={t("global.footer.linkedinLink")}
              >
                <LinkedinLight className="p-2 w-10 h-10 " />
              </a>
            </div>
          </div>

          {screenMobile <= 600 ? (
            <div className="flex w-full my-6 flex-col gap-2">
              <DropdownFooter
                title={"global.footer.legal"}
                opts={LIST_OPTS_FOOTER.LEGAL}
              />

              <DropdownFooter
                title={"global.footer.holafly"}
                opts={LIST_OPTS_FOOTER.HOLAFLY}
              />

              <DropdownFooter
                title={"global.footer.help"}
                opts={LIST_OPTS_FOOTER.HELP}
              />
            </div>
          ) : (
            <div className="flex w-full justify-center lg:w-auto text-center lg:text-start flex-row gap-[60px]">
              <div className="mt-5 md:mt-0">
                <h4 className="font-bold text-lg ">
                  {t("global.footer.legal")}
                </h4>

                <ul className="mt-4">
                  <li className="my-2 whitespace-nowrap">
                    <a
                      className={`${textStyleLi}`}
                      target="_blank"
                      rel="noreferrer"
                      href={t("global.footer.privacyPolicyLink")}
                    >
                      {t("global.footer.privacyPolicy")}
                    </a>
                  </li>
                  <li className="my-2 whitespace-nowrap">
                    <a
                      className={`${textStyleLi}`}
                      target="_blank"
                      rel="noreferrer"
                      href={t("global.footer.termsAndConditionsLink")}
                    >
                      {t("global.footer.termsAndConditions")}
                    </a>
                  </li>
                  <li className="my-2 whitespace-nowrap">
                    <a
                      className={`${textStyleLi}`}
                      target="_blank"
                      rel="noreferrer"
                      href={t("global.footer.refundPolicyLink")}
                    >
                      {t("global.footer.refundPolicy")}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="mt-5 md:mt-0">
                <h4 className="font-bold text-lg">
                  {t("global.footer.holafly")}
                </h4>
                <ul className="mt-4">
                  <li className="my-2 whitespace-nowrap">
                    <a
                      className={`${textStyleLi}`}
                      target="_blank"
                      rel="noreferrer"
                      href={t("global.footer.contactUsLink")}
                    >
                      {t("global.footer.contactUs")}
                    </a>
                  </li>
                  <li className="my-2 whitespace-nowrap">
                    <a
                      className={`${textStyleLi}`}
                      target="_blank"
                      rel="noreferrer"
                      href={t("global.footer.holaflyTeamLink")}
                    >
                      {t("global.footer.holaflyTeam")}
                    </a>
                  </li>
                  <li className="my-2 whitespace-nowrap">
                    <a
                      className={`${textStyleLi}`}
                      target="_blank"
                      rel="noreferrer"
                      href={t("global.footer.reviewsLink")}
                    >
                      {t("global.footer.reviews")}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="mt-5 md:mt-0 ">
                <h4 className="font-bold text-lg">{t("global.footer.help")}</h4>
                <ul className="mt-4">
                  <li className="my-2 whitespace-nowrap">
                    <a
                      className={`${textStyleLi}`}
                      target="_blank"
                      rel="noreferrer"
                      href={t("global.footer.blogLink")}
                    >
                      {t("global.footer.blog")}
                    </a>
                  </li>
                  <li className="my-2 whitespace-nowrap">
                    <a
                      className={`${textStyleLi}`}
                      target="_blank"
                      rel="noreferrer"
                      href={t("global.footer.esimCompatibleDevicesLink")}
                    >
                      {t("global.footer.esimCompatibleDevices")}
                    </a>
                  </li>
                  <li className="my-2 whitespace-nowrap">
                    <a
                      className={`${textStyleLi}`}
                      target="_blank"
                      rel="noreferrer"
                      href={t("global.footer.howToSetupYourEsimLink")}
                    >
                      {t("global.footer.howToSetupYourEsim")}
                    </a>
                  </li>
                  <li className="my-2 whitespace-nowrap">
                    <a
                      className={`${textStyleLi}`}
                      target="_blank"
                      rel="noreferrer"
                      href={t("global.footer.faqsLink")}
                    >
                      {t("global.footer.faqs")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className="md:border-t" />
        <div className=" w-full max-w-[1440px] m-auto flex flex-col items-center px-6 gap-8 md:gap-0 md:flex-row md:justify-between pb-6 md:py-4 md:px-[108px] bg-neutral-200">
          <div className="w-full text-center">
            <Trans
              className="font-medium text-sm text-neutral-charcoal"
              i18nKey="global.footer.madeWithLove"
              components={{
                heart: (
                  <Heart className={"inline h-5 w-5 mx-1"} color={"#FF595C"} />
                ),
              }}
            />
          </div>
          {/* <LogoMurcia /> */}
        </div>
      </div>
    </footer>
  );
};
