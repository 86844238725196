import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { DropdownSelect } from "../../../components/dropdowns/DropdownSelect";
import { UniversalPagination } from "../../../components/paginations/UniversalPagination";
import { SkeletonCardMain } from "./SkeletonCardMain";
import { CardStateLite } from "../../../components/cards/CardStateLite";
import { CardExpired } from "../../../components/cards/CardExpired";
import { PER_PAGE_OPTIONS } from "../../../constants";
import {
  ICardViewWithPaginationProps,
  IOptDropdownSelect,
  StateOrders,
} from "../../../interfaces";

export const CardViewWithPagination = ({
  cards,
  loading,
  showModalTopUp,
  initPerPage,
  pages_count,
  updatePerPage,
  updatePage,
}: ICardViewWithPaginationProps): JSX.Element => {
  const { t } = useTranslation();

  const handleSelectedLimitCard = (option: IOptDropdownSelect) =>
    updatePerPage(Number(option.value));

  const optionPerPage = PER_PAGE_OPTIONS.find(
    (opt) => opt.value === initPerPage.toString()
  )!;

  return (
    <div className="flex flex-col xl:w-[1017px] w-full  gap-[50px]">
      {cards.length > 0 && (
        <Fragment>
          <div className="flex flex-wrap justify-center xl:justify-start gap-6 justify-items-center">
            {loading ? (
              <div className="flex w-full justify-center items-center">
                <SkeletonCardMain />
              </div>
            ) : (
              cards.map((card) => {
                return (
                  <Fragment key={`${card.id}_${card.customer_id}_idx`}>
                    {card.status === "ACTIVATION_QR" ||
                    card.status === "ACTIVE" ? (
                      <CardStateLite
                        data={card}
                        status={card.status as StateOrders}
                        isCallPlan={
                          card.status !== "ACTIVATION_QR" && !card.msisdn
                        }
                        handleEvent={showModalTopUp}
                      />
                    ) : (
                      <CardExpired data={card} />
                    )}
                  </Fragment>
                );
              })
            )}
          </div>

          {!loading && (
            <div className="flex flex-col gap-[30px] md:gap-0 md:flex-row justify-between items-center xl:w-[1017px] w-full">
              <div className="flex flex-row gap-4 items-center">
                <p className="font-medium">{t("global.customerV2.invoice.main.listReceipts.title")}</p>

                <DropdownSelect
                  value={t("global.customerV2.texts.titleShowPaginationMain", {
                    number: initPerPage,
                  })}
                  options={PER_PAGE_OPTIONS}
                  handleSelectedOption={handleSelectedLimitCard}
                  selectOption={optionPerPage}
                  width="w-[146px]"
                />
              </div>

              <div className="flex flex-row items-center gap-4">
                <p className="font-medium">{t("global.customerV2.invoice.main.pagination.title")}</p>
                <UniversalPagination
                  setInitialLimit={() => {}}
                  setCurrentPos={(currentPosition) =>
                    updatePage(currentPosition)
                  }
                  limitList={initPerPage}
                  numPagination={pages_count}
                />
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};
