import { SVGProps } from "react";

const FacebookLight = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    data-testid="facebook-light-svg"
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={17}
    fill="none"
    {...props}>
    <path
      fill={props.color || "#292B2E"}
      d="M5.834 6.722H9.32a.4.4 0 0 1 .388.497l-.244.978a.4.4 0 0 1-.388.303H5.834v7.6a.4.4 0 0 1-.4.4h-.978a.4.4 0 0 1-.4-.4V8.5H.9a.4.4 0 0 1-.4-.4v-.978c0-.22.18-.4.4-.4h3.156V5.058c0-1.584.165-2.16.474-2.74A3.231 3.231 0 0 1 5.874.976C6.454.665 7.03.5 8.614.5c.335 0 .64.023.916.07.18.03.304.192.304.374v.934a.4.4 0 0 1-.4.4h-.82c-1.177 0-1.535.07-1.9.265-.27.144-.472.345-.616.615-.195.365-.264.724-.264 1.9v1.664Z"
    />
  </svg>
);
export default FacebookLight;
