import { useState, useEffect } from "react";

export const useQRImage = (customerId: string, cardId: string) => {
  const [qrImg, setQrImg] = useState("");
  const [loadingQR, setLoadingQR] = useState(true);

  const getQR = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/user/${customerId}/qr/${cardId}`
      );
      const imageBlob = await res.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setLoadingQR(false);
      setQrImg(imageObjectURL);
    } catch (error) {
      setLoadingQR(false);
    }
  };

  useEffect(() => {
    getQR();
  }, []);

  return { qrImg, loadingQR };
};
