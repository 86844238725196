import { mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import { IChipProps } from "../../../interfaces";
import { t } from "i18next";

export const Chip = ({ title, onSelect, selected }:IChipProps): JSX.Element => {
  return (
    <button
      onClick={onSelect}
      className={`flex-[0_0_auto] px-3 py-[1px] h-8 rounded-[80px] border border-neutral-300 flex justify-center items-center ${
        selected ? "bg-neutral-charcoal flex-row gap-1" : ""
      }`}
    >
      <Icon
        className={`${selected ? "flex" : "hidden"}`}
        path={mdiCheck}
        size={0.8}
        color={"white"}
      />
      <p
        className={`text-base ${
          selected ? "text-white" : "text-neutral-charcoal"
        } font-medium`}
      >
        {t(title)}
      </p>
    </button>
  );
};
