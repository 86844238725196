import { API_URLS } from "../../../constants";
import { makeApiCall } from "../../apiCall";

export const deleteAccount = async (customerId: string, lang?: string) => {

  const {URL_DELETE_USER} = API_URLS 
  const URL = `${
    process.env.REACT_APP_API_URL
  }/api/user/deleteUserById/${customerId}${lang ? `?lang=${lang}` : ""}`;
  try {
    const { status, data } = await makeApiCall<any>("DELETE", URL_DELETE_USER(customerId, lang));

    if (status === 200) {
      return {
        status,
        data,
        error: null,
      };
    }

    return {
      status,
      data: {
        message: "",
      },
      error: "Bad Request",
    };
  } catch (error) {
    console.error("Error al eliminar la cuenta:", error);
    return {
      status: 500,
      data: {
        message: "",
      },
      error,
    };
  }
};
