import { mdiBookOpenPageVariantOutline } from "@mdi/js";
import HButton from "../../../../../components/common/hbutton/HButton";
import { InstallCode } from "../../../../../components/installCode/InstallCode";
import { CallDown, ConnectionWifi } from "../../../../../components/svg";
import { IOrderEsim } from "../../../../../interfaces";
import { ActiveEsimSuggestion } from "../ActiveEsimSuggestion";
import { suggestion } from "./suggestions";
import { t } from "i18next";
import { Trans } from "react-i18next";

interface ManualOptionProps {
  cardDetails: IOrderEsim;
  hanldeNavigateHelpCenter: () => void;
}
export const ManualOption = ({
  cardDetails,
  hanldeNavigateHelpCenter,
}: ManualOptionProps) => {

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="gap-5 w-full flex flex-col p-[20px] border rounded-[12px] border-[#E4E6EA]">
        <article className="w-full items-center justify-center flex gap-2">
          <CallDown />
          <h1>1. {t("global.login.frequentDoubts.titleActiveEsim")}</h1>
        </article>
        <p className="text-center text-[16px] font-normal">
          <Trans
            i18nKey={"global.InstallOption_manualCodes_description"}
            components={{
              strong: <strong />,
            }}
          />
        </p>
        <div className="flex flex-col bg-[#F8F8FB] p-[16px] gap-[16px] rounded-xl w-full  justify-between">
          <article className="w-full flex justify-center items-center">
            <h3 className="font-bold text-[16px]">Installation codes</h3>
          </article>
          <InstallCode
            bgContainerCard="bg-white"
            customerIdCard={cardDetails.customer_id || ""}
            iccidCard={cardDetails.iccid}
          />
        </div>
        <HButton
          type="button"
          name="button activation guide"
          containerStyles="w-full mx-auto hover:bg-red-300 rounded-xl  bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold transition-all duration-300"
          text={t(
            "global.customerV2.details.pending.buttons.buttonActivationGuides"
          )}
          className="h-12 flex items-center justify-center px-5 py-3 text-white gap-2 border-none font-normal text-base"
          icon={mdiBookOpenPageVariantOutline}
          iconColor="white"
          onClick={hanldeNavigateHelpCenter}
        />
      </div>
      <div className="border border-neutral-300 rounded-xl px-4 pb-4 pt-6 md:p-[30px] flex flex-col gap-6">
        <div className="flex flex-row gap-[10px] justify-center items-center">
          <ConnectionWifi />
          <h3
            data-testid="activation-destination-title"
            className="font-bold text-base md:text-xl text-neutral-charcoal"
          >
            {t(
              "global.customerV2.details.pending.sectionActivateDestination.title"
            )}
          </h3>
        </div>
        <article className="w-full flex justify-center">
          <p
            data-testid="activation-destination-description"
            className="text-center text-base font-normal text-neutral-charcoal md:max-w-[820px]"
          >
            {t(
              "global.customerV2.details.pending.sectionActivateDestination.description"
            )}
          </p>
        </article>

        <ActiveEsimSuggestion suggestions={suggestion} />

        <HButton
          type="button"
          name="button activation guide"
          containerStyles="min-w-[215px] mx-auto hover:bg-red-300 rounded-xl  bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold transition-all duration-300"
          text={t(
            "global.customerV2.details.pending.buttons.buttonActivationGuides"
          )}
          className="h-12 flex items-center justify-center px-5 py-3 text-white gap-2 border-none font-normal text-base"
          icon={mdiBookOpenPageVariantOutline}
          iconColor="white"
          onClick={hanldeNavigateHelpCenter}
        />
      </div>
    </div>
  );
};
