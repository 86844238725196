export const InvoiceSkeleton = () => {
  const CreateBox = (): JSX.Element => (
    <div
      data-testid="skeleton-box"
      className=" w-full lg:w-[322.67px] h-[224px] rounded-[12px] bg-[#E4E6EA]"></div>
  );

  const renderBoxes = (numberItems: number) => {
    let boxes: JSX.Element[] = [];

    for (let i = 1; i <= numberItems; i++) {
      boxes.push(<CreateBox key={`box-${i}`} />);
    }
    return boxes;
  };

  return (
    <article
      data-testid="invoice-skeleton"
      className="w-full flex flex-col animate animate-pulse gap-[40px] lg:gap-[24px]">
      <div className="bg-[#E4E6EA] w-full lg:w-[322.67px] h-[40px] lg:h-[50px] rounded-[12px]"></div>
      <div className="hidden w-full lg:flex gap-6 flex-wrap justify-center">
        {renderBoxes(9)}
      </div>
      <div className="flex w-full lg:hidden gap-4 flex-wrap justify-center">
        {renderBoxes(3)}
      </div>

      <div className="flex flex-col lg:flex-row gap-[30px] lg:gap-0 w-full items-center lg:items-start lg:justify-between">
        <div className="h-[72px] lg:h-[50px] w-[185px] lg:w-[204px] rounded-[12px] bg-[#E4E6EA]"></div>
        <div className="h-[72px] lg:h-[50px] w-full lg:w-[204px] rounded-[12px] bg-[#E4E6EA]"></div>
      </div>
    </article>
  );
};
