import React, { SVGProps } from 'react'

export const ArrowEast = (props: SVGProps<SVGSVGElement>, fill?:string):JSX.Element => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    {...props}
  >
    <path
      fill={fill ? fill :"#292B2E"}
      d="M20.027 12.5c0 .1-.015.197-.043.29a.52.52 0 0 1-.172.247l-4.747 4.746a.563.563 0 0 1-.44.194.63.63 0 0 1-.461-.194.672.672 0 0 1-.172-.462c0-.179.057-.332.171-.461l3.737-3.716H4.67a.626.626 0 0 1-.643-.644c0-.186.06-.34.182-.462a.628.628 0 0 1 .462-.182H17.9l-3.737-3.737a.637.637 0 0 1-.184-.44.596.596 0 0 1 .184-.462.63.63 0 0 1 .46-.194c.18 0 .327.065.441.194l4.747 4.746a.52.52 0 0 1 .172.247c.028.093.043.19.043.29Z"
    />
  </svg>
  )
}
