import React from "react";
import { InstallCode } from "../../../components/installCode/InstallCode";
import { IInstallationCodeGuideCardProps } from "../../../interfaces";

export const InstallationCodeGuideCard = ({
  customerId,
  iccid,
}: IInstallationCodeGuideCardProps): JSX.Element => {
  return (
    <div data-testid="guide-install-code" className="flex flex-col gap-2 mt-8">
      <h4 className="font-normal text-base text-neutral-charcoal">
        Installation codes
      </h4>
      <div className="flex flex-col gap-[2px]">
        <InstallCode
          bgContainerCard="bg-neutral-200"
          customerIdCard={customerId}
          iccidCard={iccid}
        />
      </div>
    </div>
  );
};
