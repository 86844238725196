import React, { SVGProps } from "react";

export const ArrowChevronDown = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#292B2E"
        d="M12 14.496a.85.85 0 0 1-.252-.037.452.452 0 0 1-.215-.15L8.15 10.945a.573.573 0 0 1-.15-.393c0-.15.056-.28.168-.392.112-.1.243-.15.393-.15.15 0 .28.05.392.15L12 13.206l3.065-3.046a.453.453 0 0 1 .374-.16c.15.007.28.06.393.16a.548.548 0 0 1 .168.401.49.49 0 0 1-.168.384l-3.365 3.364a.453.453 0 0 1-.214.15.857.857 0 0 1-.253.037Z"
      />
    </svg>
  );
};
