import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiCheckCircleOutline } from "@mdi/js";
import { Alert } from "../../../components/alert/Alert";
import { useTimeout } from "../../../hooks/useTimeout";

export const AlertSuccess = (): JSX.Element => {
  const { t } = useTranslation();
  const [showAlert, resetAlert] = useTimeout(4000);

  useEffect(() => {
    resetAlert();
  }, []);

  return showAlert ? (
    <Alert
      type="custom"
      customStyle="mt-20 absolute bg-semantic-success-100 p-5 rounded-xl z-10 mx-2 transition-all duration-300 animate-slideDown">
      <div className="flex flex-row gap-4 items-center w-full h-5">
        <Icon
          data-testid="alert-circle-outline"
          path={mdiCheckCircleOutline}
          size={0.8}
          className="text-semantic-success-600"
        />

        <p
          data-testid="alert-text-success"
          className="font-normal text-sm leading-5 text-semantic-success-600">
          {t("global.account.alerts.alertSuccessSaveChanges")}
        </p>
      </div>
    </Alert>
  ) : (
    <></>
  );
};
