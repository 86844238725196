import React from "react";
import timeWorker from "../../../../assets/invoice/time_worker.svg";
import HButton from "../../../../components/common/hbutton/HButton";
import { Trans, useTranslation } from "react-i18next";
import { useCustomerStore } from "../../../../store/customerStore";
import { ICustomerStore } from "../../../../interfaces";

export const InvoiceConfirmation = ({ closeModal }): JSX.Element => {
  const { t } = useTranslation();
  const { orderUser } = useCustomerStore((store: ICustomerStore) => store);

  const handleConfirmationInvoice = () => {
    closeModal();
  };

  return (
    <div className="flex flex-col justify-center items-center w-full h-full gap-4 my-20 lg:mt-0">
      <img
        src={timeWorker}
        alt="time-worker"
        draggable={false}
        loading="lazy"
      />

      <h2 className="text-[22px] text-neutral-charcoal font-medium">
        {t("global.customerV2.invoice.requestForm.sent.title")}
      </h2>

      <p className="text-base font-normal w-[60%] text-center">
        <Trans
          i18nKey={"global.customerV2.invoice.requestForm.sent.description"}
          components={{
            span: <span className="font-medium" />,
          }}
          values={{email: orderUser?.customerEmail || ""}}
        />
      </p>

      <HButton
        name="button accept notification success invoice"
        type="button"
        containerStyles="border border-neutral-300 rounded-xl min-w-[130px] bg-white hover:bg-neutral-200"
        className="py-3 px-5 text-neutral-charcoal font-medium"
        text="Got it"
        onClick={handleConfirmationInvoice}
      />
    </div>
  );
};
