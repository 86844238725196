import { useState } from "react";
import { updateStatusEsim } from "../services";
import { GENERIC_STYLES } from "../constants";

export const useUpdateStatusCard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [eSimData, setEsimData] = useState(null);

  const fetchUpdateEsim = async (form) => {
    setError("");
    setLoading(true);

    const { data, error } = await updateStatusEsim(form);

    setLoading(false);
    if (error) {
      setError(error);
      return;
    }

    setEsimData(data);
  };

  const shouldShowAlert = error || eSimData;

  const getAlertMessage = () => {
    if (error) return error;
    return JSON.stringify(eSimData, null, 2);
  };

  const getAlertClassName = () => {
    return `${GENERIC_STYLES.alertNotification} ${
      error ? "bg-semantic-error-100" : "bg-semantic-success-100"
    }`;
  };

  return {
    loading,
    error,
    eSimData,
    fetchUpdateEsim,
    shouldShowAlert,
    getAlertMessage,
    getAlertClassName,
  };
};
